
import { jsx } from '@emotion/react';

import { HORIZONTAL_GLOBAL_NAV_HEIGHT } from '@atlaskit/atlassian-navigation';
import { N40 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

interface AppFrameProps {
    children: React.ReactNode;
    content?: React.ReactNode;
    shouldHideAppBar?: boolean;
    shouldHideBorder?: boolean;
}

const AppFrame = ({
    children,
    shouldHideAppBar,
    shouldHideBorder,
    content,
}: AppFrameProps) => {
    return (
        <div
            onClick={(e) => e.preventDefault()}
            style={{
                height: '100%',
                minHeight: 600,
            }}
        >
            {shouldHideAppBar || (
                <div style={{ zIndex: 10, position: 'relative' }}>
                    <img style={{ paddingTop: "15px", height: "28px" }} src={process.env.PUBLIC_URL + "/Logo.svg"} alt="" />
                </div>
            )}
            <div
                style={{
                    height: shouldHideAppBar
                        ? '100%'
                        : `calc(100% - ${HORIZONTAL_GLOBAL_NAV_HEIGHT}px)`,
                    minHeight: 1000,
                    display: 'flex',
                }}
            >
                <div
                    style={{
                        minHeight: 600,
                        borderRight: shouldHideBorder
                            ? undefined
                            : `1px solid ${token('color.border', N40)}`,
                    }}
                >
                    {children}
                </div>

                {content}
            </div>
        </div>
    );
};

export default AppFrame;
