import Button from '@atlaskit/button';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalTitle,
    ModalTransition,
} from '@atlaskit/modal-dialog';
import Textfield from '@atlaskit/textfield';
import { useState } from 'react';
import BooksService from '../../Services/Apicall';

 
const RatingCategory = ({ isOpen1, closeModal1 }: any) => {

    const [name, setName] = useState<any>("")
    const [loading, setLoading] = useState(false);

    const addRatingCategory = async () => {
        setLoading(true)
        const res = await BooksService.addRatingCategory({
            name: name
        })
        if (res.result) {
            setLoading(false)
            
        }
       /* console.log(name)*/
    }
   

    return (
        <>
            <ModalTransition>
                {isOpen1 && (
                    <Modal onClose={closeModal1}>
                        <ModalHeader>
                            <ModalTitle>Rating</ModalTitle>
                        </ModalHeader>
                        <ModalBody>
                            <div style={{ paddingRight: "25px", paddingLeft: "25px" }}>
                                <div style={{ paddingBottom: "10px" }}>
                                    <label>Rating Category</label>
                                </div>

                                <div>
                                    <Textfield
                                        onChange={(e: any) => { setName(e.target.value) } }
                                        placeholder="Category"
                                        data-testid="nativeFormValidationTest"
                                    />
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button appearance="subtle" onClick={closeModal1}>
                                Cancel
                            </Button>
                            <Button appearance="primary" onClick={addRatingCategory} autoFocus>
                                Submit
                            </Button>
                        </ModalFooter>
                    </Modal>
                )}
            </ModalTransition>
        </>
    )

}

export default RatingCategory;