import BooksService from "./Books"

class ApiService {


    latestprojectname = ""


    getProjectApi() {
    }


    getTaskApi() {

    }

}


export default BooksService;