import { createContext, useContext, useReducer, useState } from 'react';
import { Alert } from '../Components/UI/Alert/Alert';

interface AlertContextProps {
    message: string;
    isSuccess: boolean;
    showAlert: (message: string, type: string) => void;
  }

export const AlertType = {
    ERROR: "error",
    SUCCESS: "success"
}

// Define the initial state
const initialState = {
  message: "", // You can add different alert types like success, warning, error, etc.
  isSuccess: true
};

// Create the context
const AlertContext = createContext<AlertContextProps | undefined>(undefined);

// Create the reducer function
const alertReducer = (state: any, action: any) => {
  switch (action.type) {
    case AlertType.SUCCESS: 
        return {
          ...state,
          message: action.payload.message,
          isSuccess: true
        };
    
    case AlertType.ERROR:
      return {
        ...state,
        message: action.payload.message,
        isSuccess: false
      };
    default:
      return state;
  }
};

// Create the AlertProvider component
export const AlertProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(alertReducer, initialState);
  const [flags, setFlags] = useState<Array<number>>([]);

    const addFlag = () => {
        const newFlagId = flags.length + 1;
        const newFlags = flags.slice();
        newFlags.splice(0, 0, newFlagId);

        setFlags(newFlags);
    };

    const handleDismiss = () => {
        setFlags(flags.slice(1));
    };

  const showAlert = (message: string, type: string) => {
    dispatch({
      type: type,
      payload: { message },
    });
    addFlag()
  };

  return (
    <>
    <Alert isSuccess={state.isSuccess} message={state.message} flags={flags} handleDismiss={handleDismiss}  />
        <AlertContext.Provider value={{ ...state, showAlert }}>
                {children}
        </AlertContext.Provider>
    </>
  );
};

// Create a custom hook to use the alert context
export const useAlert = () => {
  return useContext(AlertContext);
};