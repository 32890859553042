import Button from "@atlaskit/button";
import { DatePicker } from "@atlaskit/datetime-picker";
import Modal, { ModalBody, ModalTransition } from "@atlaskit/modal-dialog";
import Select, { CheckboxSelect, OptionType } from '@atlaskit/select';
import "suneditor/dist/css/suneditor.min.css";
import CrossIcon from '@atlaskit/icon/glyph/cross';
import Textfield from '@atlaskit/textfield';
import { useEffect, useState } from "react";
import HTTPSCalls from "../../Services/HTTPCalls";
import LocalStorage from "../../Services/Localstorage";
import { ApprovalStatus, DurationToText, LogType } from "../../Pages/Milestone";
import { Loader } from "../UI/Loader/Loader";
import { formatDatedatetimeyeargforcreatetask } from "../../utils/DateConvertor";
const cardWidth = window.innerWidth;

export enum DurationType {
    Hours = 0,
    Days = 1,
    Months = 2,
    Years = 3,
    Date = 4
}

export interface DurationPost {
    stageId: string,
    milestoneId: string,
    type: DurationType | null,
    value: number | null,
    startDate: string,
    endDate: string
}

const StageDuration = ({ model, setModel, milestoneId, stage, GetSelectedMilestone, milestoneParantid, setMessage, addFlag }: any) => {

    const [durationType, setDurationType] = useState<DurationPost>( 
        {
            stageId: stage.id,
            milestoneId: milestoneId,
            type: stage.type,
            value: stage.value,
            startDate: stage.startDate,
            endDate: "1001-01-01T00:00:00.000Z"
        }
    )

    const [isLoading, setIsLoading] = useState<boolean>(false);
    var today = new Date(); 

    const AddDuration = async () => {
        setIsLoading(true)
        let response = await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.milestone.addDuration, durationType)
        if (response.result) {

            let logresponse = await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.milestone.addLog, {
                project: response.result.project,
                milestone: {
                    id: response.result.id,
                    name: response.result.name
                },
                user: {
                    id: LocalStorage.getItem("logindetail", "JSON").id,
                    name: LocalStorage.getItem("logindetail", "JSON").name,
                    thumb: LocalStorage.getItem("logindetail", "JSON").imageThumb
                },
                remarks: `Stage updated`,
                from: stage.value,
                to: durationType.type != DurationType.Date ? `${durationType.value} ${DurationToText(durationType.type)}` : `${durationType.startDate} - ${durationType.endDate}`,
                stage: stage.name,
                type: LogType.Extend
            })

            if (logresponse) {
                setModel({ ...model, duration: false })
                GetSelectedMilestone(milestoneParantid)
            }
        }
        else if (response.errors) {
            setMessage(response.message)
            addFlag()
        }
        setIsLoading(false)
    }
    console.log(stage, "stagestage")


    return (
        <>
            <ModalTransition>
                <Modal width="small">

                    <div style={{
                        display: "flex",
                        alignItems: "baseline",
                        justifyContent: "space-between",
                        marginTop: 14
                    }}>
                        <div style={{
                            fontSize: 14,
                            fontWeight: 600,
                            paddingLeft: 20
                        }}>
                            Add Time Duration
                        </div>
                        <div>
                            <div style={{ cursor: "pointer", marginRight: 18 }} onClick={
                                () => {
                                    setModel({ ...model, duration: false })
                                }}>

                                <CrossIcon
                                    label="Close Modal"
                                    size="medium"
                                    primaryColor="#bac2d0"

                                />

                            </div>
                        </div>
                    </div>


                    <hr style={{ color: "#F6F6F6", opacity: "0.2", marginBottom: "10px" }} />

                    <div >

                        <div style={{ padding: "15px", paddingLeft: "20px", paddingRight: "20px" }}>

                            <div style={{
                            }}>

                                <div style={{ display: "flex", justifyContent: "space-between", marginTop: 10 }}>
                                    <div style={{ width: 90, marginLeft: 20 }}>
                                        <Select
                                            inputId="checkbox-select-example"
                                            className="checkbox-select"
                                            menuPlacement="top"
                                            options={[
                                                { label: "Hours", value: DurationType.Hours },
                                                { label: "Days", value: DurationType.Days },
                                                { label: "Months", value: DurationType.Months },
                                                { label: "Years", value: DurationType.Years },
                                                /*{ label: "Date", value: DurationType.Date }*/
                                            ]}
                                            isDisabled={stage?.isApproved != ApprovalStatus.Pending}
                                            defaultValue={{ label: DurationToText(stage.type), value: stage.type }}
                                            onChange={(selectedOption: any) => {
                                                setDurationType({ ...durationType, type: selectedOption.value })
                                            }}
                                            styles={{
                                                control: (base: any) => {

                                                    return {
                                                        ...base,
                                                        backgroundColor: "#f6f8fc",
                                                        border: "none",

                                                    }

                                                }
                                            }}
                                        />
                                    </div>

                                    <div style={{ width: 240 }}>
                                        <Textfield
                                            appearance="standard"
                                            type="number"
                                            required
                                            isDisabled={stage?.isApproved != ApprovalStatus.Pending}
                                            style={{
                                                backgroundColor: "#f6f8fc",
                                                height: 40
                                            }}
                                            defaultValue={stage.value}
                                            onChange={(event: any) => {
                                                setDurationType({ ...durationType, value: event.target.value })
                                            }}
                                            autoFocus
                                            isRequired

                                        />
                                    </div>
                                </div>
                                {
                                    stage?.value ?
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: 10 }}>
                                            <label className="labelcolor" style={{
                                                fontSize: 14,
                                                marginRight: 20,
                                                marginLeft: 10
                                            }}>Start Date</label>
                                            <div style={{
                                                width: 240,
                                                fontSize: 14,
                                                marginTop: 5
                                            }}>
                                                <DatePicker
                                                    isDisabled={stage?.isApproved != ApprovalStatus.Pending}
                                                    onChange={(selectedDate: string) => setDurationType({ ...durationType, startDate: selectedDate })}
                                                    name="datetime-picker"
                                                    spacing="compact"
                                                    dateFormat="DD/MM/YYYY"
                                                    placeholder={formatDatedatetimeyeargforcreatetask(today.toString(), "not")}
                                                    appearance="default"
                                                />

                                            </div>

                                        </div>
                                        : ""
                                }
                            </div>

                            <div style={{
                                display: "flex",
                                justifyContent: "end",
                                marginTop: 20

                            }}>

                                <Button style={{ marginRight: "10px" }} appearance="subtle" onClick={
                                    () => {
                                        setModel({ ...model, duration: false })
                                    }}>
                                    Cancel
                                </Button>
                                <Button appearance="primary"
                                    style={{
                                        height: "35px",
                                        display: "flex",
                                        alignItems: "center",
                                        backgroundColor: "#3177ff",
                                        fontSize: "14px!important"
                                    }}
                                    onClick={AddDuration}
                                >
                                    Save
                                </Button>
                            </div>
                        </div>

                    </div>

                    <Loader isLoading={isLoading} />

                </Modal>
            </ModalTransition>
        </>
    )
}

export default StageDuration;