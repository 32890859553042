import Button from '@atlaskit/button';
import Drawer from '@atlaskit/drawer';
import CreateTask from '../sidenav/CreateTask';
import { useNavigate } from 'react-router-dom';
import Sidenavigation from '../Sidebarnavigation/Sidebarnavigation';
import { useState } from 'react';

const SideBar = ({isOpen, onClose}: {isOpen: boolean, onClose: () => void}) => {
    return (
        <>
                        <nav className={isOpen ? "nav-menu active" : "nav-menu"}>
                          <div style={{display: "flex"}} >
                              {
                                  <Sidenavigation onClose={onClose} />
                              } 
                          </div>
                      </nav>
        </>
    )
}


export default SideBar;