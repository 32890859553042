import Select, { OptionType } from "@atlaskit/select";
import { useEffect, useState } from "react";
import CreateMilestone from "../Components/Milestone/CreateMilestone";
import Filter, { FormData } from "../Components/Milestone/Filter";
import { Loader } from "../Components/UI/Loader/Loader";
import HTTPSCalls from "../Services/HTTPCalls";
import LocalStorage from "../Services/Localstorage";
import Button from '@atlaskit/button';
import StageDuration, { DurationType } from "../Components/Milestone/StageDuration";
import Modal, { ModalFooter, ModalHeader, ModalTitle, ModalTransition } from "@atlaskit/modal-dialog";
import MileStoneNewCard from "../Components/Milestone/MileStoneNewCard";
import MileStaneActivity from "../Components/Milestone/MileStaneActivity";
import { Alert } from "../Components/UI/Alert/Alert";
import { AlertConfig } from "../Components/UI/Alert/AlertConfig";
import { useLocation } from "react-router-dom";
import ConfirmationModal from "../Components/UI/ConfirmationModal";

export const enum ApprovalStatus {
    Pending = 0,
    Approved = 1,
    Done = 2
}

export const enum LogType {
    Create,
    Update,
    Extend,
    Attach,
    Approve
}

export interface MilestoneFilter {
    start?: number,
    length?: number,
    isAll?: boolean,
    withChild?: boolean,
    milestoneid?: NonNullable<string>,
    from?: string,
    to?: string
}

export interface MilestoneModel {
    create: boolean,
    approve: boolean,
    detail: boolean,
    duration: boolean,
    attach: boolean,
    stage: boolean
}

export interface MilestoneData {
    totalDuration: Array<{ type: DurationType, value: number }>,
    milestone: Array<any>
}

export enum ActivityType {
    Log = 0,
    Task = 1
}

export function DurationToText(duration: DurationType | null) {
    if (duration != null)
        switch (duration) {
            case DurationType.Days: return "Days";
            case DurationType.Hours: return "Hours";
            case DurationType.Months: return "Months";
            case DurationType.Years: return "Years";
            default: return ""
        }
}

const Milestone = () => {

    const location = useLocation();
    const urlArray = location.pathname.split("/");
    const UrlParam = urlArray[urlArray.length - 1];
    const { flags,
        addFlag,
        handleDismiss } = AlertConfig()
    const [message, setMessage] = useState<string | undefined>("");
    const [activityLoading, setActivityLoading] = useState<boolean>(false)
    const [isLoading, setLoading] = useState<boolean>(false)
    const [promiseLoading, setPromiseLoading] = useState<boolean>(false)
    const [activityType, setActivityType] = useState<ActivityType | null>(null)
    const [isExtend, setIsExtend] = useState<boolean>(false)
    const [isEdit, setIsEdit] = useState<boolean>(false)

    const [model, setModel] = useState<MilestoneModel>({
        create: false,
        approve: false,
        detail: false,
        duration: false,
        attach: false,
        stage: false
    })

    const [milestonedata, setMilestoneData] = useState<MilestoneData>({
        totalDuration: [],
        milestone: []
    })
    const [activity, setActivity] = useState<any>([])

    /*    const [selectOptions, setSelectOptions] = useState<Array<OptionType>>([])*/
    const [milestoneOptions, setMilestoneOptions] = useState<Array<OptionType>>([])
    const [isCreate, setIsCreate] = useState<boolean>(false)
    const [milestoneId, setMilestoneId] = useState<string>(UrlParam ? UrlParam : "")
    const [milestoneName, setMilestoneName] = useState<string>("")
    const [milestoneEditId, setMilestoneEditId] = useState<string>("")
    const [shouldExtend, setShouldExtend] = useState<boolean>(false)

    const [stage, setStage] = useState<{ id: string, name: string, value: any }>({
        id: "",
        name: "",
        value: ""
    })

    function RenderButton(index: number, milestone: any) {

        if (milestone.isApproved === ApprovalStatus.Pending) {
            return (
                <>
                    <Button type="submit" appearance="primary"
                        style={{ backgroundColor: "#0E51D3", marginRight: 5 }}
                        onClick={() => {
                            setMilestoneEditId(milestone?.id)
                            setIsEdit(true)
                            setIsExtend(false)
                            setModel({ ...model, create: true })
                        }}
                    >Edit</Button>

                    <Button type="submit" appearance="primary"
                        style={{ backgroundColor: "#0E51D3", marginRight: 5 }}
                        onClick={() => {
                            setMilestoneEditId(milestone?.id)
                            setModel({ ...model, approve: true })
                        }
                        }
                    >Approve</Button>
                </>
            )
        }
    }

    const GetSelectedMilestone = async (milestoneid: string) => {
        try {
            if (milestoneid != "") {
                setLoading(true)
                let response = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.milestone.getWithDuration + `/${milestoneid}`)
                if (response.result) {
                    let count = 0
                    response.result.milestone.map((arrayItems: any) => { if (arrayItems.isApproved === ApprovalStatus.Pending) count += 1 })
                    if (count === 0) {
                        setShouldExtend(true)
                    }
                    setMilestoneData(response.result)
                }
                setLoading(false)
            }
        } catch (err) { }
    }

    const GetProjects = async () => {
        let response = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.project.get + "?", { start: 0, length: 100, uid: LocalStorage.getItem("logindetail", "JSON").id })
        if (response.result)
            setSelectOptions(response.result.map((item: any) => ({ label: item.name, value: item.id })))
    }

    const GetAttachedTask = async (milestoneid: string) => {
        setActivityLoading(true)
        let response = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.milestone.attachedTask + `/${milestoneid}`)
        if (response.result)
            setActivity(response.result)
        setActivityLoading(false)
    }

    const GetLog = async (milestoneid: string) => {
        let response = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.milestone.getLog + `/${milestoneid}`)
        if (response.result)
            setActivity(response.result)
    }

    const GetMilestones = async () => {
        let response = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.milestone.getAll + "?", { isAll: true, withEntend: false })
        if (response.result)
            setMilestoneOptions(response.result.map((item: any) => ({ label: item.name, value: item.id })))
    }

    const ApproveMilestone = async () => {
        setLoading(true)
        let response = await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.milestone.approve + `/${milestoneEditId}`, {})
        if (response.result) {
            let logresponse = await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.milestone.addLog, {
                project: response.result.project,
                milestone: {
                    id: response.result.id,
                    name: response.result.name
                },
                user: {
                    id: LocalStorage.getItem("logindetail", "JSON").id,
                    name: LocalStorage.getItem("logindetail", "JSON").name,
                    thumb: LocalStorage.getItem("logindetail", "JSON").imageThumb
                },
                remarks: `milestone approved`,
                from: "",
                to: "",
                Stage: "",
                type: LogType.Approve
            })
            if (logresponse.result)
                GetSelectedMilestone(milestoneId != "" ? milestoneId : UrlParam)
        }
        else {
            setMessage(response.message)
            addFlag()
        }
        setLoading(false)
    }

    const DoneStage = async (milestoneid: string, stageid: string) => {
        setPromiseLoading(true)
        let response = await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.stage.done, { milestoneid, stageid })
        if (response.result) {
            GetSelectedMilestone(milestoneId)
        }
        else if (response.errors) {
            setMessage(response.message)
            addFlag()
        }
        setPromiseLoading(false)
    }

    const HandleSearch = (data: FormData, milestoneid: string) => {
        return Promise.all([
            GetSelectedMilestone(data.select.value.toString()),
            GetAttachedTask(milestoneid),
        ])
    }

    useEffect(() => {
        if (activityType === ActivityType.Log)
            GetLog(milestoneId != "" ? milestoneId : UrlParam)
        else if (activityType === ActivityType.Task)
            GetAttachedTask(milestoneId != "" ? milestoneId : UrlParam)
    }, [activityType])

    useEffect(() => {
        Promise.all([
            GetProjects(),
            GetMilestones(),
            GetSelectedMilestone(UrlParam)
        ])
    }, [])

    return (
        <>
            <Alert isSuccess={false} message={message} flags={flags} handleDismiss={handleDismiss} />
            <Loader isLoading={isLoading} />
            <div style={{ backgroundColor: "#F6F8FC", display: "flex" }}>

                <div style={{ width: `${activityType != null ? "70%" : "100%"}`, height: "93vh", overflowX: "scroll" }}>
                    <Filter selectOptions={milestoneOptions} setIsEdit={setIsEdit} shouldExtend={shouldExtend} setIsCreate={setIsCreate} handleSearch={HandleSearch} model={model} setModel={setModel} setMilestoneName={setMilestoneName}
                        milestoneid={milestoneId} setIsExtend={setIsExtend} setMilestoneId={setMilestoneId} setMessage={setMessage} addFlag={addFlag} />

                    {milestonedata.milestone.length > 0 ?
                        <MileStoneNewCard milestonedata={milestonedata} RenderButton={RenderButton} setActivityType={setActivityType} setMilestoneEditId={setMilestoneEditId}
                            model={model} setModel={setModel} stage={stage} setStage={setStage} DoneStage={DoneStage} />
                        :
                        <div>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "10%"
                            }}>
                                <img src={process.env.PUBLIC_URL + "/noTask.svg"} />
                            </div>
                            <div style={{ textAlign: "center" }}>
                                <h3 style={{ margin: 0, fontFamily: "lato", fontSize: 20 }}>Please select milestone</h3>
                            </div>
                        </div>
                    }
                </div>

                {
                    activityType != null &&
                    <div style={{ width: "30%", backgroundColor: "white", marginLeft: "10px", boxShadow: "0px 0px 10px 0px rgba(146, 166, 195, 0.58)", }}>
                        <div>
                            <MileStaneActivity activity={activity} activityType={activityType} activityLoading={activityLoading} />
                        </div>
                    </div>
                }
            </div>

            {model.create && <CreateMilestone model={model} setModel={setModel} CreateCallback={GetSelectedMilestone}
                isExtend={isExtend} isEdit={isEdit} isCreate={isCreate} setMessage={setMessage} addFlag={addFlag}
                setShouldExtend={setShouldExtend} milestoneid={milestoneEditId} milestoneName={milestoneName} milestoneParantid={milestoneId != "" ? milestoneId : UrlParam} />}
            {model.duration && <StageDuration model={model} setModel={setModel} milestoneId={milestoneEditId} milestoneParantid={milestoneId}
                setMessage={setMessage} addFlag={addFlag} stage={stage} GetSelectedMilestone={GetSelectedMilestone} />}

            <ConfirmationModal isOpen={model.approve} closeModal={() => setModel({ ...model, approve: false })}
                message="Are you sure you want to approve this milestone" handleConfirm={() => {
                    ApproveMilestone()
                    setModel({ ...model, approve: false })
                }} />

            <ConfirmationModal isOpen={model.stage} closeModal={() => setModel({ ...model, approve: false })}
                message="Are you sure you want to done this stage" handleConfirm={() => {
                    ApproveMilestone()
                    setModel({ ...model, approve: false })
                }} />

        </>
    )
}
export default Milestone;