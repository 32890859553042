import DynamicTable from '@atlaskit/dynamic-table';
import { useEffect, useState } from 'react';
import ReactStars from 'react-stars'
import Avatar from '@atlaskit/avatar';
import BooksService from '../../Services/Apicall';
import StarLargeIcon from '@atlaskit/icon/glyph/star-large'
import Spinner from '@atlaskit/spinner';
import Select from '@atlaskit/select';

export default function Userrating({ userbyproject, projectname, isloading }: any) {


    const starrating = (text: string, month: number, id: string) => {
        return (
            <ReactStars
                count={5}
                size={20}
                color2={'#ffd700'} />
        )
    }

    const createHead = (withWidth: boolean) => {
        return {
            cells: [
                {
                    key: 'User',
                    content: 'User',
                    isSortable: true,
                    width: 5
                },
                {
                    key: 'JAN',
                    content: 'JAN',
                    isSortable: true,
                    width: 5
                },
                {
                    key: 'FAB',
                    content: 'FAB',
                    isSortable: true,
                    width: 5
                },

                {
                    key: 'MAR',
                    content: 'MAR',
                    isSortable: true,
                    width: 5
                },

                {
                    key: 'APR',
                    content: 'APR',
                    isSortable: true,
                    width: 5
                },
                {
                    key: 'MAY',
                    content: 'MAY',
                    isSortable: true,
                    width: 5
                },
                {
                    key: 'JUN',
                    content: 'JUN',
                    isSortable: true,
                    width: 5
                },
                {
                    key: 'JUL',
                    content: 'JUL',
                    isSortable: true,
                    width: 5
                },
                {
                    key: 'AUG',
                    content: 'AUG',
                    isSortable: true,
                    width: 5
                },
                {
                    key: 'SEP',
                    content: 'SEP',
                    isSortable: true,
                    width: 5
                },
                {
                    key: 'OCT',
                    content: 'OCT',
                    isSortable: true,
                    width: 5
                },
                {
                    key: 'NOV',
                    content: 'NOV',
                    isSortable: true,
                    width: 5
                },
                {
                    key: 'DEC',
                    content: 'DEC',
                    isSortable: true,
                    width: 5
                },

            ],
        };
    };

    const head = createHead(true);

    return (

        <div style={{
            fontSize: 15,
            marginLeft: 50,
            marginRight: 50,
        }}>

            {
                isloading && projectname != "select a project" ?
                    <div style={{
                        marginLeft: "50%"
                    }}>
                        <Spinner interactionName="load" size="medium" />
                    </div>
                    :
                    <DynamicTable
                        head={head}
                        rows={userbyproject.map((data: any, index: number) => ({
                            key: `row-${index}-${data.id}`,
                            isHighlighted: false,
                            cells: [
                                {
                                    content: (

                                        <div style={{
                                            display: "flex",
                                            alignItems: "center",
                                            fontSize: 14,
                                            fontFamily: "lato"
                                        }}>
                                            <Avatar size="medium" />
                                            <div style={{ marginLeft: 5 }}>{data.name}</div>
                                        </div>
                                    )
                                },
                                {
                                    content: data.jan
                                },
                                {
                                    content: data.feb
                                },
                                {
                                    content: data.mar
                                },
                                {
                                    content: data.apr
                                },
                                {
                                    content: data.may
                                },
                                {
                                    content: data.jun
                                },
                                {
                                    content: data.jul
                                },
                                {
                                    content: data.aug
                                },
                                {
                                    content: data.sep
                                },
                                {
                                    content: data.oct
                                },
                                {
                                    content: data.nov
                                },
                                {
                                    content: data.dec
                                },
                            ],
                        }))}
                        rowsPerPage={8}
                        defaultPage={1}
                        loadingSpinnerSize="large"
                    />
            }



        </div>
    );
}
