import { AutoDismissFlag, FlagGroup } from '@atlaskit/flag';
import { G300 } from '@atlaskit/theme/colors';
import SuccessIcon from '@atlaskit/icon/glyph/check-circle';
import { token } from "@atlaskit/tokens";
import { R400 } from '@atlaskit/theme/colors';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import { useState } from 'react';


export const Alert = ({ isSuccess, message, flags, handleDismiss }: any) => {


    return (
        <>
            <FlagGroup onDismissed={handleDismiss}>
                {flags?.map((flagId: any) => {
                    return (
                        <AutoDismissFlag
                            id={flagId}
                            icon={
                                isSuccess ?
                                    <SuccessIcon
                                        primaryColor={token('color.icon.success', G300)}
                                        label="Success"
                                    />
                                    :
                                    <ErrorIcon
                                        primaryColor={token('color.icon.success', R400)}
                                        label="Error"
                                    />

                            }
                            key={flagId}
                            title={message}


                        />
                    );
                })}
            </FlagGroup>
        </>
    )
}