import { useState } from "react"
import InfinitScroll from 'react-infinite-scroll-component'
import Spinner from '@atlaskit/spinner';
import IssuesTable from "../Components/IssuesTable/IssuesTable";
import HTTPSCalls from "../Services/HTTPCalls";
import NoData from "../Components/UI/NoData";
import { IssueFilter } from "../Components/Filters/IssueFilter";
import './IssuesPage.css'
export enum userType {
    All,
    Assignee,
    AssignedBy,
    Reporter
}

interface IUserFilter {
    userType: userType,
    id: string
}

interface IFormData {
    project: string[],
    SearchText: string,
    status: number[],
    priority: number,
    userFilter: IUserFilter
}

const IssuePage = () => {

    const [projectname, setprojectname] = useState(localStorage.getItem("name"))
    const [userbyproject, setuserbyproject] = useState<any>([])
    const [startpage, setStartpage] = useState(0)
    const [blockapi, setBlockapi] = useState(false)
    const [scrollloading, setScrollloading] = useState(false)
    const [issuechange, setissuechange] = useState(0)
    const [isloading, setisloading] = useState(false)
    const [formData, setFormData] = useState<IFormData>({
        project: [], SearchText: "", status: [0, 1, 2, 3, 4, 5, 7], priority: -1,
        userFilter: { userType: userType.All, id: "" }
    });

    const getReportTasks = async (withSearch: boolean = false) => await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.task.issues + "?",
        formData, { start: withSearch ? 0 : startpage, length: 30 });

    const ReporterCall = async (isManual: boolean = false) => {
        if (!isManual) {
            setScrollloading(true)
            setBlockapi(true)
            let res = await getReportTasks();
            if (res.result && res?.result?.length > 0) {
                setuserbyproject([...userbyproject, ...res.result])
                setStartpage(startpage + 30)
                setScrollloading(false)
                setBlockapi(true)
            }
            else {
                setScrollloading(false)
            }
        }
        else {
            setisloading(true)
            let res = await getReportTasks(true);
            if (res) {
                setStartpage(30)
                setuserbyproject(res.result) 
            }
            setisloading(false)
        }
    }



    return (
        <>
            <div >
                <div>
                    <IssueFilter ReporterCall={ReporterCall} setFormData={setFormData} />
                </div>
                <div>
                    {
                        isloading ?
                            <div style={{ textAlign: "center", marginTop: "20%" }}>
                                <Spinner size="medium" />
                            </div>
                            :
                            <>
                                {
                                    userbyproject?.length > 0 ?

                                        <InfinitScroll
                                            dataLength={startpage}
                                            next={() => {
                                                ReporterCall()
                                            }}
                                            hasMore={true}
                                            scrollableTarget="scrollableDiv"
                                            loader={isloading && <h4></h4>}
                                        >
                                            <div id="scrollableDiv" style={{
                                                display: "block",
                                                height: "78vh",
                                                overflowX: "scroll"
                                            }}>
                                                <IssuesTable userbyproject={userbyproject} getTask={getReportTasks} setissuechange={setissuechange} projectname={projectname} isloading={isloading} />

                                            </div>
                                            {  
                                                scrollloading &&
                                                <div style={{ margin: 0, marginTop: -18, textAlign: "center" }}>
                                                    <Spinner size="medium" />
                                                </div>
                                            }
                                        </InfinitScroll>
                                        :
                                        <div style={{marginTop:"13%"} }>
                                            <NoData message="no task available" />
                                        </div>
                                }
                            </>
                    }
                </div>
            </div>


        </>
    )
}

export default IssuePage