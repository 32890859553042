import { useEffect } from "react";
import { RouterProvider, redirect } from "react-router-dom"
import { AppRoutes } from "./PageRoutes/Index"
import { AlertProvider } from "./Contexts/AlertContext"
import { protectedRoutes } from "./PageRoutes/PrivateRoutes"
import { AppProvider, useApp } from "./Contexts/AppContext"
import "./App.css"
import { isTokenNotExpired } from "./utils/Util";


const App = () => {


    return (
        <AppProvider>
        <AlertProvider>
            <RouterProvider
                router={protectedRoutes}
            />
            <AppRoutes />
        </AlertProvider>
        </AppProvider>
    )
}

export default App