import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalTitle,
    ModalTransition,
} from '@atlaskit/modal-dialog';
import Button from '@atlaskit/button/standard-button';

const AlertModal = ({ isOpenconfirm, openModalconfirm, handleattachmentdelete, imageobject, closeModelconfirm, handleclose, type }: any) => {
    return (
        <ModalTransition>
            {isOpenconfirm && (
                <Modal width="small" onClose={closeModelconfirm}>
                    <ModalHeader>
                        {
                            type === "detailmodal" ?
                                <ModalTitle>Are you sure?</ModalTitle>
                                :
                        <ModalTitle>Your changes won’t be saved</ModalTitle>
                        }
                    </ModalHeader>
                    <ModalBody>
                        {
                            type === "detailmodal" ?
                        <p style={{ paddingLeft: 25, paddingRight: 25, margin: 0, fontSize: 14 }}>Attachment will be deleted after confirmation.</p>
                                :
                        <p style={{paddingLeft: 25, paddingRight: 25, margin: 0, fontSize: 14}}>We won’t be able to save your data if you move away from this page.</p>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button appearance="subtle" onClick={closeModelconfirm}>
                            Cancel
                        </Button>
                        <Button appearance="primary" style={{ height: "35px", display: "flex", alignItems: "center", backgroundColor: "#3177ff", fontSize: "14px!important" }} onClick={() => {
                            closeModelconfirm()
                            if (type === "createtask") {
                                handleclose()
                            }
                            else if (type === "detailmodal") {
                                handleattachmentdelete(imageobject)
                            }
                        }
                        } autoFocus>
                            Confirm
                        </Button>
                    </ModalFooter>
                </Modal>
            )}
        </ModalTransition>
        )
}

export default AlertModal;