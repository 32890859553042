import {
    AtlassianNavigation,
    PrimaryButton
} from "@atlaskit/atlassian-navigation";
import ProfileDropdown from "./Useravatar";
import BulletListIcon from '@atlaskit/icon/glyph/bullet-list'
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import SuccessIcon from '@atlaskit/icon/glyph/check-circle';
import { G300 } from '@atlaskit/theme/colors';
import TaskModel, { CreateType } from "../DetaliModels/TaskModel";
import { token } from '@atlaskit/tokens';
import Flag from '@atlaskit/flag';
import Drawer from '@atlaskit/drawer';
import LocalStorage from "../../Services/Localstorage";
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import LabelIcon from '@atlaskit/icon/glyph/label'
import SideBar from './Sidebar';
import ActivityIcon from '@atlaskit/icon/glyph/activity'
import Button from '@atlaskit/button/standard-button';
import './Navbar.css'
import CreateTask from "../sidenav/CreateTask";
const AtlassianProductHome = (setSidebar: any, handleclick: any) => {


    const [isSidebar, setIsSidebar] = useState(false);

    return (
        <div
            style={{
                display: "flex",
                cursor: "pointer"
            }}>
            {
                JSON.parse(localStorage.getItem("logindetail") || "{}").role === "Admin" &&
                <div style={{ 

                    margin: 14,
                    marginTop: 10
                }} onClick={() => setIsSidebar(true)}>
                    <img style={{ paddingTop: "2px", height: "12px", width: "18px" }} src={process.env.PUBLIC_URL + "/sidebaricon.svg"} alt="" />
                    {/* <OverviewIcon primaryColor="#bac2d0" label="overview" size="medium" />*/}
                </div>
            }
            <Link to={`${JSON.parse(localStorage.getItem("logindetail") || "{}").role === "Admin" ? "/admin/dashboard" : "/dashboard"}`}>
                <div style={{ paddingLeft: "14px", paddingRight: "48px" }} onClick={() => handleclick("mywork")}>
                    <img style={{ paddingTop: "8px", height: "28px" }} src={process.env.PUBLIC_URL + "/Logo1.svg"} alt="" />
                </div>
            </Link>
            <SideBar isOpen={isSidebar} onClose={() => setIsSidebar(false)} />
        </div>
    )
};
const TopNav = ({ openModal, isOpen, setSidebar, closeModal, modelType, setbacklogrefresh }: any) => {
    const [open, setOpen] = useState<boolean>(false);

    const [showeventresponsemodel, setshoweventresponsemodel] = useState(false)
    const [eventtype, seteventtype] = useState("")
    const [width, setWidth] = useState(window.innerWidth)

    let currentUrl: string | string[] = window.location.href.split("/");
    currentUrl = currentUrl[currentUrl.length - 1]

    const navigate = useNavigate()

    const handleclick = async (pagename: string, item: any) => {
        await LocalStorage.setItem("page", pagename);

        if (pagename === "report") {
            navigate("/report/reporting", { state: { userid: item.id, email: item.email, name: item.name } })
        }

    }
    window.addEventListener('resize', () => { setWidth(window.innerWidth) })

    const [isOpenCreate, setIsOpenCreate] = useState(false);
    const openModalCreate = () => setIsOpenCreate(true)
    const closeModalCreate = () => setIsOpenCreate(false)

    return (
        <>

            <Drawer onClose={() => setOpen(false)} isOpen={open} >
                <div>
                    <div>
                        <img style={{ paddingTop: "10px", height: "32px" }} src={process.env.PUBLIC_URL + "/Logo.svg"} alt="" />
                    </div>
                    <Button style={{ backgroundColor: "white" }} onClick={() => openModal("Project")} appearance="primary"><ActivityIcon primaryColor="black" label="person" /> <span className="navpara"> Create Project</span> </Button>
                </div>
                <div style={{ paddingTop: "20px" }}>
                    <Button style={{ backgroundColor: "white" }} onClick={() => openModal("Lable")} appearance="primary"><LabelIcon primaryColor="black" label="person" /> <span className="navpara"> Create Label </span> </Button></div>

                <div style={{ paddingTop: "20px" }}>
                    <Button style={{ backgroundColor: "white" }} onClick={() => openModal("Sprint")} appearance="primary"><BulletListIcon primaryColor="black" label="person" /> <span className="navpara"> Create Sprint </span> </Button></div>
                <div style={{ paddingTop: "20px" }}>
                    <Button style={{ backgroundColor: "white" }} onClick={() => openModal("updateproject")} appearance="primary"><BulletListIcon primaryColor="black" label="person" /> <span className="navpara"> Assign Project </span> </Button></div>
                {/*<div style={{ paddingTop: "20px" }}>
                    <Button style={{ backgroundColor: "white" }} onClick={() => {
                        navigate("../userrating")
                    }} appearance="primary"><BulletListIcon primaryColor="black" label="person" /> <span className="navpara"> Rating </span> </Button></div>*/}

            </Drawer>
            {
                showeventresponsemodel &&
                <div className="alertmodel">
                    <Flag
                        icon={
                            <SuccessIcon
                                primaryColor={token('color.icon.success', G300)}
                                label="Success"
                            />
                        }
                        id="1"
                        key="1"
                        title={`${eventtype} successfully`}
                    />
                </div>
            }
            <CreateTask isOpen={isOpen} openModal={openModal} seteventtype={seteventtype} setshoweventresponsemodel={setshoweventresponsemodel} closeModal={closeModal} modelType={modelType} />

            <AtlassianNavigation
                label="site"
                
                primaryItems={[


                    <Button appearance="primary" style={{ height: "32px", width: "98px", display: "flex", alignItems: "center", marginTop: "15px", margin: `${width < 550 ? "0 auto" : "none"}`, backgroundColor: "#0E51D3", fontSize: "14px", marginRight: "34px" }} onClick={openModalCreate}>Create</Button>,

                    <Link onClick={() => handleclick("dashboard", "")} to={`${JSON.parse(localStorage.getItem("logindetail") || "{}").role === "Admin" ? "/admin/dashboard" : "/dashboard"}`} style={{
                        textDecoration: "none",
                        borderBottom: `${currentUrl === "dashboard" ? `3px solid #0E51D3` : ``}`,
                        transitionProperty: "borderBottom",
                        fontSize: "5!important",
                        transitionDuration: "1",
                        color: `${currentUrl === "dashboard" ? `#0049b0` : `black`}`,
                        marginLeft: "32px",
                        marginRight: "20px"
                    }}><PrimaryButton style={{ opacity: `${currentUrl != "dashboard" ? `1` : `1`}`, fontWeight: `${currentUrl === "dashboard" ? `500` : `400`}`, fontFamily: "lato", color: `${currentUrl === "dashboard" ? `red` : `#364663`}`, }}><div style={{ display: "flex", fontWeight: 500, fontSize: "14px" }}><div style={{ marginRight: 11.5, fontWeight: 500, marginTop: "3px" }}><img style={{ height: "14px" }} src={process.env.PUBLIC_URL + `${currentUrl === "dashboard" ? "/mywork_blue.svg" : "/mywork.svg"}`} alt="" /></div><p style={{ marginTop: "-1px", fontSize: "16px", fontWeight: 500, color: `${currentUrl === "dashboard" ? `#0E51D3` : `#44546F`}`, fontStyle: "normal" }}>{`${JSON.parse(localStorage.getItem("logindetail") || "{}").role === "Admin" ? "My work" : "My work"}`}</p></div></PrimaryButton></Link>,

                    <Link onClick={() => handleclick("board", "")} to="/board" style={{
                        textDecoration: "none",
                        fontSize: "5!important",
                        borderBottom: `${currentUrl === "board" ? `3px solid #0E51D3` : ``}`,
                        color: `${currentUrl === "board" ? `#0049b0` : `black`}`,
                        marginLeft: "20px",
                        marginRight: "20px"
                    }}><PrimaryButton style={{
                        opacity: `${currentUrl != "board" ? `1
                    ` : `1`}`, fontWeight: `${currentUrl === "board" ? `500` : `400`}`, fontFamily: "lato", color: `${currentUrl === "board" ? `#1f5fba` : `#364663`}`,
                    }}><div style={{ display: "flex", fontWeight: 500, fontSize: "14px" }}><div style={{ marginRight: 11.5, fontWeight: 500, marginTop: "3px" }} ><img style={{ color: "#364663", height: "14px" }} src={process.env.PUBLIC_URL + `${currentUrl === "board" ? "/board_blue.svg" : "/board_default.svg"}`} alt="" /></div><p style={{ marginTop: "1px", fontSize: "16px", fontWeight: 500, color: `${currentUrl === "board" ? `#0E51D3` : `#44546F`}` }}> Board</p></div></PrimaryButton></Link>,
                    <Link onClick={() => handleclick("backlog", "")} to="/backlog" style={{
                        textDecoration: "none",
                        fontSize: "5!important",
                        borderBottom: `${currentUrl === "backlog" ? `3px solid #0E51D3` : ``}`,
                        marginLeft: "20px",
                        marginRight: "20px"
                    }}><PrimaryButton style={{ opacity: `${currentUrl != "backlog" ? `0.` : `1`}`, fontWeight: `${currentUrl === "backlog" ? `500` : `400`}`, fontFamily: "lato", color: `${currentUrl === "backlog" ? `#1f5fba` : `#364663`}`, }}><div style={{ display: "flex", fontWeight: 500, fontSize: "14px" }}><div style={{ marginRight: 11.5, fontWeight: 500, marginTop: "3px" }} ><img style={{ color: "#364663", height: "14px" }} src={process.env.PUBLIC_URL + `${currentUrl === "backlog" ? "/backlog_blue.svg" : "/backlog_default.svg"}`} alt="" /></div><p style={{ marginTop: "-1px", fontSize: "16px", fontWeight: 500, color: `${currentUrl === "backlog" ? `#0E51D3` : `#44546F`}`, fontStyle: "normal" }}>Backlog</p></div></PrimaryButton></Link>,

                    <Link onClick={() => handleclick("peoplereport", "")} to="/peoplereport" style={{
                        textDecoration: "none",
                        fontSize: "5!important",
                        borderBottom: `${currentUrl === "peoplereport" ? `3px solid #0E51D3` : ``}`,
                        marginLeft: "20px",
                        marginRight: "20px"
                    }}><PrimaryButton style={{ opacity: `${currentUrl != "peoplereport" ? `0.` : `1`}`, fontWeight: `${currentUrl === "peoplereport" ? `500` : `400`}`, fontFamily: "lato", color: `${currentUrl === "peoplereport" ? `#1f5fba` : `#364663`}`, }}><div style={{ display: "flex", fontWeight: 500, fontSize: "14px" }}><div style={{ marginRight: 11.5, fontWeight: 500, marginTop: "3px" }} ><img style={{ color: "#364663", height: "14px" }} src={process.env.PUBLIC_URL + `${currentUrl === "peoplereport" ? "/people_blue.svg" : "/people_default.svg"}`} alt="" /></div><p style={{ marginTop: "-1px", fontSize: "16px", fontWeight: 500, color: `${currentUrl === "peoplereport" ? `#0E51D3` : `#44546F`}`, fontStyle: "normal" }}>People</p></div></PrimaryButton></Link>,
                    /*<Link onClick={() => handleclick("issues", "")} to="/reports" style={{
                        textDecoration: "none",
                        alignItems: "center",
                        fontSize: "5!important",
                        borderBottom: `${currentUrl === "reports" ? `3px solid #0049B0` : ``}`,
                        marginLeft: "15px"
                    }}>
                        
                        <PrimaryButton style={{ opacity: `${currentUrl != "reports" ? `0.` : `1`}`, fontWeight: `${currentUrl === "reports" ? `600` : `400`}`, fontFamily: "lato", color: `${currentUrl === "reports" ? `#1f5fba` : `#364663`}`, }}>
                            
                            <div style={{ display: "flex", fontWeight: 500, fontSize: "14px" }}>
                                <div style={{ marginRight: 6, fontWeight: 600 }} >
                                    <PageIcon label="report" primaryColor="#8D9BA9" />
                                </div>
                                <p style={{ marginTop: "-1px", fontSize: "16px", fontWeight: 500, color: "#44546F", fontStyle: "normal" }}>Reports</p>
                            </div>
                        </PrimaryButton>
                    </Link>*/
                    ,
                    /*
   
    <Link onClick={() => handleclick("rateing", "")} to="/rating" style={{
        textDecoration: "none",
        fontSize: "5!important",
        borderBottom: `${currentUrl === "rating" ? `3px solid #0049B0` : ``}`,
        marginLeft: "32px"
            }}><PrimaryButton style={{ opacity: `${currentUrl != "rating" ? `0.` : `1`}`, fontWeight: `${currentUrl === "rating" ? `600` : `400`}`, fontFamily: "lato", color: `${currentUrl === "rating" ? `#1f5fba` : `#364663`}`, }} ><div style={{ display: "flex", fontWeight: 500, fontSize: "14px" }}><div style={{ marginRight: 6, fontWeight: 600 }} ><TaskIcon primaryColor="#7d8799" label="issues" size="medium" /></div><p style={{ marginTop: "1px", fontSize: "14px" }}>Rating</p></div></PrimaryButton></Link>,
    */
                    <Link onClick={() => handleclick("meeting", "")} to="/meeting" style={{
                        textDecoration: "none",
                        fontSize: "5!important",
                        borderBottom: `${currentUrl === "meeting" ? `3px solid #0E51D3` : ``}`,
                        marginLeft: "20px",
                        marginRight: "20px"
                    }}><PrimaryButton style={{ opacity: `${currentUrl != "rating" ? `0.` : `1`}`, fontWeight: `${currentUrl === "rating" ? `500` : `400`}`, fontFamily: "lato", color: `${currentUrl === "rating" ? `#1f5fba` : `#364663`}`, }}  ><div style={{ display: "flex", fontWeight: 500, fontSize: "14px" }}><div style={{ marginRight: 11.5, fontWeight: 500, marginTop: "3px" }} ><img style={{ color: "#7d8799" }} src={process.env.PUBLIC_URL + `${currentUrl === "meeting" ? "/meeting_blue.svg" : "/meeting_default.svg"}`} alt="" /></div><p style={{ marginTop: "-1px", fontSize: "16px", fontWeight: 500, color: `${currentUrl === "meeting" ? `#0E51D3` : `#44546F`}`, fontStyle: "normal" }}>Meetings</p></div></PrimaryButton></Link>,


                    <Link onClick={() => handleclick("milestone", "")} to="/milestone" style={{
                        textDecoration: "none",
                        fontSize: "5!important",
                        borderBottom: `${currentUrl === "milestone" ? `3px solid #0E51D3` : ``}`,
                        marginLeft: "20px",
                        marginRight: "20px"
                    }}><PrimaryButton style={{ opacity: `${currentUrl != "milestone" ? `0.` : `1`}`, fontWeight: `${currentUrl === "milestone" ? `500` : `400`}`, fontFamily: "lato", color: `${currentUrl === "milestone" ? `#1f5fba` : `#364663`}`, }}  ><div style={{ display: "flex", fontWeight: 500, fontSize: "14px" }}><div style={{ marginRight: 11.5, fontWeight: 500, marginTop: "3px" }} ><img style={{ color: "#7d8799" }} src={process.env.PUBLIC_URL + `${currentUrl === "milestone" ? "/milestone_blue.svg" : "/milestone_default.svg"}`} alt="" /></div><p style={{ marginTop: "-1px", fontSize: "16px", fontWeight: 500, color: `${currentUrl === "milestone" ? `#0E51D3` : `#44546F`}`, fontStyle: "normal" }}>Milestones</p></div></PrimaryButton></Link>,
                    <div style={{ display: "flex", alignItems: "center", marginLeft: "20px" }}>
                        <DropdownMenu trigger="Reports">
                            <DropdownItemGroup>
                                <DropdownItem  onClick={() => navigate("reports/issues")} >
                                    <Link style={{
                                        color: "rgba(37, 49, 72, 1)",
                                        fontFamily: "lato",
                                        marginTop: "-1px",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        fontStyle: "normal",
                                        textDecoration: "none"
                                    }} to="reports/issues">
                                        Issues
                                    </Link>
                                </DropdownItem>
                                <DropdownItem  onClick={() => navigate("reports/inhand")} >
                                    <Link  style={{
                                        color:"rgba(37, 49, 72, 1)",
                                        fontFamily: "lato",
                                        marginTop: "-1px",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        fontStyle: "normal",
                                        textDecoration: "none"
                                    }} to="reports/inhand">
                                        In Hand
                                    </Link>
                                </DropdownItem>
                                {/*<div className="reportDrop" onClick={() => navigate("reports/taskreport")} style={{
                                    
                                    padding: "8px",
                                    marginBottom: "2px"
                                }}>
                                    <Link className="reportDetail" style={{
                                        fontFamily: "lato",
                                        marginTop: "-1px",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        fontStyle: "normal",
                                        textDecoration: "none"
                                    }} to="reports/taskreport">
                                        Day Difference
                                    </Link>
                                </div>
                                <div className="reportDrop" onClick={() => navigate("reports/weeklydonetasks")} style={{
                                    
                                    padding: "8px",
                                    marginBottom: "2px"
                                }}>
                                    <Link className="reportDetail" style={{
                                        fontFamily: "lato",
                                        marginTop: "-1px",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        fontStyle: "normal",
                                        textDecoration: "none"
                                    }} to="reports/weeklydonetasks">
                                        Weekly Done
                                    </Link>
                                </div>
                                <div className="reportDrop" onClick={() => navigate("reports/sprintreport")} style={{
                                   
                                    padding: "8px",
                                    marginBottom: "2px"
                                }}>
                                    <Link className="reportDetail" style={{
                                        
                                        fontFamily: "lato",
                                        marginTop: "-1px",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        fontStyle: "normal",
                                        textDecoration: "none"
                                    }} to="reports/sprintreport">
                                        Sprint wise done task
                                    </Link>
                                </div>*/}
                            </DropdownItemGroup>
                        </DropdownMenu>
                       
                    </div>
                    /* <Link onClick={() => handleclick("milestone", "")} to="/new" style={{
                         textDecoration: "none",
                         fontSize: "5!important",
                         borderBottom: `${currentUrl === "milestone" ? `3px solid #0049B0` : ``}`,
                         marginLeft: "32px"
                     }}><PrimaryButton style={{ opacity: `${currentUrl != "milestone" ? `0.` : `1`}`, fontWeight: `${currentUrl === "milestone" ? `600` : `400`}`, fontFamily: "lato", color: `${currentUrl === "milestone" ? `#1f5fba` : `#364663`}`, }}  ><div style={{ display: "flex", fontWeight: 500, fontSize: "14px" }}><div style={{ marginRight: 6, fontWeight: 600, marginTop: "3px" }} ><img style={{ color: "#7d8799" }} src={process.env.PUBLIC_URL + "/meeting.svg"} alt="" /></div><p style={{ marginTop: "1px", fontSize: "14px" }}>new</p></div></PrimaryButton></Link>,
 */
                    /*   <Link onClick={() => handleclick("admin", "")} to="/admin/dashboard" style={{
                           textDecoration: "none",
                           fontSize: "5!important",
                           borderBottom: `${currentUrl === "admin" ? `3px solid #0049B0` : ``}`,
                           marginLeft: "32px"
                       }}><PrimaryButton style={{ opacity: `${currentUrl != "rating" ? `0.` : `1`}`, fontWeight: `${currentUrl === "rating" ? `600` : `400`}`, fontFamily: "lato", color: `${currentUrl === "rating" ? `#1f5fba` : `#364663`}`, }}  ><div style={{ display: "flex", fontWeight: 500, fontSize: "14px" }}><div style={{ marginRight: 6, fontWeight: 600, marginTop: "3px" }} ><PersonCircleIcon primaryColor="#868fa0" label="d" /></div><p style={{ marginTop: "1px", fontSize: "14px" }}>Admin</p></div></PrimaryButton></Link>,
   */

                    /* <div style={{
                         marginTop: "12px",
                         marginLeft:"30px"
                     } }>
                     <DropdownMenu trigger="Meeting">
                         <DropdownItemGroup>
                                 <DropdownItem>
                                     <div>
                                         <Link to="/meeting/conclusion" style={{
                                             textDecoration: "none",
                                             fontSize: "5!important",
                                             color:"#474747"
                                             
                                         }}>
                                             Conclusion
                                         </Link>
                                 </div></DropdownItem>
                             <DropdownItem>Share</DropdownItem>
                            
                         </DropdownItemGroup>
                     </DropdownMenu>
                     </div>*/


                ]}
                renderSettings={() => <ProfileDropdown />}
                renderProductHome={() => AtlassianProductHome(setSidebar, handleclick)}
            />
            
            {
                isOpenCreate &&
                <TaskModel callback={() => setbacklogrefresh((prev: any) => prev + 1)} closeModel={closeModalCreate}
                    createType={CreateType.Task}
                />
            }
        </>
    )
};


export default TopNav;