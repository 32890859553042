import { useState } from "react";

export const AlertConfig = () => {
    const [flags, setFlags] = useState<Array<number>>([]);
    const [message, setMessage] = useState<string>("");
    const [isSuccess, setIsSuccess] = useState<boolean>(true)
    const addFlag = () => {
        const newFlagId = flags.length + 1;
        const newFlags = flags.slice();
        newFlags.splice(0, 0, newFlagId);

        setFlags(newFlags);
    };

    const handleDismiss = () => {
        setFlags(flags.slice(1));
    };

    const SetAlertMessage = (message: string = "") => setMessage(message);

    const IsSuccess = (isSuccess: boolean = true) => setIsSuccess(isSuccess);

    return {
        flags,
        isSuccess,
        message,
        addFlag,
        IsSuccess,
        SetAlertMessage,
        handleDismiss
    }
}