import { useEffect, useState } from "react"
import { ApiUtility } from "../Services/ApiUtility"

export const PostApi = (URL_BASE: string, URLParameaters: any = {}) => {
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState([])
    const [isSuccess, setIsSuccess] = useState(false)

    const reload = async (requestBody: any, callBack: any) => {

        setIsLoading(true)
        let response: any = await ApiUtility.post(URL_BASE + new URLSearchParams({ ...URLParameaters }).toString(), requestBody)
        if (response.result) {
            setData(response.result)
            setIsLoading(false)
            setIsSuccess(true)
            callBack()
        }
        else {
            setError(response)
            setIsLoading(false)
        }
    }

    return {
        data,
        isLoading,
        error,
        reload,
        isSuccess
        }
}