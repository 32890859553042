import Button from "@atlaskit/button"
import Form, { Field } from "@atlaskit/form"
import Select, { OptionType } from '@atlaskit/select';

export interface FilterProps {
    handleSearch: (data: FormData, milestoneid: string) => void,
    selectOptions: Array<OptionType>,
    model: { create: boolean, approve: boolean, detail: boolean },
    setModel: any,
    setMilestoneId: any,
    milestoneid: string,
    setIsExtend: any,
    setIsEdit: any,
    shouldExtend: boolean,
    setMilestoneName: any,
    setIsCreate: any,
    setMessage: any,
    addFlag: any
}

export interface FormData {
    select: OptionType,
    from: string,
    to: string
}

const Filter = ({ handleSearch, selectOptions, model, setModel, milestoneid, setMilestoneId, setIsExtend, setIsCreate, setIsEdit, shouldExtend, setMilestoneName, setMessage, addFlag }: FilterProps) => {

    const promiseOptions = () =>
        new Promise<OptionType[]>((resolve) => {
            setTimeout(() => {
                resolve(selectOptions);
            }, 1000);
        });

    return (
        <div style={{
            display: "flex",
            justifyContent: "space-between",
            fontFamily: "lato",
            backgroundColor: "#f6f8fc",
            padding: "23px"
        }}>

            <div style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between"
            }}>

                <Form onSubmit={(data: FormData) => {
                    setMilestoneId(data.select.value)
                    setMilestoneName(data.select.label)
                    handleSearch(data, data.select.value.toString())
                }} >
                    {({ formProps }) => (
                        <form style={{ display: "flex" }} {...formProps}>
                            <div style={{ display: "flex", alignItems: "center", marginLeft: 35, height: 35 }}>
                                <div style={{ width: 200,fontSize:"14px" }}>
                                    <Field name="select" defaultValue={null} >
                                        {({ fieldProps: { id, ...rest }, error }: any) => (
                                            
                                            <Select
                                                inputId="checkbox-select-example"
                                                className="checkbox-select"
                                                options={selectOptions}
                                                maxMenuHeight={150}
                                                appearance="none"
                                                spacing="compact"
                                                onChange={(selectedItem: OptionType) => {
                                                }}
                                                {...rest}
                                                placeholder="Select Milestone"
                                                loadOptions={promiseOptions}
                                                styles={{
                                                    control: (base: any) => {
                                                        return {
                                                            ...base,
                                                            backgroundColor: "white",
                                                            height: 35,
                                                            fontSize: 14,
                                                            minHeight: 35,
                                                            border: "1px solid #e2e6ef"
                                                        }
                                                    },
                                                    singleValue: (base: any) => ({
                                                        ...base,
                                                        top: "46%"
                                                    }),
                                                    indicatorsContainer: (base: any) => ({
                                                        ...base,
                                                        height: "35px"
                                                    }),
                                                    input: (base: any) => ({
                                                        ...base,
                                                        margin: 0,
                                                        paddingTop: 0
                                                    })
                                                }}
                                            />
                                        )}
                                    </Field>
                                </div>

                                <div style={{ marginLeft: 10, marginRight: 10, marginTop: 8, fontSize: 14 }}>
                                    <Button type="submit" appearance="primary"
                                        style={{ backgroundColor: "#0E51D3" }}
                                    >Search</Button>
                                </div>
                            </div>

                        </form>
                    )}
                </Form>

                <div style={{ marginLeft: 10, marginRight: 10, fontSize: 14 }}>
                    <Button type="submit" appearance="primary"
                        style={{ backgroundColor: "#0E51D3", marginRight: 5 }}
                        onClick={() => {
                            setIsCreate(true)
                            setIsEdit(false)
                            setIsExtend(false)
                            setModel({ ...model, create: true })
                        }}
                    >Create</Button>
                    {
                        shouldExtend &&
                        <Button type="submit" appearance="primary"
                                style={{ backgroundColor: "#0E51D3" }}
                            onClick={() => {
                                setIsEdit(false)
                                setIsExtend(true)
                                setModel({ ...model, create: true })
                            }}
                        >Extend</Button>
                    }
                </div>
            </div>
        </div>
    )
}



export default Filter;