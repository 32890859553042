import React, { ReactNode } from "react";

interface Props {
    onClose: () => void,
    children: ReactNode,
    style?: React.CSSProperties | undefined
}

const modal: React.CSSProperties = {
    position: "fixed",
    zIndex: 10000000000,
    left: 0,
    top: 0,
    alignItems: "center",
    width: "100vw",
    height: "100vh",
    overflow: "auto",
    backgroundColor: "rgba(0, 0, 0, 0.8)"
};

const close: React.CSSProperties = {
    position: "absolute",
    top: 15,
    right: 35,
    color: "#f1f1f1",
    fontSize: 40,
    fontWeight: "bold",
    cursor: "pointer"
};

const modalContent: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    margin: "auto"
};


export const ImagePopup = ({ onClose, children, style = {} }: Props) => {
    return (
        <div style={modal}>
            <span style={close} onClick={onClose}>
                &times;
            </span>
            <div style={{...modalContent, ...style }}>{children}</div>
        </div>
    );
};
