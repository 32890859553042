/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react'
import "react-color-palette/lib/css/styles.css";
import ImageUploading from "react-images-uploading";
import SuccessIcon from '@atlaskit/icon/glyph/check-circle';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import { AutoDismissFlag, FlagGroup } from '@atlaskit/flag';
import TextArea from "@atlaskit/textarea";
import { G300 } from '@atlaskit/theme/colors';
import Textfield from '@atlaskit/textfield';
import AttachmentIcon from '@atlaskit/icon/glyph/attachment'
import { DatePicker } from '@atlaskit/datetime-picker';
import './sidenav.css'
import "./CreateTask.css"
import BooksService from '../../Services/Books'
import Form, {
    ErrorMessage,
    Field,
    FormSection

} from '@atlaskit/form';
import TrashIcon from '@atlaskit/icon/glyph/trash'
import CrossIcon from '@atlaskit/icon/glyph/cross';
import Button from '@atlaskit/button/standard-button';
import Spinner, { Size } from '@atlaskit/spinner';
import { ChromePicker } from "react-color";
import Modal, {
    ModalBody,
    ModalTransition,
} from '@atlaskit/modal-dialog';
import { Fragment } from 'react';
import TextField from '@atlaskit/textfield';
import { N500 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import Select, { CheckboxSelect } from '@atlaskit/select';
import Userrating from '../Userrating/Userrating';
import { useNavigate } from 'react-router-dom';
import AlertModal from '../AlertModal/AlertModal';
import { initialsgenerator, substringmethod, textnumbergenerator } from '../../utils/Util';
import { ImagePopup } from '../UI/ImagePopup';
const myLable = {
    name: "",
    description: "",
    imagePath: null,
    imageThumbPath: null,
}
const CreateTask = ({ isOpen, removeimagestate, closeModal, modelType }: any) => {

    const [title, setTitle] = useState("")
    const [startDate, SetStartDate] = useState("")
    const [eventtype, seteventtype] = useState("")
    const [endDate, setEndDate] = useState("")
    const [text, setText] = useState("")
    const [sprintid, setsprintid] = useState("")
    const [projectidupdate, setprojectidupdate] = useState("")
    const [cardWidth, setcardWidth] = useState(window.innerWidth)
    const [clickstyle, setclickstyle] = useState("")
    const [onchangehandlenew, setonchangehandlenew] = useState<any>(null)
    const [extension, setextension] = useState<string>("")
    const [images, setImages] = React.useState([]);
    const [imagessave, setImagessave] = React.useState<any>([]);
    const [myname, setName] = useState("")
    const [userid, setuserid] = useState("")
    const [projectimage, setprojectimage] = useState<any>()
    const [defaultselect, setdefaultselect] = useState<any>([])
    const [savingload, setsavingload] = useState<any>(false)
    const [dropdownuserdata, setdropdownuserdata] = useState([])
    const [dropdownprojectdata, setdropdownprojectdata] = useState([])
    const [description, setDescription] = useState("")
    const [allias, setallias] = useState("")
    const [isOpenconfirm, setIsOpenconfirm] = useState(false);
    const [attachmentUrl, setAttachmentUrl] = useState("")
    const openModalconfirm = useCallback(() => setIsOpenconfirm(true), []);
    const [isPopup, setIsPopup] = useState(false)
    const handleimagechange = (imageList: any, addUpdateIndex: any) => {
        setImages(imageList);

        let ext = imageList[0].file.name.split(".");
        ext = ext[ext.length - 1]

        setextension(ext)
        setImagessave([...imagessave, imageList[0].data_url])
        console.log(imageList, "imageList")
        setAttachmentUrl(imageList.data_url)
    }


    const [colors, setColors] = useState<any>(null);
    const colorPicker = (e: any) => {
        const newColor: any = {
            hex: e.hex,
            rgb: "(" + e.rgb.r + "," + e.rgb.g + "," + e.rgb.b + "," + e.rgb.a + ")"
        };
        setColors(newColor);
    };  
    const maxNumber = 69;
   
    const onChange = (imageList: any, addUpdateIndex: any) => {
        setImages(imageList);
    };

    const [taskid, settaskid] = useState("")
    const [projectidarr, setprojectidarr] = useState<any>([])
    const [assigneeidarr, setassigneeidarr] = useState<any>([])
    const [removesprint, setremovesprint] = useState(false)
    const [errormsg, seterrmsg] = useState(false)
    const maxNumber1 = 69;
    const [getData, setGetData] = useState<any>([])
    const [getSprintData, setGetSprintData] = useState([])
    const [iserror, setiserror] = useState(false)
    const [updateid, setupdateid] = useState("")
    const [addinput, setAddinput] = useState("")


    let projecterrobj = {
        nameobj: false,
        assigneeIdsobj: false,
        descriptionobj: false,
        aliasobj: false,
    }

    let labelerrobj = {
        nameobj: false
    }

    let sprinterrobj = {
        startDateobj: false,
        endDateobj: false,
        sprintGoalobj: false
    }

    const [projecterrobj2, setprojecterrobj] = useState(projecterrobj)
    const [labelerrobj2, setlabelerrobj] = useState(labelerrobj)
    const [sprinterrobj2, setsprinterrobj] = useState(sprinterrobj)

    const requiredValidator = (
        data: any,
        key: string,
        errors?: any,
    ) => {
        if (!data[key] || data[key].length < 1 || data[key][data[key].length - 1] === " " || data[key][0] === " ") {
            return true
        }
        else {
            return false;
        }

    };

    useEffect(() => {
        setGetSprintData([])
    }, [removesprint])

    const [errmyname, seterrmyname] = useState(false)
    const [errrassigneeidarr, seterrrassigneeidarr] = useState(false)
    const [errdescription, seterrdescription] = useState(false)
    const [errallias, seterrallias] = useState(false)
    const [erraddinput, seterraddinput] = useState(false)
    const [sprintnameupdatetext, setsprintnameupdatetext] = useState("")
    const [errtitle, seterrtitle] = useState(false)
    const [errstartDate, setstartDate] = useState(false)
    const [errendDate, setendDate] = useState(false)
    const [errtext, seterrtext] = useState(false)

    const sprintvalidateOnSubmit = (data: any) => {
        let errors;
        seterrtitle(requiredValidator(data, 'title', errors))
        setstartDate(requiredValidator(data, 'startDate', errors))
        setendDate(requiredValidator(data, 'endDate', errors))
        if (requiredValidator(data, 'title', errors) === false &&
            requiredValidator(data, 'startDate', errors) === false &&
            requiredValidator(data, 'endDate', errors) === false
           
        ) {
            return true;
        }
        else {
            return false
        }

    };

    const projectvalidateOnSubmit = (data: any) => {
        let errors;
        seterrmyname(requiredValidator(data, 'myname', errors))
        seterrrassigneeidarr(requiredValidator(data, 'assigneeidarr', errors))
        seterrallias(requiredValidator(data, 'allias', errors))

        if (requiredValidator(data, 'myname', errors) === false &&
            requiredValidator(data, 'assigneeidarr', errors) === false &&
            requiredValidator(data, 'allias', errors) === false) {
            return true;
        }
        else {
            return false
        }

    };

    const labelvalidateOnSubmit = (data: any) => {
        let errors;
        seterraddinput(requiredValidator(data, 'addinput', errors))

        if (requiredValidator(data, 'addinput', errors) === false) {
            return true;
        }
        else {
            return false
        }

    };

    function capitalizeFirstLetter(str: string) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const isdiscardalert = () => {
        let data = {
            myname,
            assigneeidarr,
            allias,
        }
        if (validateOnCancel(data)) {
            return true
        }
        else {
            return false
        }

    }

    const validateOnCancel = (data: any) => {
        let errors;

        if (requiredValidator(data, 'myname', errors) === true &&
            requiredValidator(data, 'assigneeidarr', errors) === true &&
            requiredValidator(data, 'allias', errors) === true &&
            requiredValidator(data, 'checkablelabelId', errors) === true) {
            return true;
        }
        else {
            return false
        }

    };
    const handleSubmit = async () => {

        if (modelType === "Project") {
            const data = {
                myname,
                assigneeidarr,
                allias,
            }
            if (projectvalidateOnSubmit(data)) {
                if (projectidupdate === "") {
                    setsavingload(true)
                    try {
                        let res = await BooksService.addProject({
                            name: myname,
                            assigneeIds: assigneeidarr,
                            description: description,
                            company: "63a959074e041fdc2ae91ce8",
                            alias: allias.trim(),
                            imageThumbPath: "",
                            statusList: [
                                {
                                    name: "TODO",
                                    indexNo: 1
                                },
                                {
                                    name: "NEED TO DISCUSS",
                                    indexNo: 2
                                },
                                {
                                    name: "IN PROGRESS",
                                    indexNo: 3
                                },
                            ],
                            imagePath: imagessave[0]
                        })

                        if (res.errors) {
                            seterrmsg(true)
                            setsavingload(false)
                            seteventtype(res.errors[0].message)
                            addFlag()
                        }
                        else {
                            setsavingload(false)
                            seteventtype("Project created successfully")
                            addFlag()
                            handleclose()
                        }
                    } catch (err) {

                    }
                }
                else {
                    setsavingload(true)
                    try {
                        let res = await BooksService.addProject({
                            id: projectidupdate,
                            name: myname,
                            statusList: [
                                {
                                    name: "TODO",
                                    indexNo: 1
                                },
                                {
                                    name: "NEED TO DISCUSS",
                                    indexNo: 2
                                },
                                {
                                    name: "IN PROGRESS",
                                    indexNo: 3
                                },
                            ],
                            ext: extension,
                            assigneeIds: assigneeidarr,
                            company: "63a959074e041fdc2ae91ce8",
                            alias: allias.trim(),
                            description: description,
                            imageThumbPath: "",
                            imagePath: imagessave[0] ? imagessave[0] : projectimage === "?t=" ? "" : projectimage
                        })

                        seteventtype("Project updated successfully")

                        addFlag()

                        handleclose()

                    } catch (err) {

                    }

                }
            }
        }
        if (modelType === "Lable") {
            const data = {
                addinput
            }

            if (labelvalidateOnSubmit(data)) {

                setsavingload(true)

                updateid ?

                    await BooksService.addLabel({ color: typeof colors === "string" ? colors : colors.hex, name: capitalizeFirstLetter(addinput).trim(), company: "63a959074e041fdc2ae91ce8", id: updateid }).then((res: any) => {
                        setAddinput("")
                        handleclose()
                        seteventtype("Label updated successfully")
                        addFlag()
                        setsavingload(false)
                    })
                    :
                    await BooksService.addLabel({ color: colors === null ? "#000000" : colors.hex, name: capitalizeFirstLetter(addinput).trim() }).then((res) => {
                        setAddinput("")
                        handleclose()
                        seteventtype("Label created successfully")
                        addFlag()
                        setsavingload(false)
                    })
                setupdateid("")

            }
        }

        else if (modelType === "Sprint") {

            let data = {
                title,
                startDate,
                endDate,
                text
            }

            if (sprintvalidateOnSubmit(data)) {
                if (sprintid === "" || sprintid === null) {
                    setsavingload(true)
                    try {
                        let res = await BooksService.addSprint({ name: title, projectId: taskid, startDate: startDate, endDate: endDate, sprintGoal: text })
                        handleclose()
                        setTitle("")
                        settaskid("")
                        SetStartDate("")
                        setEndDate("")
                        setText("")
                        seteventtype("Sprint created successfully")
                        addFlag()
                        setsavingload(false)
                        window.location.reload();

                    } catch (err) {

                    }
                }

                else {
                    setsavingload(true)
                    try {
                        let res = await BooksService.addSprint({ id: sprintid, name: title, projectId: taskid, startDate: startDate, endDate: endDate, sprintGoal: text })
                        handleclose()
                        setTitle("")
                        settaskid("")
                        SetStartDate("")
                        setEndDate("")
                        setText("")
                        seteventtype("Sprint updated successfully")
                        addFlag()
                        setsavingload(false)
                        window.location.reload();

                    } catch (err) {

                    }

                }
            }
        }

        else if (modelType === "updateproject") {
            BooksService.updateProject({ projectIds: projectidarr, uid: userid })
        }
        getProject()
    }
    //get function of project and lable
    const getProject = async () => {

        setGetData([])

        if (modelType === "Project") {

            try {
                let res = await BooksService.getProjectByUser(JSON.parse(localStorage.getItem("logindetail") || "{}").id)
                setGetData(res.result)
            } catch (err) {

            }
            try {
                let res = await BooksService.getUser(1500).then((value: any) => {
                    let userdatatype: any = []

                    value.result.map((item: any) => {
                        userdatatype.push({ label: item.name, value: item.id })
                    })

                    setdropdownuserdata(userdatatype)
                })
            } catch (err) {

            }

        }
        else if (modelType === "Lable") {
            try {
                let res = await BooksService.getLabel(100)

                setGetData(res.result)

            } catch (err) {

            }

        }
        else if (modelType === "Sprint") {

            if (taskid != "") {
                try {
                    let res = await BooksService.getSprint(taskid)
                    setGetSprintData(res.result)

                } catch (err) {

                }

            }

            try {
                let res = await BooksService.getProjectByUser(JSON.parse(localStorage.getItem("logindetail") || "{}").id)
                let formatarr: any = []
                res.result.map((item: any) => {
                    formatarr.push({ label: item.name, value: item.id })

                    setGetData(formatarr)
                })
            } catch (err) {

            }

        }

        else if (modelType === "updateproject") {

            BooksService.getUser(1500).then((value: any) => {
                let userdatatype: any = []
                value.result.map((item: any) => {
                    userdatatype.push({ label: item.name, value: item.id })
                })
                setdropdownuserdata(userdatatype)
            })

            BooksService.getProjectByUser(JSON.parse(localStorage.getItem("logindetail") || "{}").id).then((value: any) => {
                let userdatatype: any = []
                value.result.map((item: any) => {
                    userdatatype.push({ label: item.name, value: item.id })
                })
                setdropdownprojectdata(userdatatype)
            })

        }
    }

    useEffect(() => {

        getProject()
    }, [isOpen, taskid])
    //using for maping data in sidenav
    const handledata = (data: any) => {
        if (modelType === "Project") {
            setName(data.name)
            setDescription(data.description)
            setprojectidupdate(data.id)
            setallias(data.alias)
            setprojectimage(data.imagePath)
            setassigneeidarr([])

            let PROJECT_USER: any = []
            let assigneearr: any = []
            data.assignee.map((value: any) => {
                PROJECT_USER.push({ value: value.id, label: value.name })
                assigneearr.push(value.id)
            })
            setassigneeidarr(assigneearr)

            setdefaultselect(PROJECT_USER)

        }

        else if (modelType === "Sprint") {
            setTitle(data.name)
            SetStartDate(data.startDate)
            setEndDate(data.endDate)
            setText(data.sprintGoal)
            /*settaskid(data.id)*/
            setsprintid(data.id)
        }

        else {
            setupdateid(data.id)
            setAddinput(data.name)
            setColors(data.color)
        }
        if (data.description === null) {
            setDescription("")
        }
        else if (data.name === null) {
            setName("")
        }
    }


    const handlenewproject = () => {

        setclickstyle("")
        setdefaultselect([])
        if (modelType === "Project") {
            setName("")
            setDescription("")
            setprojectidupdate("")
            setallias("")
            setassigneeidarr([])
            setdefaultselect([])
            setprojectidupdate("")
            seterrmyname(false)
            seterrrassigneeidarr(false)
            seterrdescription(false)
            seterrallias(false)
            setprojectimage(null)
        }

        else if (modelType === "Sprint") {

            setTitle(sprintnameupdatetext)

            SetStartDate("")
            setEndDate("")
            setText("")
            /*settaskid(data.id)*/
            setsprintid("")


            seterrtitle(false)
            setstartDate(false)
            setendDate(false)
            seterrtext(false)

        }

        else {
            setupdateid("")
            setAddinput("")
            setColors("")
            seterraddinput(false)
        }
    }
    const [flags, setFlags] = useState<Array<number>>([]);
    const addFlag = () => {
        const newFlagId = flags.length + 1;
        const newFlags = flags.slice();
        newFlags.splice(0, 0, newFlagId);

        setFlags(newFlags);
    };

    const handleDismiss = () => {
        setFlags(flags.slice(1));
    };

    const handleclose = () => {
        /* setremoveimagestate(true)*/
        seterrmyname(false)
        seterrrassigneeidarr(false)
        seterrdescription(false)
        seterrallias(false)
        seterraddinput(false)
        setsavingload(false)
        handlenewproject()
        setremovesprint(false)
        closeModal()
    }

    return (
        <>

            <ModalTransition>
                <FlagGroup onDismissed={handleDismiss}>
                    {flags.map((flagId) => {
                        return (
                            <AutoDismissFlag
                                id={flagId}
                                icon={

                                    errormsg ?
                                        <ErrorIcon
                                            primaryColor={token('color.icon.success', G300)}
                                            label="Error"
                                        />
                                        :
                                        <SuccessIcon
                                            primaryColor={token('color.icon.success', G300)}
                                            label="Success"
                                        />
                                }
                                key={flagId}
                                title={`${eventtype}`}

                            />
                        );
                    })}
                </FlagGroup>
                {isOpen && (

                    <Modal
                        width="large"
                    >
                        <ModalBody>
                            <FlagGroup onDismissed={handleDismiss}>
                                {flags.map((flagId) => {
                                    return (
                                        <AutoDismissFlag
                                            id={flagId}
                                            icon={
                                                <SuccessIcon label="Success" size="medium" />
                                            }
                                            key={flagId}
                                            title={`${eventtype}`}

                                        />
                                    );
                                })}
                            </FlagGroup>
                            <div style={{
                            }}>

                                <div style={{ display: "flex", fontFamily: "lato" }}>

                                    {(modelType === "Project" || modelType === "Sprint" || modelType === "Lable") &&

                                        (<div style={{
                                            fontWeight: "600",
                                            width: `${cardWidth > 1000 ? 0 : 200}`,
                                            display: `${cardWidth > 700 ? "contents" : "inherit"}`
                                            // maxHeight: "500px"
                                        }}>

                                            <div style={{
                                                justifyContent: "start",
                                                backgroundColor: "#f3f7ff",

                                            }}>

                                                {
                                                    modelType === "Sprint" ?
                                                        (<div style={{
                                                            overflowY: "scroll",
                                                            height: 380,
                                                            paddingTop: 30,
                                                            width: `${cardWidth < 650 ? "160px" : "217px"}`,
                                                            fontWeight: 400,
                                                            display: "flex",
                                                            flexDirection: "column"
                                                        }}>
                                                            <div style={{
                                                                marginLeft: `${cardWidth < 650 ? "0px" : "30px"}`,
                                                                marginBottom: 20,
                                                                paddingLeft: `${cardWidth < 650 ? "34px" : "0px"}`
                                                            }}>

                                                                <Select
                                                                    inputId="checkbox-select-example"
                                                                    className="checkbox-select"
                                                                    options={[...getData]}

                                                                    placeholder="Select Project"
                                                                    onChange={(item: any) => {
                                                                        seterrtitle(false)
                                                                        settaskid(item.value)
                                                                        setTitle(item.label)
                                                                        setsprintnameupdatetext(item.label)
                                                                    }}
                                                                />

                                                            </div>

                                                            {
                                                                getSprintData ?

                                                                    getSprintData.map((item: any, index: any) => (

                                                                        <Button appearance="subtle" key={index} style={{ textAlign: "inherit", backgroundColor: `${clickstyle === index ? "#e9f0fe" : ""}` }} onClick={() => {
                                                                            setclickstyle(index)
                                                                            handledata(item)
                                                                            setTitle(item.name)

                                                                        }
                                                                        }>{item.name}</Button>
                                                                    ))

                                                                    :

                                                                    <div style={{ display: "flex", justifyContent: "center", marginTop: "30%" }}>
                                                                        <Spinner size="medium" />
                                                                    </div>

                                                            }
                                                        </div>) :
                                                        (
                                                            <>
                                                                <p style={{ color: "#bec2cd", fontSize: "14px", fontWeight: 500, paddingLeft: `${cardWidth < 450 ? "8px" : "30px"}`, marginTop: 15, paddingTop: "5px" }}>SELECT {modelType.toUpperCase()}</p>
                                                                <div style={{
                                                                    overflowY: "scroll",
                                                                    height: 380,
                                                                    width: `${cardWidth < 650 ? "120px" : "180px"}`,
                                                                    fontWeight: 400,
                                                                    paddingLeft: 14,
                                                                    display: "flex",
                                                                    flexDirection: "column"
                                                                }}>

                                                                    {
                                                                        getData ?
                                                                            getData.map((item: any, index: any) => (
                                                                                <div style={{ borderTop: "1px solid white", cursor: "pointer", backgroundColor: `${clickstyle === index ? "#e9f0fe" : ""}` }} onClick={() => {
                                                                                    setclickstyle(index)
                                                                                    handledata(item)
                                                                                }}>
                                                                                    <Button appearance="subtle" key={index} style={{ textAlign: "inherit", width: "100%", fontWeight: `${clickstyle === index ? "600" : "500"}` }}>{item.name}</Button>
                                                                                </div>
                                                                            ))
                                                                            :
                                                                            <div style={{ display: "flex", justifyContent: "center", marginTop: "30%" }}>
                                                                                <Spinner size="medium" />
                                                                            </div>
                                                                    }

                                                                </div>
                                                            </>
                                                        )
                                                }
                                                <div style={{ display: "flex", justifyContent: "center", marginTop: 40, padding: "10px" }}>
                                                    <Button style={{ backgroundColor: "#3177FF", color: "white", paddingLeft: 10, lineHeight: 2, height: 30, paddingRight: 10 }} onClick={handlenewproject} appearance="subtle"><p style={{ margin: 0, color: "white", fontSize: 14, fontWeight: 500 }}>Add New {modelType}</p></Button>
                                                </div>
                                            </div>

                                        </div>)}

                                    <ModalBody>
                                        <FormSection>
                                            <div style={{
                                                display: "flex",
                                                borderBottom: "1px solid #e8ecf3",
                                                paddingLeft: 10,
                                                fontSize: 14,
                                                paddingBottom: 10,
                                                paddingTop: 5,
                                                paddingRight: 10,
                                                justifyContent: "space-between"
                                            }}>
                                                {modelType === "Project" &&
                                                    <p style={{ margin: 0, fontSize: 20 }}>Project</p>

                                                }
                                                {
                                                    modelType === "Lable" &&
                                                    <p style={{ margin: 0, fontSize: 20 }}>Issue</p>
                                                }

                                                {
                                                    modelType === "Sprint" &&
                                                    <p style={{ margin: 0, fontSize: 20 }}>Sprint</p>
                                                }

                                                {
                                                    modelType === "updateproject" &&
                                                    <p style={{ margin: 0, fontSize: 20 }}>Update Project</p>
                                                }

                                                {
                                                    modelType === "Rating" &&
                                                    <p style={{ margin: 0, fontSize: 20 }}>User Rating</p>
                                                }
                                                <div style={{ marginRight: `${cardWidth < 600 ? "60px" : "-6px"}` }}>
                                                    <Button appearance="link" onClick={() => {

                                                        handleclose()

                                                    }}>
                                                        <CrossIcon
                                                            label="Close Modal"
                                                            primaryColor={token('color.text.subtle', N500)}
                                                        />
                                                    </Button>
                                                </div>
                                            </div>
                                        </FormSection>

                                        <Form<{ username: string; password: string; remember: boolean }>
                                            onSubmit={(data) => {
                                                return new Promise((resolve) => setTimeout(resolve, 2000)).then(() =>
                                                    data.username === 'error' ? { username: 'IN_USE' } : undefined,
                                                );
                                            }}
                                        >

                                            {({ formProps, submitting }) => (
                                                <form {...formProps} style={{ marginTop: 40, paddingRight: `${cardWidth < 450 ? "0px" : "15px"}`, paddingLeft: `${cardWidth < 450 ? "15px" : "15px"}`, width: `${cardWidth < 400 ? "200px" : cardWidth > 400 && cardWidth < 650 ? "255px" : "null"}` }}>

                                                    <FormSection>
                                                        <div style={{
                                                            marginRight: 10
                                                        }}>
                                                            {
                                                                savingload &&
                                                                <div className="loading">
                                                                    <Spinner size="medium" />
                                                                </div>
                                                            }
                                                            {
                                                                modelType === "Project" &&
                                                                (
                                                                    <div style={{ height: "380px", overflowX: "scroll" }}>

                                                                        <div style={{ display: `${cardWidth < 650 ? "block" : "flex"}`, justifyContent: "space-between" }}>
                                                                            <div style={{
                                                                                width: `${cardWidth > 450 ? "300px" : ""}`
                                                                            }}>
                                                                                <label htmlFor="checkbox-select-example" style={{
                                                                                    fontSize: 14,
                                                                                    marginTop: 10,
                                                                                    display: 'flex',
                                                                                }}><p style={{ margin: 0 }}>Project Name</p><p style={{ color: "red", paddingLeft: 2, margin: 0 }}>*</p></label>
                                                                                <Field
                                                                                    aria-required={true}
                                                                                    name="name"

                                                                                    isRequired
                                                                                >
                                                                                    {({ fieldProps, error }) => (
                                                                                        <Fragment>
                                                                                            <TextField style={{
                                                                                                backgroundColor: "#f6f8fc"
                                                                                            }}  {...fieldProps}
                                                                                                value={myname}
                                                                                                onChange={(e: any) => {
                                                                                                    seterrmyname(false)
                                                                                                    if (projecterrobj2.nameobj === true) {
                                                                                                        setprojecterrobj({ ...projecterrobj2, nameobj: false })
                                                                                                    }
                                                                                                    setName(e.target.value)
                                                                                                }

                                                                                                } />
                                                                                        </Fragment>
                                                                                    )}

                                                                                </Field>
                                                                                {
                                                                                    errmyname && (
                                                                                        <ErrorMessage>
                                                                                            Please enter project name
                                                                                        </ErrorMessage>
                                                                                    )
                                                                                }
                                                                            </div>

                                                                            <div style={{
                                                                                width: `${cardWidth < 450 ? "100%" : "25%"}`
                                                                            }}>
                                                                                <label htmlFor="checkbox-select-example" style={{
                                                                                    fontSize: 14,
                                                                                    marginTop: 10,
                                                                                    display: 'flex',
                                                                                }}><p style={{ margin: 0 }}>Allias</p><p style={{ color: "red", paddingLeft: 2, margin: 0 }}>*</p></label>

                                                                                <Field
                                                                                    aria-required={true}

                                                                                    name="allias"
                                                                                    isRequired
                                                                                >
                                                                                    {({ fieldProps, error, valid, meta }) => {
                                                                                        return (
                                                                                            <Fragment>
                                                                                                <TextField style={{
                                                                                                    backgroundColor: "#f6f8fc"
                                                                                                }}
                                                                                                    type="off" {...fieldProps}
                                                                                                    value={allias}
                                                                                                    maxLength={3} onChange={(e: any) => {
                                                                                                        setonchangehandlenew("new")
                                                                                                        if (projecterrobj2.aliasobj === true) {
                                                                                                            setprojecterrobj({ ...projecterrobj2, aliasobj: false })
                                                                                                        }
                                                                                                        setallias(e.target.value)
                                                                                                    }

                                                                                                    } />
                                                                                            </Fragment>
                                                                                        );
                                                                                    }}
                                                                                </Field>
                                                                                {
                                                                                    errallias && (
                                                                                        <ErrorMessage>
                                                                                            Please enter allias
                                                                                        </ErrorMessage>
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div >

                                                                            <div className="TaskType" style={{ marginTop: 12, width: 511, marginLeft: `${cardWidth < 650 ? "0px" : "24px"}` }}>
                                                                                {/* <p style={{}}>Assignee*</p> */}

                                                                                <div className="select2" style={{
                                                                                    // width: '320px',

                                                                                }}>
                                                                                    <label htmlFor="checkbox-select-example" style={{
                                                                                        fontSize: 14,
                                                                                        display: 'flex',
                                                                                        marginLeft: `${cardWidth < 450 ? "0px" : "-24px"}`,
                                                                                        marginBottom: 6
                                                                                    }}><p style={{ margin: 0 }}>Assignee</p><p style={{ color: "red", paddingLeft: 2, margin: 0 }}>*</p></label>
                                                                                    <div style={{ paddingLeft: `${cardWidth < 450 ? "22px" : "0px"}`, width: `${cardWidth < 450 ? "221px" : "none"}` }}>
                                                                                        <CheckboxSelect
                                                                                            inputId="checkbox-select-example"
                                                                                            className="checkbox-select"
                                                                                            aria-label="Name"
                                                                                            isRequired
                                                                                            spacing="compact"
                                                                                            menuPlacement={defaultselect.length < 7 ? "bottom" : "top"}

                                                                                            maxMenuHeight={120}
                                                                                            value={[...defaultselect]}
                                                                                            options={[...dropdownuserdata]}
                                                                                            placeholder="Select Assignee"
                                                                                            onChange={(value) => {
                                                                                                seterrrassigneeidarr(false)
                                                                                                if (projecterrobj2.assigneeIdsobj === true) {
                                                                                                    setprojecterrobj({ ...projecterrobj2, assigneeIdsobj: false })
                                                                                                }

                                                                                                setdefaultselect(value)
                                                                                                let arr = value.map((item: any) => {
                                                                                                    return item.value
                                                                                                })

                                                                                                setassigneeidarr(arr)
                                                                                            }}
                                                                                            styles={{
                                                                                                control: (base) => {

                                                                                                    return {
                                                                                                        ...base,
                                                                                                        backgroundColor: "#f6f8fc",
                                                                                                        fontSize: 14,
                                                                                                        border: "none",

                                                                                                    }

                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    errrassigneeidarr && (
                                                                                        <ErrorMessage>
                                                                                            Please select assignee
                                                                                        </ErrorMessage>
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        </div>

                                                                        {/*---------------------------------------------------------------------------------------*/}

                                                                        <label htmlFor="checkbox-select-example" style={{
                                                                            fontSize: 14,
                                                                            marginTop: 10,
                                                                            display: 'flex',

                                                                        }}><p style={{ margin: 0 }}>Description</p></label>
                                                                        <Field
                                                                            aria-required={true}
                                                                            name="description"

                                                                            isRequired
                                                                        >
                                                                            {({ fieldProps, error, valid, meta }) => {
                                                                                return (
                                                                                    <Fragment>

                                                                                        <TextArea

                                                                                            appearance="standard"
                                                                                            style={{
                                                                                                backgroundColor: "#f6f8fc",
                                                                                                borderColor: "#ffffff"
                                                                                            }}
                                                                                            name="area"
                                                                                            value={description}
                                                                                            autoFocus
                                                                                            isRequired
                                                                                            onChange={(e: any) => {
                                                                                                seterrdescription(false)
                                                                                                if (projecterrobj2.descriptionobj === true) {
                                                                                                    setprojecterrobj({ ...projecterrobj2, descriptionobj: false })
                                                                                                }

                                                                                                setDescription(e.target.value)
                                                                                            }
                                                                                            }
                                                                                            testId="smartResizeTextArea"
                                                                                        />
                                                                                    </Fragment>
                                                                                );
                                                                            }}
                                                                        </Field>
                                                                        {
                                                                            errdescription && (
                                                                                <ErrorMessage>
                                                                                    Please enter description
                                                                                </ErrorMessage>
                                                                            )
                                                                        }
                                                                        <ImageUploading
                                                                            multiple
                                                                            value={images}
                                                                            onChange={handleimagechange}
                                                                            maxNumber={maxNumber}
                                                                            allowNonImageType={true}
                                                                            dataURLKey="data_url"
                                                                        >
                                                                            {({
                                                                                imageList,
                                                                                onImageUpload,
                                                                                onImageRemoveAll,
                                                                                onImageUpdate,
                                                                                onImageRemove,
                                                                                isDragging,
                                                                                dragProps
                                                                            }) => (
                                                                                // write your building UI
                                                                                <div className="upload__image-wrapper">

                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        alignItems: "baseline",
                                                                                        paddingTop: "10px"
                                                                                    }}>
                                                                                        <div style={{ paddingRight: "20px" }}>
                                                                                            <p style={{ color: "#9a99af", fontSize: "13px" }}>Attachment</p>
                                                                                        </div>
                                                                                        <div onClick={onImageUpload} style={{
                                                                                            display: "flex",
                                                                                            alignItems: "baseline",
                                                                                            cursor: "pointer"
                                                                                        }}>
                                                                                            <p><AttachmentIcon size="medium" primaryColor="#758296" label="persn" /></p>
                                                                                            <p style={{
                                                                                                color: "#758296",
                                                                                                fontSize: "13px"
                                                                                            }}>Attach</p>
                                                                                        </div>
                                                                                    </div>

                                                                                    &nbsp;
                                                                                    {
                                                                                        imageList.map((image, index) => {

                                                                                            removeimagestate && (
                                                                                                onImageRemove(index)
                                                                                            )

                                                                                            return (
                                                                                                <div key={index} style={{ display: "flex"  }} className="image-item">
                                                                                                    {
                                                                                                        image.file != undefined &&

                                                                                                        <div style={{ textAlign: "center", border: "1px solid #F2F6FE", padding: "10px", borderRadius: "10px" }}>
                                                                                                                <img onClick={() => {
                                                                                                                    setIsPopup(true)
                                                                                                                }
                                                                                                                } style={{ height: "60px", width: "60px" }} src={image.data_url} />
                                                                                                                <p style={{ margin: 0, fontSize: 14, fontFamily: "lato" }}>{substringmethod(image.file.name)}</p>
                                                                                                            <span style={{ paddingLeft: "20px" }} onClick={() => onImageRemove(index)}><TrashIcon primaryColor='#b9bfc9' label='person' /></span>
                                                                                                        </div>

                                                                                                    }
                                      
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                        )}

                                                                                </div>
                                                                            )}
                                                                        </ImageUploading>

                                                                    </div>
                                                                )
                                                            }
                                                            {
                                                                modelType === "Lable" &&
                                                                (
                                                                    <>
                                                                        <label htmlFor="checkbox-select-example" style={{
                                                                            fontSize: 14,
                                                                            marginTop: 10,
                                                                            display: 'flex',
                                                                        }}><p style={{ margin: 0 }}>Label Name</p><p style={{ color: "red", paddingLeft: 2, margin: 0 }}>*</p></label>

                                                                        <Fragment>
                                                                            <TextField
                                                                                name="name"
                                                                                value={addinput}
                                                                                style={{
                                                                                    backgroundColor: "#f6f8fc"
                                                                                }} onChange={(e: any) => {
                                                                                    seterraddinput(false)
                                                                                    if (labelerrobj2.nameobj === true) {
                                                                                        setlabelerrobj({ ...labelerrobj2, nameobj: false })
                                                                                    }
                                                                                    setAddinput(e.target.value)
                                                                                }
                                                                                }
                                                                            />
                                                                        </Fragment>

                                                                        {
                                                                            erraddinput && (
                                                                                <ErrorMessage>
                                                                                    Please enter label name
                                                                                </ErrorMessage>
                                                                            )
                                                                        }

                                                                        <div style={{ width: "80%" }}>
                                                                            <label htmlFor="checkbox-select-example" style={{
                                                                                fontSize: 14,
                                                                                marginTop: 10,
                                                                                marginBottom: 5,
                                                                                display: 'flex',
                                                                            }}><p style={{ margin: 0 }}>Select Color</p><p style={{ color: "red", paddingLeft: 2, margin: 0 }}>*</p></label>
                                                                            <ChromePicker
                                                                                color={colors !== null && colors}
                                                                                onChange={(e: any) => {

                                                                                    colorPicker(e)
                                                                                }
                                                                                }
                                                                                disableAlpha
                                                                            />
                                                                        </div>

                                                                    </>
                                                                )
                                                            }
                                                            {
                                                                modelType === "Sprint" &&
                                                                (
                                                                    <div style={{ marginTop: 30, height: 340 }}>

                                                                        <label htmlFor="checkbox-select-example" style={{
                                                                            fontSize: 14,
                                                                            marginTop: 10,
                                                                            marginBottom: 5,
                                                                            display: 'flex',
                                                                        }}><p style={{ margin: 0 }}>Sprint Name</p></label>
                                                                        <Textfield
                                                                            style={{
                                                                                backgroundColor: "#f6f8fc"
                                                                            }}
                                                                            value={title}
                                                                            isDisabled
                                                                            placeholder="Sprint Name"
                                                                        />
                                                                        {
                                                                            errtitle && (
                                                                                <ErrorMessage>
                                                                                    Please enter sprint name
                                                                                </ErrorMessage>
                                                                            )
                                                                        }

                                                                        <div style={{ display: `${cardWidth < 650 ? "block" : "flex"}`, justifyContent: "space-between" }}>
                                                                            <div >
                                                                                <label htmlFor="checkbox-select-example" style={{
                                                                                    fontSize: 14,
                                                                                    marginTop: 18,
                                                                                    marginBottom: 5,
                                                                                    display: 'flex',
                                                                                }}><p style={{ margin: 0 }}>Start Date</p><p style={{ color: "red", paddingLeft: 2, margin: 0 }}>*</p></label>
                                                                                <div style={{ width: "150px", fontSize: "14px" }}><DatePicker dateFormat="DD/MM/YYYY"
                                                                                    placeholder="DD/MM/YYYY" value={startDate} onChange={(e: any) => {
                                                                                        setstartDate(false)
                                                                                        SetStartDate(e)
                                                                                    }
                                                                                    } /></div>
                                                                                {
                                                                                    errstartDate && (
                                                                                        <ErrorMessage>
                                                                                            Please select start date
                                                                                        </ErrorMessage>
                                                                                    )
                                                                                }
                                                                            </div>

                                                                            <div>
                                                                                <label htmlFor="checkbox-select-example" style={{
                                                                                    fontSize: 14,
                                                                                    marginTop: 18,
                                                                                    marginBottom: 5,
                                                                                    display: 'flex',
                                                                                }}><p style={{ margin: 0 }}>End Date</p><p style={{ color: "red", paddingLeft: 2, margin: 0 }}>*</p></label>
                                                                                <div style={{ width: "150px", fontSize: "14px" }}><DatePicker dateFormat="DD/MM/YYYY"
                                                                                    placeholder="DD/MM/YYYY" value={endDate} onChange={(e: any) => {
                                                                                        setendDate(false)
                                                                                        setEndDate(e)
                                                                                    }
                                                                                    } /></div>
                                                                                {
                                                                                    errendDate && (
                                                                                        <ErrorMessage>
                                                                                            Please select end date
                                                                                        </ErrorMessage>
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        </div>

                                                                        <div>
                                                                            <label htmlFor="checkbox-select-example" style={{
                                                                                fontSize: 14,
                                                                                marginTop: 18,
                                                                                marginBottom: 5,
                                                                                display: 'flex',
                                                                            }}><p style={{ margin: 0 }}>Sprint Goal</p></label>
                                                                            <Textfield
                                                                                style={{
                                                                                    backgroundColor: "#f6f8fc"
                                                                                }}
                                                                                value={text}
                                                                                label="Subtle"
                                                                                onChange={(e: any) => {
                                                                                    if (sprinterrobj2.sprintGoalobj === true) {
                                                                                        setsprinterrobj({ ...sprinterrobj2, sprintGoalobj: false })
                                                                                    }
                                                                                    setText(e.target.value)
                                                                                }
                                                                                }
                                                                                placeholder="Sprint Goal"
                                                                            />

                                                                        </div>
                                                                    </div>
                                                                )
                                                            }

                                                            {
                                                                modelType === "Rating" &&
                                                                (
                                                                    <Userrating />
                                                                )
                                                            }

                                                            <div style={{ display: "flex", marginBottom: 20, marginTop: 15, justifyContent: `${cardWidth < 600 ? "left" : "right"}` }}>
                                                                <Button style={{ marginRight: 10, height: "30px", lineHeight: 2 }} appearance="subtle" onClick={() =>
                                                                {
                                                                    if (isdiscardalert() ) {


                                                                        handleclose()
                                                                    }

                                                                    else {
                                                                        openModalconfirm()
                                                                    }

                                                                }}>
                                                                    Cancel
                                                                </Button>
                                                                <Button style={{ height: "30px", lineHeight: 2, backgroundColor: "#3177FF" }} appearance="primary" onClick={handleSubmit}>
                                                                    Submit
                                                                </Button>
                                                            </div>
                                                        </div>

                                                    </FormSection>

                                                </form>
                                            )}
                                        </Form>

                                    </ModalBody>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                )}
            </ModalTransition>
            {
                isPopup &&
                <ImagePopup onClose={() => setIsPopup(false)}>
                        <img onClick={() => setIsPopup(true)} style={{ height: "87%", width: "100%" }} src={imagessave} />
                </ImagePopup>
            }
            <AlertModal isOpenconfirm={isOpenconfirm} openModalconfirm={openModalconfirm}  handleclose={handleclose} type={"createtask"} />
        </>
    )
}
export default CreateTask