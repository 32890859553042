import DropdownMenu, { DropdownItemGroup } from '@atlaskit/dropdown-menu';
import './Avatar.css'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import Changepassword from "../DetaliModels/Changepassword";
import UnlockFilledIcon from '@atlaskit/icon/glyph/unlock-filled'
import Avatar from "@atlaskit/avatar";
import { AsyncAvatarPickerDialogProps, AvatarPickerDialog } from '@atlaskit/media-avatar-picker';
import { initialsgenerator, textnumbergenerator } from "../../utils/Util";
import { ScreenRecorderInit } from '../ScreenRecorder/ScreenRecorder';
import BooksService from '../../Services/Apicall';
import { AlertType, useAlert } from '../../Contexts/AlertContext';
const ProfileDropdown = () => {
    return (
        <>
            <UserAvatar />
        </>
    );
};

const AvatarPicker = AvatarPickerDialog as unknown as (props : AsyncAvatarPickerDialogProps) => React.ReactElement

const UserAvatar = () => {
    const {showAlert}: any = useAlert()
    const [isOpen, setIsOpen] = useState(false);
    const initialToggleState = localStorage.getItem('roleType') === 'true';
    const [isOpenprofile, setIsOpenprofile] = useState(initialToggleState);
    const [loading, setloading] = useState(false)
    const [roleType, setRoleType] = useState(false)
    const openModalprofile = () => setIsOpenprofile(true)
    const closeModalprofile = () => setIsOpenprofile(false)  
    const openModal = () => setIsOpen(true)
    const closeModal = () => setIsOpen(false)
    const location = useLocation();
    const navigate = useNavigate()
    const [highlightedButton, setHighlightedButton] = useState(null);
    const [pageroute, setPageRoute] = useState("admin")
    const firstRender = useRef(true);
    // Effect to persist highlighted button state in local storage
    useEffect(() => {
        const storedButton = localStorage.getItem('highlightedButton');
        if (storedButton) {
            setHighlightedButton(storedButton);
        }
    }, []);

    // Function to handle button click
    const handleButtonClick = (button) => {
        // Update highlighted button state
        setHighlightedButton(button);
        // Store highlighted button state in local storage
        localStorage.setItem('highlightedButton', button);
    };

    const handlesubmit = async (image) => {

        let data = {
            userId: JSON.parse(localStorage.getItem("logindetail") || "{}").id,
            name: JSON.parse(localStorage.getItem("logindetail") || "{}").name,
            email: JSON.parse(localStorage.getItem("logindetail") || "{}").email,
            phoneNumber: "",
            imagePath: image,
            imageThumbPath: ""
        }
        setloading(true)
        try {
            let res = await BooksService.addUserProfile(data)
            setloading(false)
            if (res.result) {
                showAlert("Profile picture updated successfully", AlertType.SUCCESS)
                localStorage.setItem("logindetail", JSON.stringify({
                    ...JSON.parse(localStorage.getItem("logindetail") || "{}"),
                    imageThumb: res.result.imageThumb
                }));
                window.location.reload()
            }
            else if (res.errors) {
                showAlert("Error occure", AlertType.ERROR)
            }

        } catch (err) {

        }

    }

    const [showComponentA, setShowComponentA] = useState(true);
    const handleToggle = () => {
        setShowComponentA(!showComponentA);
        if (showComponentA) {
            navigate('/admin/dashboard');
        } else {
            navigate('/dashboard');
        }
       /* if (roleType1) {

            navigate("/admin/dashboard")
        } else {
            navigate("/dashboard")
        }*/

        /* setRoleType(roleType1);*/
        


    };

    const currentPath = location.pathname;
    useEffect(() => {
        localStorage.setItem('roleType', roleType.toString());
    }, [roleType]);


    console.log(roleType,"currentPath")
    return (
        <>

            <DropdownMenu
                trigger={({ triggerRef, ...props }) => (
                    <div style={{ textAlign: "center", display: "flex", padding: 10 }}>
                        <div
                            style={{
                                marginTop: 8
                            }}>
                            <Avatar
                                {...props}
                                ref={triggerRef}
                                src={


                                    JSON.parse(localStorage.getItem("logindetail") ||
                                        "{ }").imageThumb === null || JSON.parse(localStorage.getItem("logindetail") ||
                                            "{ }").imageThumb === "" || JSON.parse(localStorage.getItem("logindetail") ||
                                                "{ }").imageThumb === undefined ?
                                        `https://i1.wp.com/avatar-management--avatars.us-west-2.prod.public.atl-paas.net/initials/${initialsgenerator(JSON.parse(localStorage.getItem("logindetail") || "{}").name)}-${textnumbergenerator(JSON.parse(localStorage.getItem("logindetail") || "{}").name)}.png?ssl=1` :
                                        JSON.parse(localStorage.getItem("logindetail") || "{ }").imageThumb


                                }
                                name="Demo User"
                            />
                        </div>
                    </div>
                )}
            >
                <div className="userAvatar" >
                    <DropdownItemGroup >
                        <div onClick={openModalprofile} className="avatar">
                            <Avatar
                                size="medium"
                                src={
                                    JSON.parse(localStorage.getItem("logindetail") ||
                                        "{ }").imageThumb === null || JSON.parse(localStorage.getItem("logindetail") ||
                                            "{ }").imageThumb === "" || JSON.parse(localStorage.getItem("logindetail") ||
                                                "{ }").imageThumb === undefined ?
                                        `https://i1.wp.com/avatar-management--avatars.us-west-2.prod.public.atl-paas.net/initials/${initialsgenerator(JSON.parse(localStorage.getItem("logindetail") || "{}").name)}-${textnumbergenerator(JSON.parse(localStorage.getItem("logindetail") || "{}").name)}.png?ssl=1` :
                                        JSON.parse(localStorage.getItem("logindetail") || "{ }").imageThumb
                                }
                                name="Scott Farquhar"
                            />
                            <div style={{ marginLeft: 5 }}>

                                <p style={{ margin: 0, fontSize: 14 }}>{JSON.parse(localStorage.getItem("logindetail") || "{}").name}</p>
                                <p style={{ margin: 0, fontSize: 12, color: "#bac2d0" }}>{JSON.parse(localStorage.getItem("logindetail") || "{}").email}</p>
                            </div>

                        </div>
                        {
                            JSON.parse(localStorage.getItem("logindetail") || "{}").role === "Admin" ?
                                <div >
                                 
                                {/*    {showComponentA ? <button onClick={handleToggle} >
                                        <p style={{ color: highlightedButton === 'button1' ? '#0049B0' : '#43526D', fontWeight: 500, paddingLeft: "10px" }}>Admin</p>
                                    </button> : < button onClick={handleToggle}  >

                                        <p style={{ color: highlightedButton === 'button2' ? '#0049B0' : '#43526D' }}>User</p>

                                    </button>
                                            }
                                     */}
                                    
                           
                                </div>
                                
                                
                              /*  <div
                                  
                                    style={{
                                        display: "flex",
                                        justifyContent: "center", 
                                        marginTop: "10px",
                                    }}
                                >

                                    <button onClick={() =>handleToggle(!roleType)}
                                            style={{
                                                backgroundColor: "white",
                                                border: "1px solid #d9d9d9",
                                                padding: "5px",
                                                paddingLeft: "8px",
                                                paddingRight: "8px",
                                                borderRadius: "15px",
                                                fontSize: "14px",
                                                cursor: "pointer"
                                            }}
                                        >
                                            {roleType ? "Admin Portal" :"Employee Portal"}
                                        </button>
                                   
                                </div>*/
                                :
                                <div></div>
                        }

                        <div style={{
                            margin: 15
                        }}>

                            <div onClick={openModal} style={{ display: "flex", marginBottom: 10, cursor: "pointer", alignItems: "center" }}>
                                <div style={{ paddingBottom: 7 }}>
                                    <UnlockFilledIcon primaryColor="#bac2d0" label="change password" />
                                </div>
                                <div style={{ paddingLeft: 10, fontSize: 14 }}>
                                    Change Password
                                </div>
                            </div>
                        </div>
                    </DropdownItemGroup>


                </div>
                <div onClick={() => {
                    localStorage.clear()
                }}>
                    <Link style={{ display: "flex", cursor: "pointer", paddingLeft: 40, paddingRight: 15, paddingBottom: 15, paddingTop: 15, fontSize: 14, backgroundColor: "#f6f8fc", color: "#fd889e", textDecoration: "none" }} to="/login">
                        Sign Out
                    </Link>
                </div>
            </DropdownMenu>


            <Changepassword openModal={openModal} closeModal={closeModal} isOpen={isOpen} />


                {isOpenprofile && (
                    <AvatarPicker
                    avatars={[]}
                    onImagePicked={async (selectedImage, crop) => {
                        try{
                            let recorderinstance = await new ScreenRecorderInit().convertToBase64(selectedImage);
                            handlesubmit(recorderinstance)
                        }
                        catch(ex) {
                            console.log(ex)
                        }
                    }}
                    isLoading={loading}
                    onAvatarPicked={() => {}}
                    onCancel={() => setIsOpenprofile(false)}
                    />
                )}

            {/* <Userprofilemodal openModal={openModalprofile} closeModal={closeModalprofile} isOpen={isOpenprofile} /> */}
        </>
    );
};
export default ProfileDropdown;