import LabelIcon from '@atlaskit/icon/glyph/label'
import DashboardIcon from '@atlaskit/icon/glyph/dashboard'
import BoardIcon from '@atlaskit/icon/glyph/board'
import PeopleIcon from '@atlaskit/icon/glyph/people'
import ListIcon from '@atlaskit/icon/glyph/list'
import BitbucketBranchesIcon from '@atlaskit/icon/glyph/bitbucket/branches'
import CalendarIcon from '@atlaskit/icon/glyph/calendar'
import CrossIcon from '@atlaskit/icon/glyph/cross'
import './Sidebarnavigation.css'
import {
    ButtonItem,
    NestableNavigationContent,
    Section, 
    SideNavigation
} from "@atlaskit/side-navigation";
import AppFrame from "./Appframe";
import SampleHeader from "./Sampleheader";
import { useNavigate } from 'react-router-dom';
import { useCallback, useState } from 'react'
import RatingCategory from '../RatingCategory/RatingCategory'

const SidebarComponents = [
    {
        name: "Project",
        icon: <DashboardIcon primaryColor="#bac2d0" label="" />,
        route: "/sidebarProjects", 
    },
    {
        name: "Issue",
        icon: <LabelIcon primaryColor="#bac2d0" label="" />,
        route: "/sidebarIssues", 
    },
    {
        name: "Sprint",
        icon: <BoardIcon primaryColor="#bac2d0" label="" />,
        route: "/SidebarSprints", 
    },
    {
        name: "LogDetail",
        icon: <CalendarIcon primaryColor="#bac2d0" label="" />,
        route: "/logDetail", 
    },
    {
        name: "Branch",
        icon: <BitbucketBranchesIcon primaryColor="#bac2d0" label="" />,
        route: "/branch", 
    },/*
    {
        name: "Designation",
        icon: <BitbucketBranchesIcon primaryColor="#bac2d0" label="" />,
        route: "/designation",
    },*/
    {
        name: "User",
        icon: <PeopleIcon primaryColor="#bac2d0" label="" />,
        route: "/userDetail", 
    },
]

const Sidenavigation = ({onClose}: {onClose: () => void}) => {

    const navigate = useNavigate()
    const [isOpen1, setIsOpen1] = useState(false);
    const openModal1 = useCallback(() => setIsOpen1(true), []);
    const closeModal1 = useCallback(() => setIsOpen1(false), []);

    return (
        <AppFrame shouldHideAppBar>
            <SideNavigation label="project" testId="side-navigation">
                <div style={{
                    marginLeft: 35,
                    marginTop: 10,
                    marginBottom: 20,
                    display: "flex"
                }}>
                    <SampleHeader />
                    <div style={{ marginTop: 18, marginRight: 12, cursor: "pointer" }} onClick={onClose}>
                        <CrossIcon label="" primaryColor="#bac2d0" size="medium" />
                    </div>
                </div>
                <NestableNavigationContent
                    initialStack={[]}
                    testId="nestable-navigation-content"
                >
                    <Section>
                    {
                            SidebarComponents.map((item: {name: string, icon: JSX.Element, route: string}) =>
                            (
                            <div style={{
                                borderBottom: "2px solid #eff3f7",
                                marginLeft: 35,
                                marginRight: 15,
                                marginBottom: 10,
                                paddingBottom: 10

                            }} onClick={() => {
                                onClose()
                                navigate(item.route)
                            }}>
                                <ButtonItem iconBefore={item.icon}>
                                    <p style={{ margin: 0, color: "#364663", fontFamily: "lato" }}>
                                    {item.name}
                                    </p>
                                </ButtonItem>

                            </div>
                            )
                            )
                        }
                    </Section>
                </NestableNavigationContent>
            </SideNavigation>
            {
                <RatingCategory isOpen1={isOpen1}  closeModal1={closeModal1}/>
            }
        </AppFrame>


    );
};

export default Sidenavigation;
