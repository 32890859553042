import BooksService from "../Services/Apicall";


const randomColor = (i: number) => {

    let n = ( Math.random()* 0xfffff * 1000000).toString(16);

    return '#' + n.slice(0, 6);

  };


  const randomColorArray = () => {
    let arr: any = []
    for(let i = 0; i< 100; i++) {
      let n = (Math.random() * 0xfffff * 1000000).toString(16);
      arr.push('#' + n.slice(0, 6))
    }

    return arr;
  }

  export default randomColor

  export {randomColorArray};