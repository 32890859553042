import { useState } from "react"

export const useAlert = () => {
    const [isConfirm, setIsConfirm] = useState(false)

    function Confirm() {
        setIsConfirm(true)
    }

    function Reject() {
        setIsConfirm(false)
    }

    return {
        isConfirm,
        Confirm,
        Reject,
    }
}