import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalTitle,
    ModalTransition,
} from '@atlaskit/modal-dialog';

import React, { Fragment, useEffect, useState } from "react";
import { Link, redirect, useNavigate } from "react-router-dom";
import LoadingButton from "@atlaskit/button/loading-button";
import Button from "@atlaskit/button/standard-button";
import Spinner, { Size } from '@atlaskit/spinner';
import TextField from "@atlaskit/textfield";
import WatchFilledIcon from '@atlaskit/icon/glyph/watch-filled'
import { N500 } from '@atlaskit/theme/colors';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import WatchIcon from '@atlaskit/icon/glyph/watch'
import Form, {
    ErrorMessage,
    FormSection,
} from "@atlaskit/form";
import SuccessIcon from '@atlaskit/icon/glyph/check-circle';
import BooksService from '../../Services/Apicall';
import { token } from '@atlaskit/tokens';
import { AutoDismissFlag, FlagGroup } from '@atlaskit/flag';
import { G300 } from '@atlaskit/theme/colors';
import "./Changepassword.css"
const Changepassword = ({ openModal, closeModal, isOpen }: any) => {
    const [oldpasswordinputtype, setoldpasswordinputtype] = useState("password")
    const [newpasswordinputtype, setnewpasswordinputtype] = useState("password")
    const [confirmpasswordinputtype, setconfirmpasswordinputtype] = useState("password")
    const [userdata, setuserdata] = useState<any>([])
    const [confirmwaring, setconfirmwaring] = useState(false)
    const [loading, setloading] = useState(false)
    const [iserror, setiserror] = useState(false)
    const [errcontent, seterrorcontent] = useState("")
    const [alerttext, setalerttext] = useState("")
    const navigate = useNavigate()

    const inputvalue = {
        oldpassword: "",
        newpassword: "",
        confirmpassword: ""
    }

    const [inputfield, setinputfield] = useState(inputvalue)

    const handlechange = (e: any) => {
        setinputfield(
            {
                ...inputfield,
                [e.target.name]: e.target.value
            }
        )
    }

    const handleclose = () => {
        setiserror(false)

    }

    const [flags, setFlags] = useState<Array<number>>([]);
    const addFlag = () => {
        const newFlagId = flags.length + 1;
        const newFlags = flags.slice();
        newFlags.splice(0, 0, newFlagId);

        setFlags(newFlags);
    };

    const handleDismiss = () => {
        setFlags(flags.slice(1));
    };

    interface LoginHandleType { newpassword: string; confirmpassword: string; oldpassword: string; remember: boolean }

    const HandleSubmit = async () => {


        if (inputfield.newpassword === "" || inputfield.confirmpassword === "" || inputfield.oldpassword === "") {
            setiserror(true)
            seterrorcontent("All fields are required")
        }


        else if (inputfield.newpassword === inputfield.oldpassword) {
            setiserror(true)
            seterrorcontent("Current password and new password cannot be same")
        }


        else {

            if (inputfield.newpassword != inputfield.confirmpassword) {
                setconfirmwaring(true)
                setiserror(true)
                seterrorcontent("Password Doesn't Match")
            }
            else {

                setconfirmwaring(false)
                setloading(true)
                BooksService.resetPassword({ oldpassword: inputfield.oldpassword, newpassword: inputfield.newpassword, uid: JSON.parse(localStorage.getItem('logindetail') || '{}').id })
                    .then((res: any) => {

                        if (res.result === "Password changed successfully.") {
                            setloading(false)
                            setiserror(false)
                            navigate("/")

                            closeModal()
                            setalerttext("Password changed successfully")
                            addFlag()
                            navigate("/")
                        }
                        else {
                            setloading(false)
                            setiserror(false)
                           
                            setalerttext("error occure can't reset password")
                            addFlag()
                        }
                    })
            }
        }
    }



    return (
        <ModalTransition>

            <FlagGroup onDismissed={handleDismiss}>
                {flags.map((flagId) => {
                    return (
                        <AutoDismissFlag
                            id={flagId}
                            icon={<SuccessIcon
                                primaryColor={token('color.icon.success', G300)}
                                label="Success"
                            />}
                            key={flagId}
                            title={alerttext}


                        />
                    );
                })}
            </FlagGroup>
            {isOpen && (
                <Modal width="small" height={360}>
                    {
                        loading &&
                        <div className="loading">
                            <Spinner size="medium" />
                        </div>
                    }
                    <ModalHeader>
                        <ModalTitle>Change Password</ModalTitle>
                        <Button style={{ paddingRight: "0px" }} appearance="link" onClick={() => {
                            handleclose()
                            closeModal()
                        }
                        }>

                            <CrossIcon
                                label="Close Modal"
                                primaryColor={token('color.text.subtle', N500)}
                            />

                        </Button>
                    </ModalHeader>
                    <ModalBody>

                        <Form<{ newpassword: string; confirmpassword: string; oldpassword: string; remember: boolean }>
                            onSubmit={
                                (data) => HandleSubmit()}
                        >
                            {({ formProps, submitting }) => (
                                <form style={{
                                    textAlign: "center",

                                }} {...formProps}>
                                    <FormSection>
                                        <div style={{
                                            textAlign: "initial",
                                            margin: "auto",
                                            width: "353px"
                                        }}>

                                            <Fragment>
                                                <TextField type={oldpasswordinputtype} isRequired name="oldpassword" onChange={(e: any) => {
                                                    setnewpasswordinputtype("password")
                                                    setconfirmpasswordinputtype("password")
                                                    handlechange(e)
                                                }} autoComplete="off" placeholder="Old Password" elemAfterInput={
                                                    <div
                                                        onClick={() => oldpasswordinputtype === "password" ? setoldpasswordinputtype("text") : setoldpasswordinputtype("password")}>
                                                        <div style={{
                                                            marginRight: 10,
                                                            cursor: "pointer"
                                                        }}>
                                                            {
                                                                oldpasswordinputtype === "text" ?
                                                                <WatchFilledIcon label="text" size="medium" /> :
                                                                <WatchIcon size="medium" primaryColor="#9B9B9B" label="password" />
                                                            }

                                                        </div>
                                                    </div>
                                                } />

                                            </Fragment>

                                            <div style={{
                                                textAlign: "initial",
                                                margin: "auto",
                                                width: "353px",
                                                paddingTop: "20px",
                                            }}>
                                                <Fragment>
                                                    <TextField type={newpasswordinputtype} isRequired name="newpassword" onChange={(e: any) => {
                                                        setoldpasswordinputtype("password")
                                                        setconfirmpasswordinputtype("password")
                                                        handlechange(e)
                                                    }} placeholder="New Password" elemAfterInput={
                                                        <div
                                                            onClick={() => newpasswordinputtype === "password" ? setnewpasswordinputtype("text") : setnewpasswordinputtype("password")}>
                                                            <div style={{
                                                                marginRight: 10,
                                                                cursor: "pointer"
                                                            }}>
                                                                {
                                                                    newpasswordinputtype === "text" ?
                                                                        <WatchFilledIcon label="text" size="medium" /> :
                                                                        <WatchIcon size="medium" primaryColor="#9B9B9B" label="password" />
                                                                }
                                                            </div>
                                                        </div>
                                                    } />

                                                </Fragment>
                                            </div>
                                        </div>
                                        <div style={{
                                            textAlign: "initial",
                                            margin: "auto",
                                            width: "353px",
                                            paddingTop: "20px",
                                        }}>
                                            <Fragment>
                                                <TextField type={confirmpasswordinputtype} isRequired name="confirmpassword" onChange={(e: any) => {
                                                    setoldpasswordinputtype("password")
                                                    setnewpasswordinputtype("password")
                                                    handlechange(e)
                                                }} placeholder="Confirm Password" elemAfterInput={
                                                    <div
                                                        onClick={() => confirmpasswordinputtype === "password" ? setconfirmpasswordinputtype("text") : setconfirmpasswordinputtype("password")}>
                                                        <div style={{
                                                            marginRight: 10,
                                                            cursor: "pointer"
                                                        }}>
                                                            {
                                                                confirmpasswordinputtype === "text" ?
                                                                    <WatchFilledIcon label="text" size="medium" /> :
                                                                    <WatchIcon size="medium" primaryColor="#9B9B9B" label="password" />
                                                            }
                                                        </div>
                                                    </div>
                                                } />


                                            </Fragment>
                                        </div>
                                    </FormSection>
                                    {
                                        iserror && (
                                            <ErrorMessage>
                                                {errcontent}
                                            </ErrorMessage>
                                        )
                                    }
                                    
                                </form>
                            )}
                        </Form>

                    </ModalBody>
                    <ModalFooter>
                        <Button style={{
                            height: "30px",
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "3177FF"
                        }} appearance="subtle" onClick={() => {
                            handleclose()
                            closeModal()
                        }
                        }>Cancel</Button>
                        <LoadingButton
                            type="submit"
                            appearance="primary"
                            onClick={HandleSubmit}
                            style={{
                                height: "30px",
                                display: "flex",
                                alignItems: "center"
                            }}
                        >
                            Save
                        </LoadingButton>
                    </ModalFooter>
                </Modal>
            )}
        </ModalTransition>
    )
}

export default Changepassword