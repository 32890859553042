import * as XLSX from 'xlsx';

export function readFile(file: any) {
    return new Promise((resolve, reject) => {
      // Create file reader
      let reader = new FileReader()
  
      // Register event listeners
      reader.addEventListener("loadend", e => resolve(e.target.result))
      reader.addEventListener("error", reject)
  
      // Read file
      reader.readAsArrayBuffer(file)
    })
  }

export  async function getAsByteArray(file) {
    return new Uint8Array(await readFile(file))
}

export const exportToExcel = (data: Array<Array<any>>, filename: string, defaultsheetname: string = "Sheet1") => {
  const ws =  XLSX.utils.aoa_to_sheet(data);
  const wb =  XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, defaultsheetname);

  // Save the file
  XLSX.writeFile(wb, filename);
};