/*eslint-disable*/
import React from 'react';

import { jsx } from '@emotion/react';

import Box, { BoxProps } from '@atlaskit/ds-explorations/box';
import Icon from '@atlaskit/icon';
import { CustomItemComponentProps } from '@atlaskit/menu';

import { Header } from '@atlaskit/side-navigation';



const ExampleHeader = () => {
    return (
        <Header
        >
            <img width={140} src={process.env.PUBLIC_URL + "/Logo1.svg"} alt="" />
        </Header>
    );
};

export default ExampleHeader;
