import { useEffect, useState } from "react";
import LocalStorage from "../Services/Localstorage";

export const useAuth = () => {

    const [isAuth, setIsAuth] = useState<boolean>(false);
    const [user, setUser] = useState<any>({})

    useEffect(() => {
        let user = LocalStorage.getItem("logindetail", "JSON")
        if (user) {
            setIsAuth(true)
            setUser(user)
        }
        else {
            setIsAuth(false)
        }
    }, [])

    return { isAuth, user }
};
