import React, { useContext, useEffect } from 'react';
import { useCallback, useState } from 'react';
import Form, { ErrorMessage, Field, FormFooter } from '@atlaskit/form';
import ImageUploading from "react-images-uploading";
import AttachmentIcon from '@atlaskit/icon/glyph/attachment'
import Select, { CheckboxSelect } from '@atlaskit/select';
import LabelIcon from '@atlaskit/icon/glyph/label'
import AvatarGroup from '@atlaskit/avatar-group';
import { N500 } from '@atlaskit/theme/colors';
import SendIcon from '@atlaskit/icon/glyph/send'
import { AutoDismissFlag, FlagGroup } from '@atlaskit/flag';
import EditorDoneIcon from '@atlaskit/icon/glyph/editor/done'
import Spinner from '@atlaskit/spinner';
import { G300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import SuccessIcon from '@atlaskit/icon/glyph/check-circle';
import Textfield from '@atlaskit/textfield';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import './DashboardModal.css'
import BooksService from '../../Services/Books';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalTitle,
    ModalTransition,
} from '@atlaskit/modal-dialog';
import Tabs, { TabList, TabPanel, useTab } from '@atlaskit/tabs';
import { Panel } from '../DetaliModels/DetailModelFirst';
import Comment, { CommentAuthor, CommentEdited, CommentTime } from '@atlaskit/comment';
import Avatar from '@atlaskit/avatar';
import { imagePath, initialsgenerator, prioritycolorchange, priorityconvertor, selectvalue, statusbackgroundgenerator, statuscolorfont, statusconvertor, statusvalueconvertor, substringmethod, textnumbergenerator } from '../../utils/Util';
import { formatDatedatetimeyear } from '../../utils/DateConvertor';
import { NotifyContext } from '../../Routes/Headerroutes';
import { DatePicker } from '@atlaskit/datetime-picker';
import { ConfirmAlert } from '../UI/Alert/ConfirmAlert/ConfirmAlert';
import { useAlert } from '../../Hooks/useAlert';
import SunEditor from 'suneditor-react';
import Button from '@atlaskit/button';

export const DashboardModal = ({ openModal, isOpen, getTask, setchildtaskchange, setissuechange, setonchangepending, setdashboardloading, defaultselectassignee, initialassigneevalues, initiallabelvalue, setdefaultselectassignee, setdefaultselectlabel, defaultselectlabel, modaldata, setmodaldata, closeModal, modaltype = "dashboardmodal" }: any) => {
      
    const notifydetail = useContext(NotifyContext)
    const [cardWidth, setcardWidth] = useState(window.innerWidth)
    const [sprintdata, setsprintdata] = useState([])
    const [comment, setcomment] = useState("")
    const [errcomment, seterrcomment] = useState(false)
    const [changeTick, setchangeTick] = useState(false)
    const [changeRight, setchangeRight] = useState(false)
    const [changedueDate, setchangedueDate] = useState(null)
    const [userdata, setuserdata] = useState([])
    const [isloading, setisloading] = useState(false)
    const [labeldata, setlabeldata] = useState([])
    const [defaultvalueassignee, setdefaultvalueassignee] = useState([])
    const [isPanelLoading, setIsPanelLoading] = useState(false)
    const [priorityselect, setpriorityselect] = useState<any>(null)
    const [message, setmessage] = useState("")
    const [titlevalue, settitlevalue] = useState(null)
    window.addEventListener('resize', () => {
        setcardWidth(window.innerWidth)

    })

    const { isConfirm,
        Confirm,
        Reject, } = useAlert();

    const changetypearr = ["Title", "Description", "Sprint", "Priority", "Assignee", "status", "Issue Type"]

    let changesprintlog: string = ""
    let changeprioritylog: any = null
    let changename: string = ""

    const [logtype, setlogtype] = useState(1)
    const [logdata, setlogdata] = useState([])
    const [latestassigneechange, setlatestassigneechange] = useState<any>(null)
    const [latestlabelchange, setlatestlabelchange] = useState<any>(null)
    const [latestassigneechangestringonly, setlatestassigneechangestringonly] = useState<any>(null)
    const [latestlabelchangestringonly, setlatestlabelchangestringonly] = useState<any>(null)
    const [sprintselect, setsprintselect] = useState<any>(null)
    const [childTask, setChildTask] = useState([])
    const [statusselect, setstatusselect] = useState<any>(null)
    const [descriptionvalue, setdescriptionvalue] = useState<any>(null)
    const [images, setImages] = React.useState([]);
    const [attachmentchange, setattachmentchange] = useState(0)
    const [imagessave, setImagessave] = React.useState<any>([]);
    let today = new Date();

    let changetype: string = "";

    let labeloriginalarr = modaldata.label.map((item: any) => item.id)

    let assigneeoriginalarr = modaldata.assignee.map((item: any) => item.id)

    let assigneeidarrayoriginalname = modaldata.assignee.map((item: any) => item.name)

    let labelidarrayoriginalname = modaldata.label.map((item: any) => item.name)

    let defaultwatching = modaldata.watching?.map((item: any) => item.id)

    const defaultassigneevalue = () => {
        const defaultdata: any = []
        modaldata.assignee.map((item: any) => {
            defaultdata.push({ label: item.name, value: item.id })
        })
        setdefaultvalueassignee(defaultdata)
    }

    useEffect(() => {

        if (modaldata.id != null || modaldata.id != undefined || modaldata.id != "")
            BooksService.getTaskByTaskId(modaldata.id).then((res: any) => {
                setmodaldata({
                    ...modaldata,
                    ...res.result[0]

                })
            })
    }, [attachmentchange])

    const handleimagechange = (imageList: any, addUpdateIndex: any) => {

        changetype = "attachment";
        setImagessave([...imagessave, imageList[0]?.data_url])

        let imagarray: any = []
        for (let img in imageList) {
            imagarray.push({
                name: imageList[img].file.name,
                contentType: imageList[img].file.contenttype,
                existingType: 1,
                path: imageList[img].data_url
            })
        }
        saveimage(imagarray)

    }

    const saveimage = (imagarray: any) => {
        changetype = "attachment";
        submitchange("file", imagarray)
    }

    const fromchangetypefunc = (changetype: any) => {
        if (changetype === "Title") {
            if (localStorage.getItem(changetype)) {
                return localStorage.getItem(changetype)
            }
            else {
                return modaldata.title
            }
        }
        else if (changetype === "Description") {

            if (localStorage.getItem(changetype)) {
                return localStorage.getItem(changetype)
            }
            else {
                return modaldata.description
            }
        }
        else if (changetype === "Sprint") {
            if (modaldata.sprint.length === 0) {
                return "backlog"
            }
            else {
                if (localStorage.getItem(changetype)) {
                    return localStorage.getItem(changetype)
                }
                else {
                    return modaldata.sprint.length > 0 ? modaldata.sprint[0].name : ""
                }
            }

        }
        else if (changetype === "Priority") {
            if (localStorage.getItem(changetype)) {
                return localStorage.getItem(changetype)
            }
            else {
                return priorityconvertor(modaldata.priority)
            }
        }
        else if (changetype === "Assignee") {
            if (localStorage.getItem(changetype)) {
                return localStorage.getItem(changetype)
            }
            else {
                return assigneeidarrayoriginalname.toString().replaceAll(',', ', ');
            }
        }
        else if (changetype === "status") {
            if (localStorage.getItem(changetype)) {
                return localStorage.getItem(changetype)
            }
            else {
                return statusconvertor(modaldata.tStatus)
            }
        }
        else if (changetype === "Issue Type") {
            if (localStorage.getItem(changetype)) {
                return localStorage.getItem(changetype)
            }
            else {
                return labelidarrayoriginalname.toString().replaceAll(',', ', ');
            }
        }
    }

    const handleavatardata = (taskdata: any) => {
        const data = taskdata.assignee.map((d: any, i: any) => {
            return ({
                email: d.name,
                key: d.name,
                name: d.name,
                href: '#',
                src: d.thumb === null || d.thumb === "" || d.thumb === undefined ? `https://i1.wp.com/avatar-management--avatars.us-west-2.prod.public.atl-paas.net/initials/${initialsgenerator(d.name)}-${textnumbergenerator(d.name)}.png?ssl=1` :
                    d.thumb
            })
        }
        );

        return data;
    }

    useEffect(() => {
        BooksService.getLogByisComment(logtype, modaldata.id).then((res) => {
            setlogdata(res.result)
            setisloading(false)
        })
    }, [logtype, modaldata])

    const handlenoreloadchange = (changetype: string, data: any) => {
        localStorage.setItem(changetype, data)
    }

    const tochangetypefunc = (changetype: any) => {
        if (changetype === "Title") {
            return titlevalue
        }
        else if (changetype === "Description") {
            return descriptionvalue
        }
        else if (changetype === "Sprint") {
            return changesprintlog
        }
        else if (changetype === "Priority") {
            return priorityconvertor(changeprioritylog)
        }
        else if (changetype === "Assignee") {

            return latestassigneechangestringonly.toString().replaceAll(',', ', ');
        }
        else if (changetype === "status") {
            return changename
        }
        else if (changetype === "Issue Type") {
            return latestlabelchangestringonly.toString().replaceAll(',', ', ');
        }

    }

    const handlehistorydisplay = (remark: string) => {

        if (remark.includes("Updated the") || remark.includes("updated the")) {
            return <><span>{remark.split("Updated the")[0]}</span> Updated the <span>{remark.split("Updated the")[1]}</span></>
        }
        else if (remark.includes("created the") || remark.includes("Created the")) {
            return <><span>{remark.split("created the")[0]}</span> Created the <span>{remark.split("created the")[1]}</span></>
        }
    }

    const handlecomment = () => {

        if (comment === "") {
            seterrcomment(true)
        }
        else {
            BooksService.addLog({
                tId: modaldata.id,
                PId: modaldata.project[0].id,
                uKey: modaldata.uKey,
                assigneeName: JSON.parse(localStorage.getItem('logindetail') || '{}').name,
                email: JSON.parse(localStorage.getItem('logindetail') || '{}').email,
                remarks: comment,
                assigneeId: JSON.parse(localStorage.getItem('logindetail') || '{}').id,
                company: "63a959074e041fdc2ae91ce8",
                from: "",
                to: "",
                cngType: 4,
                isComment: 1
            }).then(() => {
                setcomment("")
                setlogtype(1)
                BooksService.getLogByisComment(logtype, modaldata.id).then((res) => {
                    setlogdata(res.result)
                    setisloading(false)
                    let assigneeidarray: any = [];

                    modaldata.assignee.map((value: any) => {
                        if (value.email != JSON.parse(localStorage.getItem("logindetail") || "{}").email) {
                            assigneeidarray.push(value.email)
                        }
                    })

                    let currentdate = new Date();
                    let currentdateiso = currentdate.toISOString()
                    let responseobjectnotify = {
                        tId: modaldata.id,
                        PId: modaldata.project[0].id,
                        uKey: modaldata.uKey,
                        assigneeName: JSON.parse(localStorage.getItem('logindetail') || '{}').name,
                        email: JSON.parse(localStorage.getItem('logindetail') || '{}').email,
                        remarks: comment,
                        assigneeId: JSON.parse(localStorage.getItem('logindetail') || '{}').id,
                        company: "63a959074e041fdc2ae91ce8",
                        from: "",
                        to: "",
                        cngType: 4,
                        isComment: true,
                        cDate: currentdateiso,
                    }

                    notifydetail.connection.invoke("SendMessage", assigneeidarray, JSON.stringify(responseobjectnotify))
                })
            })

        }

    }


    const handleattachmentdelete = async (image: any) => {

        setisloading(true)
        try {
            let res = BooksService.deleteAttachmentFile(modaldata.id, image.id)
            notifydetail.setbacklogrefresh((prev: any) => prev + 1)
            setisloading(false)
            setmessage(`File removed successfully`)
            addFlag()
            handlenoreloadchange(changetype, tochangetypefunc(changetype))
            setattachmentchange((prev: any) => prev + 1)
            if (modaltype === "pendingtask") {
                setonchangepending((prev: any) => prev + 1)
            }

        } catch (err) {

        }

    }

    const submitchange = async (type: any, value: any) => {

        let currentstate = {

            id: modaldata.id,

            title: titlevalue === null ? modaldata.title : titlevalue,

            description: descriptionvalue === null ? modaldata.description : descriptionvalue,

            sprintIds: [modaldata.sprint.length > 0 ? modaldata.sprint[0].id : ""],

            dueDate: modaldata.dueDate,

            priority: modaldata.priority,

            file: modaldata.file,

            tStatus: modaldata.tStatus,

            projectIds: [modaldata.project.length > 0 ? modaldata.project[0].id : ""],

            assigneedBy: [],

            taskId: modaldata.taskId,

            watchingIds: defaultwatching,

            assignedByIds: [modaldata.assignedBy != null ? modaldata.assignedBy[0].id : ""],

            assigneeIds: latestassigneechange === null ? initialassigneevalues : latestassigneechange,

            labelIds: latestlabelchange === null ? initiallabelvalue : latestlabelchange,

        }

        if (changetype === "Sprint") {
            if (fromchangetypefunc(changetype) != tochangetypefunc(changetype)) {

                setisloading(true)
            }
            await BooksService.addTask({ ...currentstate, tStatus: modaldata.tStatus === 0 ? 1 : modaldata.tStatus, [type]: value, company: "63a959074e041fdc2ae91ce8" }).then(async (res) => {

                if (fromchangetypefunc(changetype) != tochangetypefunc(changetype)) {

                    await BooksService.addLog(
                        {
                            tId: modaldata.id,
                            PId: modaldata.project[0].id,
                            uKey: modaldata.uKey,
                            assigneeId: JSON.parse(localStorage.getItem('logindetail') || '{}').id,
                            assigneeName: JSON.parse(localStorage.getItem('logindetail') || '{}').name,
                            remarks: `${JSON.parse(localStorage.getItem('logindetail') || '{}').name} Updated the ${changetype}`,
                            from: fromchangetypefunc(changetype),
                            isComment: 2,
                            to: tochangetypefunc(changetype),
                            cngType: 3,
                        }
                    ).then((res) => {
                        notifydetail.setbacklogrefresh((prev: any) => prev + 1)
                        setisloading(false)
                        setmessage(`${changetype} updated successfully`)
                        addFlag()
                        handlenoreloadchange(changetype, tochangetypefunc(changetype))
                        if (modaltype === "dashboardmodal") {
                            setdashboardloading((prev: any) => prev + 1)
                        }
                        if (modaltype === "pendingtask") {
                            setonchangepending((prev: any) => prev + 1)
                        }
                        getTask()
                    })

                }
                handlenoreloadchange(changetype, tochangetypefunc(changetype))

            })

        }

        if (changetype === "attachment") {
            setisloading(true)
            if (modaldata.file) {
                BooksService.addTask({ ...currentstate, watchingIds: defaultwatching ? defaultwatching : [], [type]: value, company: "63a959074e041fdc2ae91ce8" }).then((res: any) => {
                    setisloading(false)
                    notifydetail.setbacklogrefresh((prev: any) => prev + 1)
                    setisloading(false)
                    setmessage(`${changetype} added successfully`)
                    addFlag()
                    handlenoreloadchange(changetype, tochangetypefunc(changetype))
                    setdashboardloading((prev: any) => prev + 1)
                    setattachmentchange((prev: any) => prev + 1)
                    if (modaltype === "pendingtask") {
                        setonchangepending((prev: any) => prev + 1)
                    }
                    getTask()
                })
            }
            else {
                BooksService.addTask({ ...currentstate, watchingIds: defaultwatching ? defaultwatching : [], [type]: value, company: "63a959074e041fdc2ae91ce8" }).then((res: any) => {
                    setisloading(false)
                    notifydetail.setbacklogrefresh((prev: any) => prev + 1)
                    setisloading(false)
                    setmessage(`${changetype} added successfully`)
                    addFlag()
                    handlenoreloadchange(changetype, tochangetypefunc(changetype))
                    setdashboardloading((prev: any) => prev + 1)
                    if (modaltype === "pendingtask") {
                        setonchangepending((prev: any) => prev + 1)
                    }
                })
            }
        }

        else {

            if (changetype === "Due Date") {
                setisloading(true)
                await BooksService.addTask({ ...currentstate, [type]: value, company: "63a959074e041fdc2ae91ce8" }).then((res: any) => {
                    notifydetail.setbacklogrefresh((prev: any) => prev + 1)
                    setisloading(false)
                    setmessage(`${changetype} updated successfully`)
                    addFlag()
                    if (modaltype === "childtaskmodal") {
                        setchildtaskchange((prev: number) => prev + 1)
                    }
                    handlenoreloadchange(changetype, tochangetypefunc(changetype))
                    if (modaltype === "dashboardmodal") {
                        setdashboardloading((prev: any) => prev + 1)
                    }
                    if (modaltype === "pendingtask") {
                        setonchangepending((prev: any) => prev + 1)
                    }
                    if (modaltype === "issuemodal") {
                        setissuechange((prev: any) => prev + 1)
                    }
                })
            }

            if (fromchangetypefunc(changetype).trim() != tochangetypefunc(changetype).trim()) {
                if (fromchangetypefunc(changetype) != tochangetypefunc(changetype)) {
                    setisloading(true)
                }
                await BooksService.addTask({ ...currentstate, [type]: value, company: "63a959074e041fdc2ae91ce8" }).then(async (res) => {

                    if (fromchangetypefunc(changetype) != tochangetypefunc(changetype)) {

                        await BooksService.addLog(
                            {
                                tId: modaldata.id,
                                PId: modaldata.project[0].id,
                                uKey: modaldata.uKey,
                                assigneeId: JSON.parse(localStorage.getItem('logindetail') || '{}').id,
                                assigneeName: JSON.parse(localStorage.getItem('logindetail') || '{}').name,
                                remarks: `${JSON.parse(localStorage.getItem('logindetail') || '{}').name} Updated the ${changetype}`,
                                from: fromchangetypefunc(changetype),
                                to: tochangetypefunc(changetype),
                                isComment: 2,
                                cngType: changetype === "status" ? 2 : 3,
                            }
                        ).then((res) => {
                            notifydetail.setbacklogrefresh((prev: any) => prev + 1)
                            setisloading(false)
                            setmessage(`${changetype} updated successfully`)
                            if (modaltype === "childtaskmodal") {
                                setchildtaskchange((prev: number) => prev + 1)
                            }
                            addFlag()
                            setattachmentchange((prev: any) => prev + 1)
                            /*handlenoreloadchange(changetype, tochangetypefunc(changetype))*/
                            if (modaltype === "dashboardmodal") {
                                setdashboardloading((prev: any) => prev + 1)
                            }
                            if (modaltype === "pendingtask") {
                                setonchangepending((prev: any) => prev + 1)
                            }
                            if (modaltype === "issuemodal") {
                                setissuechange((prev: any) => prev + 1)
                            }
                        })

                    }

                })
            }
        }

    }

    useEffect(() => {

        for (let item in changetypearr) {
            localStorage.removeItem(changetypearr[item])
        }

        BooksService.getSprint(modaldata.project[0].id).then((res: any) => {
            let sprintarr: any = []
            res.result.map((item: any) => {
                sprintarr.push({ label: item.name, value: item.id, ...item })
            })
            setsprintdata(sprintarr)
        })

        BooksService.getUserByProjectId(modaldata.project[0].id).then((res: any) => {
            let user: any = []
            res.result[0].assignee.map((item: any) => {
                user.push({ label: item.name, value: item.id, ...item })
            })
            setuserdata(user)
        })

        BooksService.getLabel(1500).then((res: any) => {
            let sprintarr: any = []
            res.result.map((item: any) => {
                sprintarr.push({ label: item.name, value: item.id, ...item })
            })
            setlabeldata(sprintarr)
        })

        return () => {
            setstatusselect(null)
        }
    }, [])

    const [flags, setFlags] = useState<Array<number>>([]);
    const addFlag = () => {
        const newFlagId = flags.length + 1;
        const newFlags = flags.slice();
        newFlags.splice(0, 0, newFlagId);

        setFlags(newFlags);
    };

    const handleDismiss = () => {
        setFlags(flags.slice(1));
    };

    const CustomTab = ({ label }: { label: string }) => {

        const tabAttributes = useTab();

        return (

            <div  {...tabAttributes}>

                {label}

            </div>

        );

    };
    const [imageobject, setimageobject] = useState()
    const [isOpenconfirm, setIsOpenconfirm] = useState(false);
    const openModalconfirm = useCallback(() => setIsOpenconfirm(true), []);
    const closeModalconfirm = useCallback(() => setIsOpenconfirm(false), []);

    return (
        <>
            <div>
                <div style={{
                    fontFamily: "lato",
                    fontSize: 10
                }}>
                    <FlagGroup onDismissed={handleDismiss}>
                        {flags.map((flagId) => {
                            return (
                                <AutoDismissFlag
                                    id={flagId}
                                    icon={<SuccessIcon
                                        primaryColor={token('color.icon.success', G300)}
                                        label="Success"
                                    />}
                                    key={flagId}
                                    title={`${message}`}

                                />
                            );
                        })}
                    </FlagGroup>
                </div>

                <ModalTransition>

                    {isOpen && (
                        <Modal width="x-large">
                            {
                                isloading &&
                                <div className="loading">
                                    <Spinner size="medium" />
                                </div>
                            }
                            <ModalBody>
                                <div style={{
                                    display: "flex",
                                    alignItems: "baseline",
                                    justifyContent: "space-between",
                                    marginTop: 14
                                }}>
                                    <div style={{
                                        display: "flex"
                                    }}>
                                        <div style={{
                                            fontSize: 14,
                                            fontWeight: 600,
                                            paddingLeft: 50
                                        }}>
                                            Task Details
                                        </div>
                                        <div style={{
                                            fontSize: 14,
                                            fontWeight: 600,
                                            marginLeft: 5
                                        }}>
                                            ({modaldata.uKey})
                                        </div>
                                    </div>
                                    <div>
                                        <div style={{ cursor: "pointer", marginRight: 18 }} onClick={() => {
                                            settitlevalue(null)
                                            setdescriptionvalue(null)
                                            for (let item in changetypearr) {
                                                localStorage.removeItem(changetypearr[item])
                                            }
                                            closeModal()
                                            setlogdata([])

                                        }} >
                                            <CrossIcon
                                                label="Close Modal"
                                                size="medium"
                                                primaryColor="#bac2d0"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <hr style={{ color: "#F6F6F6", opacity: "0.2", marginTop: "10px", marginBottom: "10px" }} />

                                <div style={{ display: `${cardWidth < 550 ? "block" : "flex"}`, justifyContent: "space-evenly", gridTemplateColumns: "auto auto" }}>

                                    <div style={{ padding: "15px", paddingLeft: "20px", paddingRight: "20px", height: "57vh", overflowX: "scroll" }}>

                                        <div style={{ padding: `${cardWidth < 650 ? "10px" : "0px"}`, display: "flex", justifyContent: "space-between" }}>
                                            <div>
                                                <label className="labelcolor" style={{ fontSize: 14 }}>Status</label>
                                                <div style={{
                                                    width: `${cardWidth < 550 ? "150px" : "173px"}`,
                                                    marginLeft: 25,
                                                    marginTop: 5,
                                                    fontSize: "10px!important"
                                                }}>
                                                    <Select
                                                        inputId="checkbox-select-example"
                                                        className="checkbox-select"
                                                        options={[{ label: "Todo", value: 1 },
                                                        { label: "Need To Discuss", value: 2 },
                                                        { label: "In Progress", value: 3 },
                                                        { label: "UAT", value: 4 },
                                                        { label: "Developed", value: 7 },
                                                        { label: "Testing", value: 5 },
                                                        { label: "Done", value: 6 }
                                                            ,]}
                                                        spacing="compact"
                                                        placeholder="Choose status"
                                                        defaultValue={{ label: statusconvertor(modaldata.tStatus), value: statusconvertor(modaldata.tStatus) }}
                                                        onChange={(value: any) => {
                                                            changetype = "status"
                                                            setstatusselect(value)
                                                            changename = value.label
                                                            submitchange("tStatus", value.value)
                                                        }}
                                                        styles={{
                                                            control: (base) => {

                                                                return {
                                                                    ...base,
                                                                    backgroundColor: "#f2f6fc",
                                                                    fontSize: 14,
                                                                    border: "none",

                                                                }

                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <label className="labelcolor" style={{ fontSize: 14 }}>Assigned By</label>
                                                <div style={{
                                                    width: `${cardWidth < 550 ? 150 : 200}`,
                                                    marginTop: 5,
                                                    fontSize: "10px!important"
                                                }}>
                                                    <Textfield
                                                        appearance="standard"
                                                        value={modaldata.assignedBy[0].name}
                                                        style={{
                                                            backgroundColor: "#f6f8fc",
                                                            height: "32px"
                                                        }}
                                                        placeholder="Assigned by"
                                                        isReadOnly
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{
                                            marginTop: `${cardWidth < 450 ? "0px" : "14px"}`,
                                            padding: `${cardWidth < 650 ? "10px" : "0px"}`,
                                            paddingTop: `${cardWidth < 450 ? "0px" : "10px"}`
                                        }}>
                                            <label className="labelcolor" style={{
                                                fontSize: 14
                                            }}>Title</label>
                                            <div style={{
                                                marginTop: 5
                                            }}>
                                                <Textfield
                                                    appearance="standard"
                                                    required
                                                    style={{
                                                        backgroundColor: "#f6f8fc",
                                                    }}
                                                    value={titlevalue === null ? modaldata.title : titlevalue}
                                                    onBlur={
                                                        () => {
                                                            changetype = "Title"
                                                            if (titlevalue === null) {
                                                                submitchange("title", modaldata.title)
                                                            }
                                                            else {
                                                                submitchange("title", titlevalue)
                                                            }
                                                        }
                                                    }
                                                    isRequired
                                                    onChange={(e: any) => settitlevalue(e.target.value)}
                                                />
                                            </div>
                                        </div>

                                        <div style={{
                                            paddingTop: "20px"
                                        }}>
                                            <label className="labelcolor" style={{ fontSize: 14 }}>Sprint</label>
                                            <div style={{
                                                width: 334,
                                                marginLeft: 25,

                                                marginTop: 5,
                                                fontSize: "10px!important"
                                            }}>
                                                {
                                                    modaldata.sprint.length > 0 ?
                                                        <Select
                                                            inputId="checkbox-select-example"
                                                            className="checkbox-select"
                                                            options={[...sprintdata]}

                                                            spacing="compact"
                                                            placeholder="Choose sprint"
                                                            onChange={(value: any) => {
                                                                if (modaltype === "dashboardmodal" || "childtaskmodal") {
                                                                    changetype = "Sprint"
                                                                    setsprintselect(value)
                                                                    changesprintlog = value.label
                                                                    submitchange("sprintIds", [value.value])
                                                                }
                                                            }}
                                                            value={sprintselect === null ? { label: modaldata.sprint[0].name, value: modaldata.sprint[0].id } : sprintselect}
                                                            styles={{
                                                                control: (base) => {

                                                                    return {
                                                                        ...base,
                                                                        backgroundColor: "#f2f6fc",
                                                                        fontSize: 14,
                                                                        border: "none",

                                                                    }

                                                                }
                                                            }}
                                                        />
                                                        :
                                                        <Select
                                                            inputId="checkbox-select-example"
                                                            className="checkbox-select"
                                                            options={[...sprintdata]}

                                                            spacing="compact"
                                                            placeholder="Choose sprint"
                                                            onChange={(value: any) => {

                                                                if (modaltype === "dashboardmodal" || "childtaskmodal") {
                                                                    changetype = "Sprint"
                                                                    setsprintselect(value)
                                                                    changesprintlog = value.label
                                                                    submitchange("sprintIds", [value.value])
                                                                }
                                                            }}
                                                            value={sprintselect}
                                                            styles={{
                                                                control: (base) => {

                                                                    return {
                                                                        ...base,
                                                                        backgroundColor: "#f2f6fc",
                                                                        fontSize: 14,
                                                                        border: "none",

                                                                    }

                                                                }
                                                            }}
                                                        />
                                                }
                                            </div>
                                        </div>

                                        <div style={{
                                            display: `${cardWidth < 450 ? "block" : "flex"}`,
                                            justifyContent: "space-between",
                                            marginTop: `${cardWidth < 450 ? "0px" : "10px"}`,
                                            padding: `${cardWidth < 650 ? "10px" : "0px"}`,
                                            paddingTop: "10px"

                                        }}>
                                            <div>
                                                <label className="labelcolor" style={{ fontSize: 14 }}>Priority</label>
                                                <div style={{
                                                    width: 145,
                                                    marginLeft: 25,
                                                    fontSize: "10px!important"
                                                }}>
                                                    <Select
                                                        inputId="checkbox-select-example"
                                                        className="checkbox-select"
                                                        options={[
                                                            { label: "High", value: 2 },
                                                            { label: "Medium", value: 1 },
                                                            { label: "Low", value: 0 },
                                                        ]}
                                                        value={priorityselect === null ? { label: priorityconvertor(modaldata.priority), value: modaldata.priority } : priorityselect}
                                                        spacing="compact" ffff
                                                        placeholder="Choose priority"
                                                        onChange={(value: any) => {
                                                            changetype = "Priority"
                                                            setpriorityselect(value)
                                                            changeprioritylog = value.value
                                                            submitchange("priority", value.value)
                                                        }}
                                                        styles={{
                                                            control: (base) => {

                                                                return {
                                                                    ...base,
                                                                    backgroundColor: "#f2f6fc",
                                                                    fontSize: 14,
                                                                    border: "none",

                                                                }

                                                            }
                                                        }}
                                                    />

                                                </div>
                                            </div>

                                            <div className='devidenav'>
                                                <label style={{ display: "flex", paddingLeft: "4px", alignItems: "center", fontSize: 14, paddingRight: "21px", paddingBottom: "2px", color: "#657187" }}>Due Date</label>
                                                <li style={{ listStyle: "none" }}>
                                                    <div style={{ marginLeft: 4, width: "130px", fontSize: 14 }}>
                                                        <DatePicker
                                                            name="datetime-picker"
                                                            spacing="compact"
                                                            dateFormat="DD/MM/YYYY"
                                                            placeholder="DD/MM/YYYY"
                                                            defaultValue={modaldata.dueDate}

                                                            onChange={(e: any) => {

                                                                if (e != "") {
                                                                    changetype = "Due Date"
                                                                    setchangedueDate(e)
                                                                    submitchange("dueDate", e)
                                                                }
                                                            }
                                                            }
                                                            appearance="default"
                                                        />
                                                    </div>

                                                </li>
                                            </div>

                                        </div>
                                        <div style={{
                                            justifyContent: "space-between",
                                            marginTop: 20,
                                            padding: `${cardWidth < 650 ? "10px" : "0px"}`
                                        }}>
                                            <div style={{ paddingLeft: `${cardWidth < 450 ? "0px" : "0px"}`, paddingTop: `${cardWidth < 450 ? "10px" : "0px"}`, paddingBottom: `${cardWidth < 450 ? "10px" : "0px"}` }}>
                                                <label className="labelcolor" style={{ fontSize: 14 }}>Assignee</label>
                                                <div style={{
                                                    width: "94%",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    marginTop: 5,
                                                    paddingLeft: 24
                                                }}>

                                                    <CheckboxSelect
                                                        inputId="checkbox-select-example"
                                                        className="checkbox-select"
                                                        isRequired
                                                        options={[...userdata]}
                                                        menuPlacement="top"
                                                        placeholder="Choose assignee"

                                                        onChange={(value) => {
                                                            setchangeTick(true)
                                                            setdefaultselectassignee(value)
                                                            let idarr: any = []
                                                            let idarr2: any = []
                                                            value.map((item: any) => {
                                                                idarr.push(item.value)
                                                                idarr2.push(item.label)
                                                            })
                                                            setlatestassigneechangestringonly(idarr2)
                                                            assigneeoriginalarr = idarr
                                                            setlatestassigneechange(idarr)

                                                        }}
                                                        value={[...defaultselectassignee]}
                                                        styles={{
                                                            control: (base) => {

                                                                return {
                                                                    ...base,
                                                                    backgroundColor: "#f6f8fc",
                                                                    border: "none",

                                                                }

                                                            }
                                                        }}
                                                    />
                                                    {
                                                        changeTick === !false ?
                                                            <div style={{
                                                                marginLeft: 5, cursor: "pointer", backgroundColor: "#F2F6FC", borderRadius: "5px", width: "36px", height: "34px", display: "flex",
                                                                justifyContent: "center", alignItems: "center"
                                                            }}
                                                                onClick={() => {
                                                                    changetype = "Assignee"
                                                                    submitchange("assigneeIds", latestassigneechange)
                                                                }}
                                                            >
                                                                <EditorDoneIcon label="add" primaryColor="#3177ff" />
                                                            </div> :
                                                            <div style={{
                                                                marginLeft: 5, cursor: `${changeTick === false ? "no-drop" : "pointer"}`, backgroundColor: "#dee0e3", borderRadius: "5px", width: "36px", height: "34px", display: "flex",
                                                                justifyContent: "center", alignItems: "center"
                                                            }}
                                                                onClick={() => {
                                                                    changetype = "Assignee"
                                                                }}
                                                            >
                                                                <EditorDoneIcon label="add" primaryColor="#bfbfbf" />
                                                            </div>
                                                    }

                                                </div>

                                            </div>

                                        </div>
                                        <div style={{ marginTop: 20 }}>
                                            <div style={{ paddingLeft: `${cardWidth < 450 ? "24px" : "0px"}`, paddingTop: `${cardWidth < 450 ? "10px" : "0px"}`, paddingBottom: `${cardWidth < 450 ? "10px" : "0px"}` }}>
                                                <label className="labelcolor" style={{ marginTop: 15, fontSize: 14 }}>Issue Type</label>
                                                <div style={{
                                                    width: "94%",
                                                    marginTop: 5,
                                                    display: "flex",
                                                    alignItems: "center",
                                                    marginLeft: "23px"
                                                }}>

                                                    <CheckboxSelect
                                                        inputId="checkbox-select-example"
                                                        className="checkbox-select"
                                                        placeholder="Choose Issue Type"
                                                        options={[...labeldata]}
                                                        menuPlacement="top"
                                                        value={[...defaultselectlabel]}
                                                        onChange={(value) => {

                                                            if (modaltype === "dashboardmodal" || "childtaskmodal") {
                                                                setchangeRight(true)
                                                                setdefaultselectlabel(value)
                                                                let idarr: any = []
                                                                let idarr2: any = []
                                                                value.map((item: any) => {
                                                                    idarr.push(item.value)
                                                                    idarr2.push(item.label)
                                                                })
                                                                setlatestlabelchangestringonly(idarr2)
                                                                labeloriginalarr = idarr
                                                                setlatestlabelchange(idarr)
                                                            }
                                                        }}
                                                        styles={{
                                                            control: (base) => {

                                                                return {
                                                                    ...base,
                                                                    backgroundColor: "#f6f8fc",
                                                                    border: "none",

                                                                }

                                                            }
                                                        }}

                                                    />
                                                    {
                                                        changeRight === !false ?
                                                            <div style={{
                                                                marginLeft: 5, cursor: "pointer", backgroundColor: "#F2F6FC", borderRadius: "5px", width: "36px", height: "34px", display: "flex",
                                                                justifyContent: "center", alignItems: "center"
                                                            }}
                                                                onClick={() => {
                                                                    changetype = "Issue Type"
                                                                    submitchange("labelIds", latestlabelchange)
                                                                }}
                                                            >
                                                                <EditorDoneIcon label="add" primaryColor="#3177ff" />
                                                            </div> :
                                                            <div style={{
                                                                marginLeft: 5, cursor: `${changeTick === false ? "no-drop" : "pointer"}`, backgroundColor: "#dee0e3", borderRadius: "5px", width: "36px", height: "34px", display: "flex",
                                                                justifyContent: "center", alignItems: "center"
                                                            }}
                                                                onClick={() => {
                                                                    changetype = "Issue Type"

                                                                }}
                                                            >
                                                                <EditorDoneIcon label="add" primaryColor="#bfbfbf" />
                                                            </div>
                                                    }

                                                </div>

                                            </div>
                                        </div>
                                        <div style={{
                                            marginTop: `${cardWidth < 450 ? "0px" : "14px"}`,
                                            padding: `${cardWidth < 650 ? "10px" : "0px"}`,
                                            paddingTop: `${cardWidth < 450 ? "0px" : "10px"}`
                                        }}>
                                            <label className="labelcolor" style={{
                                                fontSize: 14
                                            }}>Description</label>
                                            <div style={{
                                                marginTop: 5
                                            }}>
                                                <SunEditor
                                                    setContents={descriptionvalue === null ? modaldata.description : descriptionvalue}
                                                    setOptions={{
                                                        buttonList: [
                                                            ["undo", "redo"],
                                                            ["font", "fontSize"],
                                                            // ['paragraphStyle', 'blockquote'],
                                                            [
                                                                "bold",
                                                                "underline",
                                                                "italic",
                                                                "strike"
                                                            ],
                                                            ["fontColor", "hiliteColor"],
                                                            ["align", "list", "lineHeight"],
                                                            ["outdent", "indent"],
                                                            ["table", "horizontalRule", "link", "image", "video"],
                                                            // ['math'] //You must add the 'katex' library at options to use the 'math' plugin.
                                                            // ['imageGallery'], // You must add the "imageGalleryUrl".
                                                            // ["fullScreen", "showBlocks", "codeView"],
                                                            ["print"],
                                                            ["removeFormat"]
                                                            // ['save', 'template'],
                                                            // '/', Line break
                                                        ], // Or Array of button list, eg. [['font', 'align'], ['image']]
                                                        defaultTag: "div",
                                                        minHeight: "300px",
                                                        showPathLabel: false
                                                    }}

                                                    onChange={(value: string) => setdescriptionvalue(value)}
                                                />

                                                <Button appearance="primary"
                                                    style={{
                                                        height: "35px", display: "flex", marginTop: 10,
                                                        alignItems: "center", marginRight: "10px", marginBottom: 10,
                                                        backgroundColor: "#3177ff", fontSize: "14px!important"
                                                    }}
                                                    onClick={(e: any, editorContent: any) => {
                                                        console.log("working")
                                                        changetype = "Description"
                                                        if (descriptionvalue === null) {
                                                            submitchange("description", modaldata.description)
                                                        }
                                                        else {
                                                            submitchange("description", descriptionvalue)
                                                        }
                                                    }}>
                                                    Save Description
                                                </Button>


                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div style={{
                                            marginTop: 10,
                                            marginLeft: 12,
                                            fontSize: 14,
                                            fontFamily: "lato"
                                        }}>

                                            <Tabs id="custom-tabs" defaultSelected={1}>
                                                <TabList>
                                                    <div onClick={() => {

                                                        if (logtype != 0) {

                                                            setlogtype(0)
                                                            setlogdata([])
                                                        }
                                                    }}>
                                                        <CustomTab label="All" />
                                                    </div>
                                                    <div onClick={() => {

                                                        if (logtype != 1) {

                                                            setlogtype(1)
                                                            setlogdata([])
                                                        }
                                                    }}>
                                                        <CustomTab label="Comment" />   
                                                    </div>
                                                    <div onClick={() => {

                                                        if (logtype != 2) {

                                                            setlogtype(2)
                                                            setlogdata([])
                                                        }
                                                    }}>
                                                        <CustomTab label="History" />
                                                    </div>
                                                    <div
                                                        style={{
                                                            marginLeft: 10
                                                        }}
                                                        onClick={() => {


                                                        }}>
                                                        <CustomTab label="Attachment" />
                                                    </div>

                                                    {
                                                        modaldata.taskId === null &&
                                                        <div
                                                            style={{
                                                                marginLeft: 10
                                                            }}
                                                            onClick={() => {
                                                                setIsPanelLoading(true)
                                                                BooksService.getChildTask(modaldata.id, 1).then((res: any) => {
                                                                    setChildTask(res.result)
                                                                    setIsPanelLoading(false)
                                                                })

                                                            }}>
                                                            <CustomTab label="Child" />
                                                        </div>
                                                    }

                                                </TabList>

                                                <TabPanel>
                                                    <Panel><div style={{
                                                        height: "444px",
                                                        width: "400px",
                                                        overflowY: `${isloading ? "hidden" : "scroll"}`
                                                    }}>

                                                        {
                                                            isloading ?
                                                                (
                                                                    <div style={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        marginTop: "30%"
                                                                    }}>
                                                                        <Spinner />
                                                                    </div>

                                                                ) :

                                                                (logdata.map((value: any, index: any) => (

                                                                    <div key={index} style={{
                                                                        margin: 8,
                                                                        marginBottom: 20,
                                                                    }}>

                                                                        {

                                                                            value.from === "" && value.to === "" ?
                                                                                (
                                                                                    <div style={{
                                                                                        fontSize: 14,
                                                                                        fontFamily: "lato"
                                                                                    }}>
                                                                                        <p style={{
                                                                                            fontSize: 14,
                                                                                            marginBottom: 1,
                                                                                            color: "#091e42",
                                                                                        }}>{<><span>{value.assigneeName}</span>{` added a `}<span>{`comment `}</span></>}</p>
                                                                                        <p style={{ fontSize: "10px", color: "#8b99a7", margin: 0, marginBottom: 3 }}>
                                                                                            {formatDatedatetimeyear(value.cDate)}
                                                                                        </p>
                                                                                        <p style={{
                                                                                            fontSize: 14,
                                                                                            color: "#6c798e",
                                                                                            margin: 0
                                                                                        }}>

                                                                                            {
                                                                                                value.remarks
                                                                                            }

                                                                                        </p>
                                                                                    </div>
                                                                                ) :

                                                                                (<>
                                                                                    <p style={{
                                                                                        fontSize: 14,
                                                                                        fontFamily: "lato",
                                                                                        marginBottom: 1,
                                                                                        color: "#091e42",
                                                                                    }}>{
                                                                                            <>
                                                                                                {value.remarks}
                                                                                            </>
                                                                                        }
                                                                                    </p>

                                                                                    <p style={{ fontSize: "10px", color: "#8b99a7", margin: 0, marginBottom: 3 }}>
                                                                                        {formatDatedatetimeyear(value.cDate)}
                                                                                    </p>
                                                                                    <p style={{
                                                                                        fontSize: 14,
                                                                                        color: "#6c798e",
                                                                                        margin: 0
                                                                                    }}>
                                                                                        {
                                                                                            (value.cngType === 3 || value.cngType === 2) &&
                                                                                            ((<> <span style={{ backgroundColor: `${statusbackgroundgenerator(value.from)}`, color: `${statuscolorfont(value.from)}`, paddingTop: 1, paddingBottom: 1, paddingLeft: 5, paddingRight: 5, borderRadius: 2, fontSize: 12 }}>{value.from ? value.from : "None"}</span> {`->`} <span style={{ backgroundColor: `${statusbackgroundgenerator(value.to)}`, color: `${statuscolorfont(value.to)}`, paddingTop: 1, paddingBottom: 1, paddingLeft: 5, paddingRight: 5, borderRadius: 2, fontSize: 12 }}>{value.to ? value.to : "None"}</span>
                                                                                            </>))
                                                                                        }
                                                                                    </p>
                                                                                </>)

                                                                        }
                                                                    </div>

                                                                )))
                                                        }

                                                    </div>  </Panel>
                                                </TabPanel>

                                                <TabPanel>
                                                    <Panel><div style={{
                                                        height: "436px",
                                                        marginTop: 8,
                                                        fontSize: 14,
                                                        width: "400px",
                                                        overflowY: `${isloading ? "hidden" : "scroll"}`
                                                    }}>

                                                        {
                                                            isloading ?
                                                                (
                                                                    <div style={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        marginTop: "30%"
                                                                    }}>
                                                                        <Spinner />
                                                                    </div>) :

                                                                (logdata.map((value: any, index: any) => (
                                                                    <div style={{
                                                                        marginBottom: 10,
                                                                        fontSize: 14,
                                                                        fontFamily: "lato"
                                                                    }}>

                                                                        <Comment
                                                                            avatar={<Avatar
                                                                                name="Scott Farquhar"
                                                                                src={
                                                                                    value.thumb === null || value.thumb === "" || value.thumb === undefined ?
                                                                                        `https://i1.wp.com/avatar-management--avatars.us-west-2.prod.public.atl-paas.net/initials/${initialsgenerator(value.assigneeName)}-${textnumbergenerator(value.assigneeName)}.png?ssl=1`
                                                                                        : value.thumb
                                                                                }

                                                                            />}
                                                                            author={<div style={{ fontWeight: "bold" }}><CommentAuthor>{value.assigneeName}</CommentAuthor></div>}

                                                                            time={<CommentTime>{formatDatedatetimeyear(value.cDate)}</CommentTime>}
                                                                            content={
                                                                                <p style={{
                                                                                    margin: 0,
                                                                                    padding: 0
                                                                                }}>
                                                                                    {value.remarks}
                                                                                </p>
                                                                            }
                                                                        />
                                                                    </div>
                                                                )))
                                                        }

                                                    </div>  </Panel>
                                                </TabPanel>

                                                <TabPanel>
                                                    <Panel><div style={{
                                                        height: "444px",
                                                        width: "400px",
                                                        overflowY: `${isloading ? "hidden" : "scroll"}`
                                                    }}>

                                                        {
                                                            isloading ?
                                                                (
                                                                    <Spinner />) :

                                                                (
                                                                    logdata.map((value: any, index: any) => (

                                                                        <div key={index} style={{
                                                                            margin: 8,
                                                                            marginBottom: 20
                                                                        }}>
                                                                            <p style={{
                                                                                fontSize: 14,
                                                                                fontFamily: "lato",
                                                                                marginBottom: 1,
                                                                                color: "#091e42",
                                                                            }}>{<>

                                                                                {value.remarks}

                                                                            </>}</p>
                                                                            <p style={{ fontSize: "10px", color: "#8b99a7", margin: 0, marginBottom: 3 }}>
                                                                                {formatDatedatetimeyear(value.cDate)}
                                                                            </p>
                                                                            <p style={{
                                                                                fontSize: 14,
                                                                                color: "#6c798e",
                                                                                margin: 0,

                                                                            }}>

                                                                                {
                                                                                    (value.cngType === 3 || value.cngType === 2) &&
                                                                                    (<> <span style={{ backgroundColor: `${statusbackgroundgenerator(value.from)}`, color: `${statuscolorfont(value.from)}`, paddingTop: 1, paddingBottom: 1, paddingLeft: 5, paddingRight: 5, borderRadius: 2, fontSize: 12 }}>{value.from ? value.from : "None"}</span> {`->`} <span style={{ backgroundColor: `${statusbackgroundgenerator(value.to)}`, color: `${statuscolorfont(value.to)}`, paddingTop: 1, paddingBottom: 1, paddingLeft: 5, paddingRight: 5, borderRadius: 2, fontSize: 12 }}>{value.to ? value.to : "None"}</span>
                                                                                    </>)
                                                                                }

                                                                            </p>
                                                                        </div>

                                                                    ))
                                                                )
                                                        }

                                                    </div>  </Panel>
                                                </TabPanel>

                                                <TabPanel>
                                                    <Panel><div style={{
                                                        height: "444px",
                                                        width: "400px",
                                                        overflowY: `${isloading ? "hidden" : "scroll"}`
                                                    }}>

                                                        {
                                                            isloading ?
                                                                (
                                                                    <Spinner />) :

                                                                (
                                                                    modaldata.file.map((value: any, index: any) => (

                                                                        <div style={{
                                                                            margin: 5
                                                                        }}>
                                                                            {imagePath(value.name)}
                                                                            <p style={{ margin: 0 }}  >{value.name}</p>

                                                                            <span style={{ paddingLeft: "48px", cursor: "pointer" }} onClick={() => {

                                                                                setimageobject(value)
                                                                                Confirm()
                                                                            }
                                                                            }><CrossIcon
                                                                                    label="Close Modal"
                                                                                    size="small"
                                                                                    primaryColor={token('color.text.subtle', N500)}
                                                                                /></span>
                                                                        </div>
                                                                    ))
                                                                )
                                                        }

                                                    </div>  </Panel>
                                                </TabPanel>

                                                { }
                                                <TabPanel>
                                                    <Panel><div style={{
                                                        height: "436px",
                                                        marginTop: 8,
                                                        fontSize: 14,
                                                        width: "400px",
                                                        overflowY: `${isloading ? "hidden" : "scroll"}`
                                                    }}>

                                                        {
                                                            isPanelLoading ?
                                                                (
                                                                    <div style={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        marginTop: "30%"
                                                                    }}>
                                                                        <Spinner />
                                                                    </div>) :

                                                                <div style={{
                                                                    marginBottom: 10,
                                                                    fontSize: 14,
                                                                    fontFamily: "lato"
                                                                }}>

                                                                    {
                                                                        childTask.map((item: any) => (
                                                                            <div style={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                cursor: "pointer",
                                                                                paddingTop: "10px"
                                                                            }}
                                                                            >

                                                                                <div
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        alignItems: "center"
                                                                                    }}
                                                                                    onClick={() => {

                                                                                        let assigneearr: any = []
                                                                                        let labelarr: any = []
                                                                                        let assigneeidarr: any = []
                                                                                        let labelidarray: any = []

                                                                                        item.assignee.map((item: any) => {
                                                                                            assigneearr.push({ label: item.name, value: item.id })
                                                                                            assigneeidarr.push(item.id)
                                                                                        })

                                                                                        item.label.map((item: any) => {
                                                                                            labelarr.push({ label: item.name, value: item.id })
                                                                                            labelidarray.push(item.id)
                                                                                        })

                                                                                    }}
                                                                                >
                                                                                    <div style={{

                                                                                        borderRadius: "50%", height: "20px", display: "flex", justifyContent: "center", alignItems: "center", transform: "rotate(91deg)",

                                                                                    }}>
                                                                                        <LabelIcon label="task" size="small" primaryColor={prioritycolorchange(item.priority)} />
                                                                                    </div>
                                                                                    <div>
                                                                                        <div style={{
                                                                                            width: "135px",
                                                                                            alignItems: "center"
                                                                                        }}>
                                                                                            <div>
                                                                                                <span style={{ fontSize: "14px", fontFamily: "lato", color: "#4a5973", paddingLeft: "5px", marginBottom: "0px" }}>{item.uKey}/</span>
                                                                                                <span style={{ fontSize: "14px", padding: 0, fontFamily: "lato", color: "#3177ff", paddingLeft: "5px", marginBottom: "0px" }}>{item.jira_UKey}</span>
                                                                                            </div>

                                                                                            <div>
                                                                                                <span style={{ fontSize: "14px", fontFamily: "lato", color: "#4a5973", paddingLeft: "5px" }}>{substringmethod(item.title)}</span>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>

                                                                                </div>
                                                                                <div style={{
                                                                                    paddingLeft: "10px",
                                                                                    width: "57px",

                                                                                }}>
                                                                                    {
                                                                                        item.assignee.length > 0 ?
                                                                                            <AvatarGroup appearance="stack" size="small" maxCount={2} data={handleavatardata(item)} /> :
                                                                                            <Avatar size="small" />
                                                                                    }

                                                                                </div>

                                                                                <div style={{
                                                                                    paddingLeft: "41px",
                                                                                    width: "103px"
                                                                                }}>
                                                                                    <Select
                                                                                        options={[...selectvalue]}
                                                                                        spacing="compact"
                                                                                        placeholder="Choose status"

                                                                                        defaultValue={[{ label: statusconvertor(item.tStatus), value: statusvalueconvertor(item.tStatus) }]}
                                                                                        onChange={(value: any) => {
                                                                                            setisloading(true)
                                                                                            BooksService.updateTask({ tid: item.id, tstatus: value.value }).then((res: any) => {
                                                                                                setisloading(false)
                                                                                                setchildtaskchange((prev: number) => prev + 1)
                                                                                            })
                                                                                        }}
                                                                                        styles={
                                                                                            {
                                                                                                control: (base: any) => ({ ...base, fontSize: 14, width: "90%", minHeight: 23, height: 23 }),
                                                                                                singleValue: (base) => ({
                                                                                                    ...base,
                                                                                                    top: "40%"
                                                                                                }),
                                                                                                indicatorsContainer: (base: any) => ({
                                                                                                    ...base,
                                                                                                    height: "20px"
                                                                                                }),
                                                                                                input: (base: any) => ({
                                                                                                    ...base,
                                                                                                    margin: 0,
                                                                                                    paddingTop: 0
                                                                                                })
                                                                                            }
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>

                                                        }

                                                    </div>  </Panel>
                                                </TabPanel>

                                            </Tabs>
                                            <div style={{ display: "flex", justifyContent: "space-between", paddingBottom: "20px", paddingTop: "5px" }}>
                                                {/* <div style={{ paddingLeft: "40px" }}> */}
                                                <div style={{
                                                    width: "350px",
                                                    marginTop: 8,
                                                    border: "1px solid #f4f5f7",
                                                    borderRadius: "5px"
                                                }}>

                                                    <Textfield
                                                        appearance="subtle"
                                                        label="Subtle"
                                                        value={comment}
                                                        height={50}
                                                        autoFocus
                                                        placeholder="Add a comment.."
                                                        onChange={(e: any) => {
                                                            seterrcomment(false)
                                                            setcomment(e.target.value)

                                                        }}
                                                    />
                                                    {
                                                        errcomment && (
                                                            <ErrorMessage>
                                                                Please enter comment
                                                            </ErrorMessage>
                                                        )
                                                    }
                                                </div>
                                                <div>
                                                    <ImageUploading
                                                        multiple
                                                        value={images}
                                                        onChange={handleimagechange}
                                                        allowNonImageType={true}
                                                        dataURLKey="data_url"
                                                    >
                                                        {({
                                                            imageList,
                                                            onImageUpload,
                                                            onImageRemoveAll,
                                                            onImageUpdate,
                                                            onImageRemove,
                                                            isDragging,
                                                            dragProps
                                                        }) => (

                                                            <div onClick={onImageUpload} style={{ display: "flex", justifyContent: "flex-end", marginTop: 19, marginLeft: 5, cursor: "pointer" }}>
                                                                <h5 style={{ marginTop: "0px", marginBottom: "0px" }} ><AttachmentIcon size="medium" primaryColor="#647288" label="person" /> </h5>
                                                            </div>
                                                        )}
                                                    </ImageUploading>
                                                </div>
                                                <div style={{ marginTop: "16px", marginRight: 10 }} onClick={handlecomment}>
                                                    <SendIcon primaryColor="#647288" label="prob" />

                                                    {/*                                                    <Button style={{ marginTop: "11px", backgroundColor: "#3177ff", marginRight: 10 }} appearance="primary" onClick={handlecomment} >Comment</Button>
*/}                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </ModalBody>

                        </Modal>
                    )}
                </ModalTransition>
            </div>

            {
                isConfirm &&
                <ConfirmAlert Reject={Reject} heading="Are you sure" message="" callBack={() => handleattachmentdelete(imageobject)} />
            }
        </>
    )
}
