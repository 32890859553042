import DynamicTable from '@atlaskit/dynamic-table';
import { useCallback, useEffect, useState } from 'react';
import BooksService from '../../Services/Apicall';
import AvatarGroup from '@atlaskit/avatar-group';
import HipchatChevronDoubleUpIcon from '@atlaskit/icon/glyph/hipchat/chevron-double-up'
import HipchatChevronDoubleDownIcon from '@atlaskit/icon/glyph/hipchat/chevron-double-down'
import { initialsgenerator, Priorityflag, statusbackgroundgenerator, statuscolorfont, statusconvertor, textnumbergenerator } from '../../utils/Util';
import Tooltip from '@atlaskit/tooltip';
import { formatDatedatetimeyeargforcreatetask } from '../../utils/DateConvertor';
import { DashboardModal } from '../DashboardModal/DashboardModal';

export default function IssuesTable({ userbyproject, setissuechange }: any) {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const openModal = useCallback(() => setIsOpen(true), []);
    const closeModal = useCallback(() => setIsOpen(false), []);
    const [defaultselectassignee, setdefaultselectassignee] = useState([])
    const [defaultselectlabel, setdefaultselectlabel] = useState([])

    const getYrlUkey = (clickedValue) => {
        window.open(process.env.REACT_APP_API_URL + "/task/" + `${clickedValue}`)
    }

    const [initialassigneevalues, setinitialassigneevalues] = useState([])
    const [initiallabelvalue, setinitiallabelvalue] = useState([])

    const [modaldata, setmodaldata] = useState<any>()

    const substringmethod = (data: string) => {

        if (data?.length > 32) {
            return data.substring(0, 32) + "..."
        }
        else {
            return data
        }
    }

    const createHead = (withWidth: boolean) => {
        return {
            cells: [
                { 
                    key: 'Key',
                    content: <div style={{ marginLeft: "25px" }}>Key</div>,
                    width: 1
                },
                /*{
                    key: 'Jira Ukey',
                    content: 'Jira Ukey',
                    isSortable: true,
                    width: 0.5
                },*/
                {
                    key: 'Parent',
                    content: 'Parent',
                    width: 1
                },
                /* {
                     key: 'Jira Parent Ukey',
                     content: 'Jira Parent Ukey',
                     isSortable: true,
                     width: 1
                 },*/
                {
                    key: 'Project',
                    content: 'Project',
                    width: 1
                },
                {
                    key: 'Task',
                    content: 'Task',
                    width: 1
                },
                {
                    key: 'Status',
                    content: 'Status', 
                    width: 1
                },
                {
                    key: 'Assignee',
                    content: 'Assignee',
                    width: 1
                },
                {
                    key: 'Assigned By',
                    content: 'Assigned By',
                    width: 1
                },

                {
                    key: 'Due Date',
                    content: 'Due Date',
                    width: 1
                },
                {
                    key: 'Duplicate of',
                    content: 'Duplicate of',
                    width: 1
                }
            ],
        };
    };

    const head = createHead(true);

    return (
        <>
            <div style={{
                fontSize: 15,
                marginLeft: 20,
                marginRight: 20,
                marginTop: 10
            }}>

                {
                    <DynamicTable
                        head={head}
                        rows={userbyproject?.map((data: any, index: number) => {

                            const avatardata = data?.assignee?.map((d: any, i: any) => ({
                                email: d?.name,
                                key: d?.name,
                                name: d?.name,
                                href: '#',
                                src: d?.thumb === null || d?.thumb === "" || d?.thumb === undefined ? `https://i1.wp.com/avatar-management--avatars.us-west-2.prod.public.atl-paas.net/initials/${initialsgenerator(d?.name)}-${textnumbergenerator(d?.name)}.png?ssl=1` :
                                    d?.thumb
                            }));
                            const avatardata2 = data?.assignedBy?.map((d: any, i: any) => ({
                                email: d?.name,
                                key: d?.name,
                                name: d?.name,
                                href: '#',
                                src: d?.thumb === null || d?.thumb === "" || d?.thumb === undefined ? `https://i1.wp.com/avatar-management--avatars.us-west-2.prod.public.atl-paas.net/initials/${initialsgenerator(d?.name)}-${textnumbergenerator(d?.name)}.png?ssl=1` :
                                    d?.thumb
                            }));
                            return ({
                                key: `row-${index}-${data.id}`,
                                isHighlighted: false,
                                cells: [
                                    {
                                        key: data?.uKey,
                                        content:
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <div style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    fontSize: 14,
                                                    fontFamily: "lato"
                                                }}>
                                                    {
                                                        Priorityflag(data.priority) === "red" && <HipchatChevronDoubleUpIcon label="high" primaryColor="#ff8585" />
                                                    }
                                                    {
                                                        Priorityflag(data.priority) === "orange" && <HipchatChevronDoubleDownIcon label="medium" primaryColor="#ffd19e" />
                                                    }
                                                    {
                                                        Priorityflag(data.priority) === "yellow" && <HipchatChevronDoubleDownIcon label="low" primaryColor="#d9d9d9" />
                                                    }
                                                </div>
                                                <div key={index} style={{ cursor: "pointer", fontSize: 14, color: "#5770d9" }} onClick={async () => {
                                                    let res: any = await BooksService.getTaskByIdGet(data?.id)
                                                    getYrlUkey(data?.uKey)
                                                }}>
                                                    {data?.uKey?.length > 0 ? data?.uKey : "-"}
                                                </div>

                                            </div>
                                    },
                                    {
                                        content:
                                            <div style={{ fontSize: 14 }}>
                                                {data?.jira_UKey?.length > 0 ? data?.jira_UKey : "-"}
                                            </div>
                                    },
                                    {
                                        key: data.project[0]?.name,
                                        content: data?.project[0]?.name
                                    },
                                    {
                                        key: data.title,
                                        content:
                                            <Tooltip content={data.title}>
                                                {(tooltipProps) => (

                                                    <p {...tooltipProps} className="" style={{
                                                        margin: 0,
                                                        fontSize: 14,
                                                        fontFamily: "lato",
                                                        color: "#253148",
                                                        fontWeight: 400

                                                    }}>{substringmethod(data?.title)}</p>
                                                )}
                                            </Tooltip>
                                    },
                                    {
                                        key: statusconvertor(data?.tStatus),
                                        content: statusconvertor(data?.tStatus)
                                    },
                                    {
                                        content: (

                                            <div style={{
                                                display: "flex",
                                                alignItems: "center",
                                                fontSize: 14,
                                                fontFamily: "lato",

                                            }}>
                                                <AvatarGroup css={{ zIndex: 0 }} appearance="stack" size="small" maxCount={2} data={avatardata} />
                                            </div>
                                        )
                                    },
                                    {
                                        content: (

                                            <div style={{
                                                display: "flex",
                                                alignItems: "center",
                                                fontSize: 14,
                                                fontFamily: "lato",

                                            }}>
                                                <AvatarGroup appearance="stack" size="small" maxCount={2} data={avatardata2} />
                                            </div>
                                        )
                                    },
                                    {
                                        content: (

                                            <div style={{
                                                display: "flex",
                                                alignItems: "center",
                                                fontSize: 14,
                                                fontFamily: "lato"
                                            }}>
                                                {formatDatedatetimeyeargforcreatetask(data?.dueDate, "normal")}
                                            </div>
                                        )
                                    },
                                    {
                                        content: (

                                            <div style={{
                                                display: "flex",
                                                alignItems: "center",
                                                fontSize: 14,
                                                fontFamily: "lato"
                                            }}>
                                                <>
                                                    {data?.duplicateReference?.map((item: any, index: number) => {
                                                        if (index > data?.duplicateReference.length - 1)
                                                            return (
                                                                <span style={{ cursor: "pointer" }} onClick={() => {
                                                                    getYrlUkey(item)
                                                                }}>
                                                                    {item}&nbsp
                                                                </span>
                                                            )
                                                        else return (
                                                            <span style={{ cursor: "pointer" }} onClick={() => {
                                                                getYrlUkey(item)
                                                            }}>
                                                                {item}
                                                            </span>
                                                        )
                                                    })}
                                                </>
                                                {data?.duplicateReference?.length > 0 ? data?.duplicateReference?.join(", ") : "-"}

                                            </div>
                                        )
                                    },

                                ],
                            })

                        }

                        )
                        }
                        loadingSpinnerSize="large"
                    />
                }
            </div>
            {
                modaldata &&
                <DashboardModal isOpen={isOpen} modaltype={"issuemodal"} setissuechange={setissuechange} initialassigneevalues={initialassigneevalues} initiallabelvalue={initiallabelvalue} defaultselectassignee={defaultselectassignee} setdefaultselectassignee={setdefaultselectassignee} setdefaultselectlabel={setdefaultselectlabel} defaultselectlabel={defaultselectlabel} modaldata={modaldata} setmodaldata={setmodaldata} openModal={openModal} closeModal={closeModal} />
            }
        </>
    );
}
