import { useEffect, useState } from 'react';
import React from 'react';
import Avatar from '@atlaskit/avatar';
import Textfield from '@atlaskit/textfield';
import Spinner from '@atlaskit/spinner';
import "./DetailModel.css"
import Button from '@atlaskit/button/standard-button';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import Badge from '@atlaskit/badge';
import { N500 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import PersonIcon from '@atlaskit/icon/glyph/person'
import Comment, { CommentAuthor, CommentEdited, CommentTime } from '@atlaskit/comment';
import {
    DropdownItemCheckbox,
    DropdownItemCheckboxGroup,
} from '@atlaskit/dropdown-menu';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import Modal, {
    ModalBody,
    ModalTransition,
    ModalTitle,
    useModal,
} from '@atlaskit/modal-dialog';
import BooksService from '../../Services/Apicall';
import Tabs, { TabList, TabPanel, useTab } from '@atlaskit/tabs';
import { formatDatedatetimeyear } from '../../utils/DateConvertor';
import { randomhax } from '../../utils/Util';

export const Panel = ({ children }: { children: any }) => (

    <div>{children}</div>

);
export default function DetailModel({ openModal, sprintdatachecked, labelidarray, logdatedroppable, projectid, randomcolor, setrandomcolor, titleimg, assigneeidarray, initialuserdata, projectimage, projectname, singletask, fetchSprint, fetchTasks, isOpen, page, closeModal }: any) {

    let changename: any = ""
    const [comment, setcomment] = useState("")
    const [logtype, setlogtype] = useState(1)
    const [shouldrender, setshouldrender] = useState(false)
    const CustomHeader = ({ titleimg, submitchange, randomcolor, setrandomcolor }: any) => {
        const { onClose, titleId } = useModal();


        return (
            <div style={{ display: "flex", justifyContent: "space-between", margin: "15px" }}>
                <div style={{ display: "flex" }}>

                    {
                        <div style={{
                            borderRadius: 5,
                            padding: 8,
                            marginRight: 4,
                            fontSize: 15,
                            color: "white",
                            backgroundColor: `${randomhax(localStorage.getItem("index"))}`,
                        }}>
                            {titleimg}
                        </div>
                    }

                    <ModalTitle>{projectname}</ModalTitle>
                </div>
                <Button appearance="link" onClick={() => {
                    if (focustype === "Title") {
                        changetype = "Title"
                        submitchange("title", changetitle)
                    }
                    else if (focustype === "Description") {
                        changetype = "Description"
                        changetype = "Description"
                        submitchange("description", changedescription)
                    }

                    closeModal()
                }
                }>
                    <CrossIcon
                        label="Close Modal"
                        primaryColor={token('color.text.subtle', N500)}
                    />
                </Button>
            </div>
        );
    };

    const prioritycheck = (value: any) => {
        switch (value) {
            case 0: return "Low";
            case 1: return "Medium";
            case 2: return "High";
        }
    }

    let assigneeidarrayoriginalname: any = []
    let labelidarrayoriginalname: any = []
    let assigneeidarrayname: any = []
    let labelidarrayname: any = []
    assigneeidarrayname = singletask?.assignee?.map((value: any) => value.name)
    labelidarrayname = singletask?.label?.map((value: any) => value.name)
    assigneeidarrayoriginalname = singletask?.assignee?.map((value: any) => value.name)
    labelidarrayoriginalname = singletask?.label?.map((value: any) => value.name)

    const initialvalue = {
        id: singletask?.id,

        title: singletask?.title,

        description: singletask?.description,

        sprint: singletask?.sprint?.length > 0 ? singletask?.sprint : "",

        sprintname: singletask?.sprint?.length > 0 ? singletask?.sprint[0].name : "",

        dueDate: singletask?.dueDate,

        priority: prioritycheck(singletask?.priority),

        tStatus: 1,

        projectIds: [singletask?.project != null ? singletask?.project[0].id : ""],

        assigneedBy: [],

        assigneeIds: [],

        labelIds: [singletask?.label?.length > 0 ? singletask?.label[0].id : ""],

        labelname: singletask?.label?.length > 0 ? singletask?.label[0].id : ""
    }

    const tstatustoboardchanger = (tstatus: any) => {
        switch (tstatus) {
            case 1: return "Todo";
            case 2: return "Need To Discuss";
            case 3: return "In Progress";
            case 4: return "UAT";
            case 5: return "Testing";
            case 6: return "Done";
        }
    }

    const [images, setImages] = React.useState([]);
    const [mydata, setMyData] = useState("GT-25")
    const [myTask, setMyTask] = useState(singletask?.label?.length > 0 ? singletask.label[0].name : "")
    const [myPriority, setMyPriority] = useState(prioritycheck(singletask?.priority))
    const [labeldata, setlabeldata] = useState([])
    const [userdata, setuserdata] = useState([])
    const [sprintname, setsprintname] = useState(singletask?.sprint?.length > 0 ? singletask?.sprint[0].name : "backlog")
    const [changetitle, setchangetitle] = useState(initialvalue?.title)
    const [changedescription, setchangedescription] = useState(initialvalue?.description)
    const [logdata, setlogdata] = useState([])
    const [tStatus, settStatus] = useState(tstatustoboardchanger(singletask?.tStatus))
    const [focustype, setfocustype] = useState("")
    const [isloading, setisloading] = useState(true)

    let changetype: any = ""
    let changelabellog: any = ""
    let changesprintlog: any = ""
    let changeprioritylog: any = ""


    const tstatuscounter = (tstatus: string) => {
        switch (tstatus) {
            case "Todo": return 1;
            case "Need to Discuss": return 2;
            case "In Progress": return 3;
            case "UAT": return 4;
            case "Testing": return 5;
            case "Done": return 6;
        }
    }

    const maxNumber = 69;
    const onChange = (imageList: any, addUpdateIndex: any) => {
        // data for submit
        setImages(imageList);
    };
    const myChange = (data: any) => {
        setMyData(data)
    }
    const MyTask = (datas: any) => {
        setMyTask(datas)
    }
    const MyPriority = (tasks: any) => {
        setMyPriority(tasks)
    }


    const fromchangetypefunc = (changetype: any) => {
        if (changetype === "Title") {
            return singletask?.title
        }
        else if (changetype === "Description") {
            return singletask?.description
        }
        else if (changetype === "Sprint") {
            return singletask?.sprint?.length > 0 ? singletask?.sprint[0].name : ""
        }
        else if (changetype === "Priority") {
            return prioritycheck(singletask?.priority)
        }
        else if (changetype === "Assignee") {
            return assigneeidarrayoriginalname.toString().replaceAll(',', ', ');
        }
        else if (changetype === "status") {
            return tstatustoboardchanger(singletask?.tStatus)
        }
        else if (changetype === "Label") {
            return labelidarrayoriginalname.toString().replaceAll(',', ', ');
        }
    }

    const tochangetypefunc = (changetype: any) => {
        if (changetype === "Title") {
            return changetitle
        }
        else if (changetype === "Description") {
            return changedescription
        }
        else if (changetype === "Sprint") {
            return changesprintlog
        }
        else if (changetype === "Priority") {
            return changeprioritylog
        }
        else if (changetype === "Assignee") {
            return assigneeidarrayname.toString().replaceAll(',', ', ');
        }
        else if (changetype === "status") {
            return changename
        }
        else if (changetype === "Label") {
            return labelidarrayname.toString().replaceAll(',', ', ');
        }
    }

    const submitchange = async (type: any, value: any) => {

        let currentstate = {

            id: singletask?.id,

            title: singletask?.title,

            description: singletask?.description,

            sprintIds: [singletask?.sprint?.length > 0 ? singletask?.sprint[0].id : ""],

            dueDate: singletask?.dueDate,

            priority: singletask?.priority,

            tStatus: singletask?.tStatus,

            projectIds: [singletask?.project?.length > 0 ? singletask?.project[0].id : ""],

            assigneedBy: [],

            assignedByIds: [singletask?.assignedBy != null ? singletask?.assignedBy[0].id : ""],

            assigneeIds: singletask?.assignee != null || singletask?.assignee?.length > 0 ? assigneeidarray : "",

            labelIds: [singletask?.label?.length > 0 ? singletask?.label[0].id : ""]

        }

        if (changetype === "Sprint") {
            await BooksService.addTask({ ...currentstate, tStatus: 1, [type]: value, company: "63a959074e041fdc2ae91ce8" }).then(async (res) => {

                fetchTasks(page)

                if (fromchangetypefunc(changetype) != tochangetypefunc(changetype)) {
                    await BooksService.addLog(
                        {
                            tId: singletask?.id,
                            uKey: singletask?.uKey,
                            PId: localStorage.getItem("projectid"),
                            assigneeId: JSON.parse(localStorage.getItem('logindetail') || '{}').id,
                            assigneeName: JSON.parse(localStorage.getItem('logindetail') || '{}').name,
                            remarks: `${JSON.parse(localStorage.getItem('logindetail') || '{}').name} Updated the ${changetype}`,
                            from: fromchangetypefunc(changetype),
                            isComment: 2,
                            to: tochangetypefunc(changetype),
                            cngType: 3,
                        }
                    )

                }

            })
        }

        else {
            await BooksService.addTask({ ...currentstate, [type]: value, company: "63a959074e041fdc2ae91ce8" }).then(async (res) => {

                fetchTasks(page)

                if (fromchangetypefunc(changetype) != tochangetypefunc(changetype)) {
                    await BooksService.addLog(
                        {
                            tId: singletask?.id,
                            uKey: singletask?.uKey,
                            assigneeId: JSON.parse(localStorage.getItem('logindetail') || '{}').id,
                            PId: localStorage.getItem("projectid"),
                            assigneeName: JSON.parse(localStorage.getItem('logindetail') || '{}').name,
                            remarks: `${JSON.parse(localStorage.getItem('logindetail') || '{}').name} Updated the ${changetype}`,
                            from: fromchangetypefunc(changetype),
                            to: tochangetypefunc(changetype),
                            isComment: 2,
                            cngType: 3,
                        }
                    )

                }

            })
        }
        await BooksService.getLogByTaskid(singletask?.id).then((res) => {
            setlogdata(res.result)
        })
    }

    const CustomTab = ({ label }: { label: string }) => {

        const tabAttributes = useTab();
        return (
            <div {...tabAttributes}>

                {label}

            </div>

        );

    };

    const onblurfunctioncall = () => {
        submitchange("assigneeIds", assigneeidarray)
    }


    const onblurfunctioncall2 = () => {
        submitchange("labelIds", labelidarray)
    }

    useEffect(() => {
        setlogdata([])

    }, [logtype])


    const handlecomment = () => {
        setcomment("")
        BooksService.addLog({
            tId: singletask?.id,
            uKey: singletask?.uKey,
            PId: localStorage.getItem("projectid"),
            assigneeName: JSON.parse(localStorage.getItem('logindetail') || '{}').name,
            email: JSON.parse(localStorage.getItem('logindetail') || '{}').email,
            remarks: comment,
            assigneeId: JSON.parse(localStorage.getItem('logindetail') || '{}').id,
            company: "63a959074e041fdc2ae91ce8",
            from: "",
            to: "",
            cngType: 1,
            isComment: 1
        }).then(() => {
            if (logtype === 1) {
                setlogtype(1)
            }
        })
    }

    return (
        <div>
            <ModalTransition>
                {isOpen && (
                    <Modal width="large">

                        <CustomHeader titleimg={titleimg} focustype={focustype} submitchange={submitchange} randomcolor={randomcolor} setrandomcolor={setrandomcolor} />

                        <ModalBody>
                            <div style={{}}>

                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <ul className='modelnavlist' style={{ display: "flex", listStyle: "none", paddingLeft: "0px" }}>

                                        {
                                            tstatustoboardchanger(singletask?.tStatus) &&
                                            (
                                                <li className='fontul' style={{ marginRight: "20px" }}>
                                                    <DropdownMenu trigger={tStatus}>
                                                        <DropdownItemGroup>
                                                            <DropdownItem onClick={() => {
                                                                settStatus("Todo")
                                                                changetype = "status"
                                                                changename = "Todo"
                                                                submitchange("tStatus", 1)
                                                            }}>TO DO</DropdownItem>
                                                            <DropdownItem onClick={() => {
                                                                settStatus("Need To Discuss")
                                                                changetype = "status"
                                                                changename = "Need To Discuss"
                                                                submitchange("tStatus", 2)
                                                            }}>Need To Discuss</DropdownItem>
                                                            <DropdownItem onClick={() => {
                                                                settStatus("In Progress")
                                                                changetype = "status"
                                                                changename = "In Progress"
                                                                submitchange("tStatus", 3)
                                                            }}>In Progress</DropdownItem>
                                                            <DropdownItem onClick={() => {
                                                                settStatus("UAT")
                                                                changetype = "status"
                                                                changename = "In Progress"
                                                                submitchange("tStatus", 4)
                                                            }}>UAT</DropdownItem>
                                                            <DropdownItem onClick={() => {
                                                                settStatus("Testing")
                                                                changetype = "status"
                                                                changename = "Testing"
                                                                submitchange("tStatus", 5)
                                                            }}>Testing</DropdownItem>
                                                            <DropdownItem onClick={() => {
                                                                settStatus("Done")
                                                                changetype = "status"
                                                                changename = "Done"
                                                                submitchange("tStatus", 6)
                                                            }}>Done</DropdownItem>
                                                        </DropdownItemGroup>
                                                    </DropdownMenu>
                                                </li>
                                            )
                                        }
                                        <li style={{ marginRight: "20px" }}><DropdownMenu trigger={sprintname}>

                                            <DropdownItemGroup >
                                                <div style={{
                                                }}>
                                                    {
                                                        sprintdatachecked &&
                                                        (sprintdatachecked.map((value: any, index: any) => (
                                                            <DropdownItem key={index} onClick={
                                                                () => {
                                                                    changetype = "Sprint"
                                                                    setsprintname(value.name)
                                                                    changesprintlog = value.name
                                                                    submitchange("sprintIds", [value.id])
                                                                }

                                                            }>{value.name}</DropdownItem>
                                                        )))
                                                    }
                                                </div>
                                            </DropdownItemGroup>
                                        </DropdownMenu>
                                        </li>
                                        <li style={{ marginRight: "20px" }}>

                                            <div>
                                                <DropdownMenu trigger="Label" >
                                                    <DropdownItemCheckboxGroup id="actions">
                                                        {
                                                            (labeldata.map((value: any, index: any) => (

                                                                labelidarray.includes(value.id) ?
                                                                    (<DropdownItemCheckbox key={index} onClick={() => {
                                                                        changetype = "Label"
                                                                        if (labelidarray.includes(value.id)) {
                                                                            labelidarray = labelidarray.filter(function (item: any,) {
                                                                                return item != value.id;
                                                                            });
                                                                            if (labelidarrayname.includes(value.name)) {
                                                                                labelidarrayname = labelidarrayname.filter(function (item: any,) {
                                                                                    return item != value.name;
                                                                                });
                                                                            }
                                                                        }
                                                                        else {
                                                                            labelidarray.push(value.id)
                                                                            labelidarrayname.push(value.name)
                                                                        }

                                                                        onblurfunctioncall2()

                                                                    }} id={value.id} defaultSelected><PersonIcon label='person' primaryColor="#6456B2" />{value.name}</DropdownItemCheckbox>
                                                                    )
                                                                    :
                                                                    (<DropdownItemCheckbox key={index} onClick={() => {
                                                                        changetype = "Label"
                                                                        if (labelidarray.includes(value.id)) {

                                                                            labelidarray = labelidarray.filter(function (item: any,) {
                                                                                return item != value.id;
                                                                            });

                                                                            if (labelidarrayname.includes(value.name)) {
                                                                                labelidarrayname = labelidarrayname.filter(function (item: any,) {
                                                                                    return item != value.name;
                                                                                });
                                                                            }
                                                                        }
                                                                        else {
                                                                            labelidarray.push(value.id)
                                                                            labelidarrayname.push(value.name)
                                                                        }
                                                                        onblurfunctioncall2()

                                                                    }} id={value.id} ><PersonIcon label='person' primaryColor="#6456B2" />{value.name}</DropdownItemCheckbox>

                                                                    ))))
                                                        }

                                                    </DropdownItemCheckboxGroup>
                                                </DropdownMenu>
                                            </div>
                                        </li>

                                        <li style={{ marginRight: "20px" }}><DropdownMenu trigger={myPriority} >
                                            <DropdownItemGroup >
                                                <DropdownItem onClick={() => {

                                                    changetype = "Priority"
                                                    changeprioritylog = "Low"
                                                    MyPriority("Low")
                                                    submitchange("priority", 0)

                                                }}><div style={{ display: "flex" }}><img src={process.env.PUBLIC_URL + "/low_priority.svg"} /><div style={{ marginLeft: 8 }}> Low</div></div>  </DropdownItem>
                                                <DropdownItem onClick={() => {

                                                    changetype = "Priority"
                                                    changeprioritylog = "Medium"
                                                    MyPriority("Medium")
                                                    submitchange("priority", 1)

                                                }}><div style={{ display: "flex" }}><img src={process.env.PUBLIC_URL + "/medium_priority.svg"} /><div style={{ marginLeft: 8 }}> Medium</div></div>  </DropdownItem>
                                                <DropdownItem onClick={() => {

                                                    changetype = "Priority"
                                                    changeprioritylog = "High"
                                                    MyPriority("High")
                                                    submitchange("priority", 2)

                                                }}> <div style={{ display: "flex" }}><img src={process.env.PUBLIC_URL + "/high_priority.svg"} /><div style={{ marginLeft: 8 }}> High</div></div></DropdownItem>
                                            </DropdownItemGroup>
                                        </DropdownMenu></li>


                                        <li style={{ marginRight: "20px" }}>

                                            <div>
                                                <DropdownMenu trigger="Assignee" >

                                                    <DropdownItemCheckboxGroup id="actions">

                                                        {
                                                            (userdata.map((value: any, index: any) => (

                                                                assigneeidarray.includes(value.id) ?
                                                                    (<DropdownItemCheckbox key={index} onClick={() => {
                                                                        changetype = "Assignee"

                                                                        if (assigneeidarray.includes(value.id)) {
                                                                            assigneeidarray = assigneeidarray.filter(function (item: any,) {
                                                                                return item != value.id;
                                                                            });
                                                                            if (assigneeidarrayname.includes(value.name)) {
                                                                                assigneeidarrayname = assigneeidarrayname.filter(function (item: any,) {
                                                                                    return item != value.name;
                                                                                });
                                                                            }
                                                                        }
                                                                        else {
                                                                            assigneeidarray.push(value.id)
                                                                            assigneeidarrayname.push(value.name)
                                                                        }

                                                                        onblurfunctioncall()

                                                                    }} id={value.id} defaultSelected><PersonIcon label='person' primaryColor="#6456B2" />{value.name}</DropdownItemCheckbox>
                                                                    )
                                                                    :
                                                                    (<DropdownItemCheckbox key={index} onClick={() => {
                                                                        changetype = "Assignee"
                                                                        if (assigneeidarray.includes(value.id)) {

                                                                            assigneeidarray = assigneeidarray.filter(function (item: any,) {
                                                                                return item != value.id;
                                                                            });

                                                                            if (assigneeidarrayname.includes(value.name)) {
                                                                                assigneeidarrayname = assigneeidarrayname.filter(function (item: any,) {
                                                                                    return item != value.name;
                                                                                });
                                                                            }
                                                                        }
                                                                        else {
                                                                            assigneeidarray.push(value.id)
                                                                            assigneeidarrayname.push(value.name)
                                                                        }
                                                                        onblurfunctioncall()

                                                                    }} id={value.id} ><PersonIcon label='person' primaryColor="#6456B2" />{value.name}</DropdownItemCheckbox>

                                                                    ))))
                                                        }

                                                    </DropdownItemCheckboxGroup>

                                                </DropdownMenu>
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                            <div style={{ paddingLeft: "30px", marginTop: "10px", marginRight: 25 }}>

                                <Textfield
                                    appearance="subtle"
                                    placeholder='enter title'
                                    style={{ fontSize: "27px", fontWeight: "600" }}
                                    label="None"
                                    onChange={(e: any) => setchangetitle(e.target.value)}
                                    onFocus={() => {
                                        setfocustype("Title")
                                    }}
                                    onBlur={
                                        () => {
                                            changetype = "Title"
                                            submitchange("title", changetitle)
                                        }
                                    }
                                    defaultValue={singletask?.title}

                                />

                                <Textfield
                                    appearance="subtle"
                                    placeholder="enter description"
                                    label="None"
                                    onChange={(e: any) => setchangedescription(e.target.value)}
                                    onFocus={() => {
                                        setfocustype("Description")
                                    }}
                                    onBlur={
                                        () => {
                                            changetype = "Description"
                                            submitchange("description", changedescription)
                                        }
                                    }
                                    defaultValue={singletask?.description}

                                />
                                <br />
                                <div style={{ paddingBottom: "10px" }}><b >Attachment</b></div>
                                <Avatar
                                    src="https://pbs.twimg.com/profile_images/803832195970433027/aaoG6PJI_400x400.jpg"
                                    name="Scott Farquhar" appearance="square" size="large" />
                                <br />
                                <b style={{ paddingTop: "10px", paddingBottom: "20px" }}>Activity</b>
                                <div style={{
                                    marginTop: 10
                                }}>
                                    <Tabs id="custom-tabs" defaultSelected={1}>
                                        <TabList>
                                            <div onClick={() => {
                                                setlogdata([])
                                                setshouldrender(true)
                                                setlogtype(0)
                                            }}>
                                                <div style={{
                                                    display: "flex"
                                                }}>
                                                    <CustomTab label="All" /><div style={{
                                                        height: 2,
                                                        width: 2
                                                    }}><Badge>{8}</Badge></div></div>
                                            </div>
                                            <div onClick={() => {
                                                setlogdata([])
                                                setshouldrender(true)
                                                setlogtype(1)
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    marginRight: 5
                                                }}>
                                                    <CustomTab label="Comment" /><div style={{
                                                        height: 2,
                                                        width: 2,
                                                        margin: 0,
                                                        padding: 0,
                                                        marginTop: 10
                                                    }}><Badge>{8}</Badge></div></div>
                                            </div>
                                            <div onClick={() => {
                                                setlogdata([])
                                                setshouldrender(true)
                                                setlogtype(2)
                                            }}>
                                                <div style={{
                                                    display: "flex"
                                                }}>
                                                    <CustomTab label="History" /><div style={{
                                                        height: 2,
                                                        width: 2
                                                    }}><Badge>{8}</Badge></div></div>
                                            </div>
                                        </TabList>

                                        <TabPanel>
                                            <Panel><div style={{
                                                height: "275px",
                                                overflowY: `${isloading ? "hidden" : "scroll"}`
                                            }}>

                                                {
                                                    isloading ?
                                                        (
                                                            <Spinner />) :

                                                        (logdata.map((value: any, index: any) => (

                                                            <div key={index} style={{
                                                                margin: 8,
                                                                marginBottom: 20
                                                            }}>

                                                                {

                                                                    value.from === "" && value.to === "" ?
                                                                        (
                                                                            <div style={{
                                                                                fontSize: 14
                                                                            }}>
                                                                                <p style={{
                                                                                    fontSize: 14,
                                                                                    marginBottom: 3,
                                                                                    color: "#091e42",
                                                                                }}>{<><b>{value.assigneeName}</b>{` added a `}<b>{`comment `}</b>{formatDatedatetimeyear(value.cDate)}</>}</p>
                                                                                <p style={{
                                                                                    fontSize: 14,
                                                                                    color: "#6c798e",
                                                                                    margin: 0
                                                                                }}>
                                                                                    {/* {`${value.from} -> ${value.to}`} */}

                                                                                    {
                                                                                        value.remarks
                                                                                    }

                                                                                </p>
                                                                            </div>
                                                                        ) :

                                                                        (<>
                                                                            <p style={{
                                                                                fontSize: 14,
                                                                                marginBottom: 3,
                                                                                color: "#091e42",
                                                                            }}>{<><b>{`${value.remarks.split(" ")[0]} ${value.remarks.split(" ")[1]}`}</b>{` ${value.remarks.split(" ")[2]} `}<b>{value.remarks.split(" ")[3]}</b></>} {formatDatedatetimeyear(value.cDate)}</p>
                                                                            <p style={{
                                                                                fontSize: 14,
                                                                                color: "#6c798e",
                                                                                margin: 0
                                                                            }}>
                                                                                {/* {`${value.from} -> ${value.to}`} */}

                                                                                {
                                                                                    (value.cngType === 3 || value.cngType === 2) &&
                                                                                    (<>{value.from ? value.from : "null"} {`->`} {value.to ? value.to : "null"}
                                                                                    </>)
                                                                                }

                                                                            </p>
                                                                        </>)

                                                                }
                                                            </div>

                                                        )))
                                                }

                                            </div>  </Panel>
                                        </TabPanel>

                                        <TabPanel>
                                            <Panel><div style={{
                                                height: "267px",
                                                fontSize: 14,
                                                marginTop: 8,
                                                overflowY: `${isloading ? "hidden" : "scroll"}`
                                            }}>

                                                {
                                                    isloading ?
                                                        (
                                                            <Spinner />) :

                                                        (logdata.map((value: any, index: any) => (

                                                            <div style={{
                                                                marginBottom: 6
                                                            }}>
                                                                <Comment
                                                                    avatar={<Avatar name="Scott Farquhar" src="" />}
                                                                    author={<CommentAuthor>{value.assigneeName}</CommentAuthor>}
                                                                    type="author"
                                                                    time={<CommentTime>{formatDatedatetimeyear(value.cDate)}</CommentTime>}
                                                                    content={
                                                                        <p style={{
                                                                            margin: 0,
                                                                            padding: 0
                                                                        }}>
                                                                            {value.remarks}
                                                                        </p>
                                                                    }
                                                                />
                                                            </div>
                                                        )))
                                                }

                                            </div>  </Panel>
                                        </TabPanel>


                                        <TabPanel>
                                            <Panel><div style={{
                                                height: "275px",
                                                overflowY: `${isloading ? "hidden" : "scroll"}`
                                            }}>

                                                {
                                                    isloading ?
                                                        (
                                                            <Spinner />) :

                                                        (

                                                            logdata.map((value: any, index: any) => (

                                                                <div key={index} style={{
                                                                    margin: 8,
                                                                    marginBottom: 20
                                                                }}>
                                                                    <p style={{
                                                                        fontSize: 14,
                                                                        marginBottom: 3,
                                                                        color: "#091e42",
                                                                    }}>{<><b>{`${value.remarks.split(" ")[0]} ${value.remarks.split(" ")[1]}`}</b>{` ${value.remarks.split(" ")[2]} `}<b>{value.remarks.split(" ")[3]}</b></>} {formatDatedatetimeyear(value.cDate)}</p>
                                                                    <p style={{
                                                                        fontSize: 14,
                                                                        color: "#6c798e",
                                                                        margin: 0
                                                                    }}>
                                                                        {/* {`${value.from} -> ${value.to}`} */}

                                                                        {
                                                                            (value.cngType === 3 || value.cngType === 2) &&
                                                                            (<>{value.from ? value.from : "null"} {`->`} {value.to ? value.to : "null"}
                                                                            </>)
                                                                        }

                                                                    </p>
                                                                </div>

                                                            ))

                                                        )
                                                }

                                            </div>  </Panel>
                                        </TabPanel>
                                    </Tabs>
                                </div>

                                <hr style={{
                                    opacity: 0.3,
                                    height: 2, backgroundColor: "#ebecf0"
                                }} />
                            </div>

                        </ModalBody>

                        <div style={{ display: "flex", justifyContent: "space-between", paddingBottom: "20px", paddingTop: "5px" }}>
                            <div style={{ paddingLeft: "40px" }}>
                                <Textfield
                                    appearance="none"
                                    label="Subtle"
                                    autoFocus
                                    value={comment}
                                    placeholder="Add a comment.."
                                    onChange={(e: any) => setcomment(e.target.value)}
                                />
                            </div>

                            <div style={{ paddingLeft: "10px", display: "flex", paddingRight: "10px" }}>

                                <Button style={{ marginTop: "10px" }} appearance="primary" onClick={handlecomment} >Comment</Button>
                            </div>
                        </div>

                    </Modal>
                )}
            </ModalTransition>
        </div>
    );
}