import { useEffect, useState, createContext, useRef } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Navbar from '../Components/Navbar/Navbar'
import "./Headerroutes.css"

const UserContext = createContext("")
export const NotifyContext = createContext<any>({});
function Headerroutes(props: any) {

    const box = useRef(null);
    const openModal = (type: any) => {
        setModelType(type)
        setIsOpen(true);
    }
    const [isOpen, setIsOpen] = useState(false);
    const [modelType, setModelType] = useState("")
    const [sidebar, setSidebar] = useState(false);
    const [removeimagestate, setremoveimagestate] = useState(false)

    useEffect(() => {
        setSidebar(false)
        localStorage.setItem("page", props.page)
    }, [props])

    return (
        <>
            {
                localStorage.getItem("logindetail") != null ?
                    <>
                        <Navbar />
                        <div onClick={() => setSidebar(false)}>
                            <Outlet />
                        </div>
                    </>

                    : <Navigate to="/" replace />
            }


        </>
    );
}

export default Headerroutes;
export { UserContext }