import { ApiUtility } from "./ApiUtility";


class HTTPSCalls {

    static ENDPOINTS = {

        milestone: {
            getAll: "v1/api/Milestone/GetList",
            add: "v1/api/Milestone/Add",
            edit: "v1/api/Milestone/Edit",
            approve: "v1/api/Milestone/Approve",
            done: "v1/api/Milestone/Done",
            get: "v1/api/Milestone/Get",
            addLog: "v1/api/Milestone/AddLog",
            attachTask: "v1/api/Milestone/Attach",
            getLog: "v1/api/Milestone/GetLog",
            attachedTask: "v1/api/Milestone/AttachTask",
            addDuration: "v1/api/Milestone/AddDuration",
            getWithDuration: "v1/api/Milestone/GetListWithDurationTotal"
        },
        taskreport: {
            getAll: "v1/api/TaskReport/GetDoneTaskDuration",
            getWeeklyDoneTask: "v1/api/TaskReport/GetWeakWiseDoneTask"
        },
        login: {
            JWTauth: "Account/Login"   
        },
        sprintreport: {
            getAll: "v1/api/SprintReport/GetSprintWiseStatusRecords"
        },
        admin: {
            getAll: "v1/api/Admin/projectswithpercentagedonetask/projects",
            gettAllUsers:"v1/api/Admin/userswithpercentagedonetask/users",
            getAllDueMIlestone: "v1/api/Admin/expiredmilestones",
            getAllDueTasks:"v1/api/Admin/expiredtasks"
        },
        project: {
            get: "v1/api/Project?start=0&length=3000",
            getAll: "v1/api/Project", 
        },
        milestoneStages: {
            getAll: "v1/api/MilestoneStages/GetList",
        },
        task: {
            getbyukey: "v1/api/Task/ukey",
            getAll: "v1/api/Task",
            getAllApp:"v1/api/Task/AppList",
            watching: "v1/api/Task/watching", 
            updateTask: "v1/api/update",
            attachment: "v1/api/Task/attachment",
            multipleTasks: "v1/api/Task/multipletask",
            issues: "v1/api/Task/issues",
            userReportData: "v1/api/Task/UserReportData",
            addDuplicate: "v1/api/duplicate",
            getCurrentUKey: "v1/api/currentukey",
            addFile: "v1/api/Task",
            getAllPending: "v1/api/Task/ProjectPendingTask",
            delay: "v1/api/Task/delay",
            filePreview: "v1/api/Task/filePreview",
            getAttachments: "v1/api/Task/files",
            getAttachmentDownload:"v1/api/Task/Download/"
        },
        sprint: {
            getAll: "v1/api/Sprint"
        },
        issueType: {
            getAll: "v1/api/Label"
        },
        BranchName: {
            getAll: "v1/api/Branch"
        },
        DesignationName: {
            getAll: "v1/api/Designation"
        },
        user: {
            getAll: "v1/api/user/assignee",
            create: "v1/api/user/create",
            resetPassword: "v1/api/user/create",
            getByProject: "v1/api/user/assigneebyproject",
            getAssigneeByTaskCount:"v1/api/user/assigneebytaskcount"
        },
        log: {
            get: "v1/api/Log",
            add: "v1/api/Log/list"
        },
        stage: {
            getAll: "v1/api/MilestoneStages/GetList",
            done: "v1/api/Milestone/StageDone"
        }
    }

    static GET = async (URL_BASE: string, URLParameaters: any = {}) => {
        return await ApiUtility.get(URL_BASE + new URLSearchParams({ ...URLParameaters }).toString());
    }

    static POST = async (URL_BASE: string, requestBody: any, URLParameaters: any = {}) => {
        ;
        return await ApiUtility.post(URL_BASE + new URLSearchParams({ ...URLParameaters }).toString(), requestBody);
    }

}

export default HTTPSCalls