import Avatar from '@atlaskit/avatar';
import Button, { ButtonGroup } from '@atlaskit/button';
import { DatePicker } from '@atlaskit/datetime-picker';
import AttachmentIcon from '@atlaskit/icon/glyph/attachment'
import CheckCircleIcon from '@atlaskit/icon/glyph/check-circle'
import EditorEditIcon from '@atlaskit/icon/glyph/editor/edit'
import ListIcon from '@atlaskit/icon/glyph/list'
import Textfield from '@atlaskit/textfield';
import SunEditor from "suneditor-react";
import AddCircleIcon from '@atlaskit/icon/glyph/add-circle'
import { ActivityType, ApprovalStatus, DurationToText, MilestoneData, MilestoneModel } from '../../Pages/Milestone';
import "../Droppable/Droppable.css"
import EditorBulletListIcon from '@atlaskit/icon/glyph/editor/bullet-list'
import { useState } from 'react';
import MileStaneActivity from "../../Components/Milestone/MileStaneActivity";
import task from '@atlaskit/icon/glyph/task';
import activity from '@atlaskit/icon/glyph/activity';
import { TaskAttachModel } from './TaskAttachModel';
import { IdName } from '../../Constents/Status';
import HTTPSCalls from '../../Services/HTTPCalls';
import { formatDatedatetimeyeargforcreatetask } from '../../utils/DateConvertor';
import LocalStorage from '../../Services/Localstorage';
import { color } from 'echarts';

export interface MilestoneDetail {
    milestone: IdName,
    project: IdName,
    stage: IdName
}

export interface MilestoneCardProp {
    model: MilestoneModel,
    setModel: any,
    setStage: any,
    DoneStage: (milestoneid: string, stageid: string) => void
    milestonedata: MilestoneData,
    setActivityType: any,
    setMilestoneEditId: any,
    RenderButton: (index: number, milestone: any) => JSX.Element | undefined,
    stage:any
}

const MileStoneNewCard = ({ milestonedata, stage , RenderButton, setActivityType, model, setModel, setStage, setMilestoneEditId, DoneStage }: MilestoneCardProp) => {

    const [milestoneData, setMilestoneData] = useState<any>([])

    const [milestoneDetail, setMilestoneDetail] = useState<MilestoneDetail>({
        milestone: { id: "", name: "" },
        project: { id: "", name: "" },
        stage: {id: "", name: ""}
    })

    return (
        <>
            {
                milestonedata?.milestone.map((arrayItems: any, index: number) => (
                    <div style={{ marginRight:"15px" }}>
                        <div className="accordion__section" style={{
                        paddingBottom: 15,
                        fontSize: "10px!important",
                        display: "flex",
                        justifyContent: "space-between",
                        boxShadow: "0px 0px 10px 0px rgba(146, 166, 195, 0.58)",
                        marginLeft: "40px",
                        backgroundColor: "white",
                            marginBottom: "20px",
                            borderRadius:"5px"
                    }}>
                        <div style={{
                            borderRadius: "3px",
                            backgroundColor: "white",
                            margin: "30px",
                            width: "95%"
                            }}>
                                <label className="labelcolor" style={{
                                    fontSize: 14,
                                    fontWeight: "bold"
                                }}>{arrayItems?.project?.name}</label>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "end", marginTop: 20 }}>

                                    <div style={{ display: "flex", alignItems: "center" }}>
                                    <label className="labelcolor" style={{
                                        fontSize: 15
                                    }}>Milestone Title: </label>
                                    <div style={{
                                        backgroundColor: "white",
                                        marginRight: "70px"
                                    }}>

                                        {/*<Textfield
                                            appearance="standard"
                                            required
                                            style={{
                                                backgroundColor: "#ffffff",
                                                width: "610px",
                                                border: "1px solid #DADADA",
                                                borderRadius: "5px"
                                            }}
                                            value={arrayItems.name}
                                            readOnly
                                            autoFocus
                                            isRequired

                                        />*/}
                                            <div style={{marginLeft: 10}}>{arrayItems.name}</div>

                                    </div>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                       {/* <div onClick={() => {
                                            setActivityType(ActivityType.Log)
                                        }} style={{ marginRight: "20px", cursor: "pointer" }}><EditorBulletListIcon label="d" primaryColor="rgb(125, 135, 153)" />Log</div>*/}
                                        <div style={{ cursor: "pointer" }} onClick={() => {
                                            setActivityType(ActivityType.Task)
                                        }}><AttachmentIcon label="d" primaryColor="rgb(125, 135, 153)" />Attached Tasks</div>
                                        <div style={{ cursor: "pointer", marginLeft: 20 }}
                                            onClick={() => {
                                                setMilestoneDetail({ ...milestoneDetail, project: { id: arrayItems?.project?.id, name: arrayItems?.project?.name }, milestone: { id: arrayItems?.id, name: arrayItems?.name } })
                                                setMilestoneData(arrayItems)
                                                setModel({ ...model, attach: true })
                                            }}>
                                            <AddCircleIcon label="attach tasks" primaryColor="rgb(125, 135, 153)" />
                                        </div>
                                    </div>
                                    {/*<div style={{ display: "flex" }}>
                                        <div onClick={() => {
                                            setMilestoneEditId(arrayItems?.id)
                                            setActivityType(ActivityType.Task)
                                        }} style={{ display: 'flex', alignItems: "center", paddingRight: "30px", cursor: "pointer" }}>
                                        <AttachmentIcon label="df" primaryColor="#97A0B0" size="medium" />
                                        <p style={{ fontSize: "14px", color: "#364663", paddingTop: "10px" }}>Task</p>

                                    </div>

                                        <div onClick={() => {
                                            setMilestoneEditId(arrayItems?.id)
                                            setActivityType(ActivityType.Log)
                                        }} style={{ display: 'flex', alignItems: "center", cursor: "pointer" }}>
                                        <ListIcon label="sd" primaryColor="#97A0B0" size="medium" />
                                        <p style={{ fontSize: "14px", color: "#364663", paddingTop: "10px" }}>Activity</p>
                                    </div>
                                </div>*/}
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px", width: "39.8%" }}>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <label className="labelcolor" style={{
                                            fontSize: 14
                                        }}>Due Date</label>
                                        <div style={{
                                            width: 191,
                                            fontSize: 14,
                                            marginLeft: 10
                                        }}>

                                            <DatePicker
                                                name="datetime-picker"
                                                spacing="compact"
                                                dateFormat="DD/MM/YYYY"
                                                appearance="default"
                                                placeholder="DD/MM/YYYY"
                                                isDisabled={true}
                                                value={arrayItems?.due}
                                            />

                                        </div>

                                    </div>
                                    {
                                        LocalStorage.getItem("logindetail", "JSON").role === "Role" &&
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                        <label className="labelcolor" style={{
                                            fontSize: 14
                                        }}>Deadline Date</label>
                                        <div style={{
                                            width: 191,
                                            fontSize: 14,
                                            marginLeft: 10
                                        }}>

                                            <DatePicker
                                                name="datetime-picker"
                                                spacing="compact"
                                                dateFormat="DD/MM/YYYY"
                                                appearance="default"
                                                placeholder="DD/MM/YYYY"
                                                isDisabled={true}
                                                value={arrayItems?.deadline}
                                            />

                                        </div>

                                    </div>
                                    }
                                </div>

                            <div style={{ marginTop: "20px" }}>
                                <label className="labelcolor" style={{
                                    fontSize: 14, color: "#8993A4"
                                }}>Points</label>
                                <div style={{
                                    marginTop: 5
                                }}>
                                    <SunEditor
                                        height="300"
                                            hideToolbar
                                            setOptions={{ resizingBar: false }}
                                            setDefaultStyle='background: #F4F5F780'
                                        setContents={arrayItems?.points?.toString()}
                                        readOnly
                                        />
                                       {/* <div style={{ background: "#F4F5F780", padding: 10 }} dangerouslySetInnerHTML={{ __html: arrayItems?.points }}></div>*/}
                                </div>
                                </div>

                                <div style={{ display: "flex", marginTop: "20px" }}>

                                    {
                                        arrayItems?.progress.map((item: any) => (
                                            <div style={{ marginRight: "30px" }}>
                                                <div style={{ display: "flex", marginRight: 10, alignItems: "center" }}>
                                                <label style={{ fontSize: "14px", color: "#8993A4" }}>{item.stage.name}</label>
                                                       {/* <Textfield
                                                            value={item?.duration ? `${item?.duration?.value} ${DurationToText(item?.duration?.type)}` : ""}
                                                            readOnly
                                                            style={{
                                                                backgroundColor: "#ffffff",
                                                                border: "1px solid #DADADA",
                                                                borderRadius: "5px"
                                                            }}
                                                        />*/}
                                                    <div style={{ marginLeft: 10, fontWeight: 400 }}>{item?.duration ? `${item?.duration?.value} ${DurationToText(item?.duration?.type)}` : ""}</div>
                                                        <div style={{ cursor: "pointer" }}
                                                            onClick={() => {
                                                                setMilestoneEditId(arrayItems?.id)
                                                                setStage({
                                                                    id: item?.stage.id, name: item?.stage.name, value: item?.duration?.value,
                                                                    type: item?.duration?.type, isApproved: arrayItems?.isApproved, startDate: item?.startDate
                                                                })
                                                                setModel({ ...model, duration: true })
                                                            }}>
                                                            <EditorEditIcon primaryColor="#7d8799" label="edit" size="large" />
                                                    </div>
                                                   
                                                        <div style={{ cursor: "pointer" }}
                                                            onClick={() => {
                                                                DoneStage(arrayItems?.id, item?.stage?.id)
                                                            }}>
                                                            {
                                                                item?.startDate &&
                                                                <CheckCircleIcon primaryColor={`${item?.isCompleted ? "#49df73" : "#7d8799"}`} label="done" size="large" />
                                                            }
                                                        </div>
                                                    </div>

                                                    <div style={{ display: "flex", justifyContent: "space-between", color: "#7d8799" }}>
                                                    {
                                                            formatDatedatetimeyeargforcreatetask(item?.startDate, "DD/MM/YY") != "01-01-01" &&
                                                        <div style={{ display: "flex", fontSize: 12, alignItems: "center" }}>
                                                            <p>Start Date: </p>
                                                                    <p>{formatDatedatetimeyeargforcreatetask(item?.startDate, "DD/MM/YY")}</p>
                                                        </div>
                                                    }

                                                    {
                                                            formatDatedatetimeyeargforcreatetask(item?.endDate, "DD/MM/YY") != "01-01-01" &&
                                                        <div style={{ display: "flex", fontSize: 12, alignItems: "center" }}>
                                                            <p style={{ marginLeft:"10px" }}>Completion Date: </p>
                                                                    <p>{formatDatedatetimeyeargforcreatetask(item?.endDate, "DD/MM/YY")}</p>
                                                        </div>
                                                    }
                                                        </div>
                                            </div>
                                        ))
                                    }

                                </div>
                                <div style={{ marginTop: "20px", fontFamily: "lato", display: "flex", justifyContent: "end" }}>
                                    {
                                        RenderButton(index, arrayItems)
                                    }
                            </div>
                        </div>
                            {
                                model.attach &&
                                <TaskAttachModel model={model} setModel={setModel} milestoneDetail={milestoneDetail} milestoneData={milestoneData}  />
                            }
                        </div>
                    </div>
                ))
            }
        </>
    )
}

export default MileStoneNewCard;