import { CheckboxSelect } from '@atlaskit/select';
import Textfield from '@atlaskit/textfield';
import Select from "@atlaskit/select";
import Button from '@atlaskit/button/standard-button';
import { userType } from '../../Pages/IssuesPage';
import { useApp } from '../../Contexts/AppContext';
import HTTPSCalls from '../../Services/HTTPCalls';
import { useEffect, useState } from 'react';
import "./issueFilter.css"
import useWindowSize from '../../Hooks/useWindowSize';


export const IssueFilter = ({ ReporterCall, setFormData }: any) => {
    const { width, height } = useWindowSize();
    const { projects, user } = useApp();
    const [users, setUsers] = useState<any>([]);
    const getUsers = async () => {
        let res = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.user.getAll + "?", { isAll: true })
        if (res.result) setUsers(res.result?.map((item: any) => ({ label: item?.name, value: item?.id, ...item })));
    }

    useEffect(() => {
        getUsers()
    }, [])

    return (
        <div style={{ display: "flex", alignItems: "center", height: 100, backgroundColor: "#f6f8fc", paddingLeft: "30px" }}>

            <div style={{
                margin: 30,
                marginLeft: `${width < 1560 ? "20px" : "30px"}`,
                marginRight: `${width < 1560 ? "20px" : "30px"}`
            }}>

                <CheckboxSelect
                    inputId="checkbox-select-example"
                    className="checkbox-select issuefilter-project"
                    classNamePrefix="select"
                    options={[...projects]}
                    appearance="none"
                    spacing="compact"
                    controlShouldRenderValue={false}
                    placeholder="Select Project"
                    onChange={(value: any) => {
                        setFormData((prev: any) => ({ ...prev, project: value.map((item: any) => item.value) }))
                    }}
                    styles={{
                        control: (base) => {

                            return {
                                ...base,
                                backgroundColor: "white",
                                height: 35,
                                fontSize: 14,
                                border: "none",
                                minHeight: 35,
                                width: `${width < 540 ? "150px" : "200px"}`
                            }

                        },
                        singleValue: (base) => ({
                            ...base,
                            top: "46%"
                        }),
                        indicatorsContainer: (base: any) => ({
                            ...base,
                            height: "35px"
                        }),
                        input: (base: any) => ({
                            ...base,
                            margin: 0,
                            paddingTop: 0
                        })
                    }}
                />
            </div>

            <div style={{
                margin: 30,
                marginLeft: `${width < 1560 ? "20px" : "30px"}`,
                marginRight: `${width < 1560 ? "20px" : "30px"}`,
                display: `${width < 1250 ? "none" : "block"}`
            }}>
                <CheckboxSelect
                    inputId="checkbox-select-example"
                    className="checkbox-select issuefilter-status"
                    classNamePrefix="select"
                    options={[
                        { label: "Todo", value: 1 },
                        { label: "Need To Discuss", value: 2 },
                        { label: "InProgress", value: 3 },
                        { label: "UAT", value: 4 },
                        { label: "Developed", value: 7 },
                        { label: "Testing", value: 5 },
                        { label: "Done", value: 6 }
                    ]}
                    appearance="none"
                    spacing="compact"
                    controlShouldRenderValue={false}
                    placeholder="Select status"
                    defaultValue={[
                        { label: "Todo", value: 1 },
                        { label: "Need To Discuss", value: 2 },
                        { label: "InProgress", value: 3 },
                        { label: "UAT", value: 4 },
                        { label: "Developed", value: 7 },
                        { label: "Testing", value: 5 }
                    ]}
                    onChange={(value: any) => {
                        setFormData((prev: any) => ({ ...prev, status: value.map((item: any) => item.value) }))
                    }}
                    styles={{
                        control: (base) => {

                            return {
                                ...base,
                                backgroundColor: "white",
                                height: 35,
                                fontSize: 14,
                                border: "none",
                                minHeight: 35,
                                width: "200px"
                            }

                        },
                        singleValue: (base) => ({
                            ...base,
                            top: "46%"
                        }),
                        indicatorsContainer: (base: any) => ({
                            ...base,
                            height: "35px"
                        }),
                        input: (base: any) => ({
                            ...base,
                            margin: 0,
                            paddingTop: 0
                        })
                    }}
                />
            </div>

            <div style={{
                margin: 30,
                marginLeft: 30,
                marginRight: 30,
                display: `${width < 545 ? "none" : "block"}`
            }}>
                <Textfield className="issuefilter-text" style={{ backgroundColor: "white", width: `${width < 1560 ? "130px" : "200px"}` }}
                    placeholder='Search'
                    onChange={(e: any) => {
                        setFormData((prev: any) => ({ ...prev, SearchText: e?.target?.value }))
                    }}
                />
            </div>

            <div style={{
                margin: 30,
                marginLeft: `${width < 1560 ? "20px" : "30px"}`,
                marginRight: `${width < 1560 ? "20px" : "30px"}`,
                display: `${width < 1250 ? "none" : "block"}`
            }}>
                <Select
                    inputId="checkbox-select-example"
                    className="checkbox-select issuefilter-userType"   
                    spacing="compact"
                    options={[
                        { label: 'All', value: userType.All },
                        { label: 'Assignee', value: userType.Assignee },
                        { label: 'AssignedBy', value: userType.AssignedBy },
                        { label: 'Reporter', value: userType.Reporter },
                    ]}
                    onChange={(value: any) => {
                        setFormData((prev: any) => ({ ...prev, userFilter: { userType: value?.value, id: prev.userFilter.id } }))
                    }}
                    placeholder="Select user type"
                    styles={{
                        control: (base) => {

                            return {
                                ...base,
                                backgroundColor: "white",
                                height: 35,
                                fontSize: 14,
                                border: "none",
                                minHeight: 35,
                                width: `${width < 1560 ? "150px" : "200px"}`

                            }

                        },
                        singleValue: (base) => ({
                            ...base,
                            top: "46%"
                        }),
                        indicatorsContainer: (base: any) => ({
                            ...base,
                            height: "35px"
                        }),
                        input: (base: any) => ({
                            ...base,
                            margin: 0,
                            paddingTop: 0
                        })
                    }}
                />

            </div>

            <div style={{
                margin: 30,
                marginLeft: `${width < 1560 ? "20px" : "30px"}`,
                marginRight: `${width < 1560 ? "20px" : "30px"}`,
                display: `${width < 1250 ? "none" : "block"}`
            }}>
                <Select
                    inputId="checkbox-select-example"
                    className="checkbox-select issuefilter-user"
                    spacing="compact"
                    options={[...users]}
                    onChange={(value: any) => {
                        setFormData((prev: any) => ({ ...prev, userFilter: { id: value?.value, userType: prev.userFilter.userType } }))
                    }}
                    placeholder="Select user"
                    styles={{
                        control: (base) => {

                            return {
                                ...base,
                                backgroundColor: "white",
                                height: 35,
                                fontSize: 14,
                                border: "none",
                                minHeight: 35,
                                width: "200px",
                                zIndex: 3
                            }

                        },
                        singleValue: (base) => ({
                            ...base,
                            top: "46%"
                        }),
                        indicatorsContainer: (base: any) => ({
                            ...base,
                            height: "35px"
                        }),
                        input: (base: any) => ({
                            ...base,
                            margin: 0,
                            paddingTop: 0
                        })
                    }}
                />
            </div>

            <div style={{
                margin: 30,
                marginLeft: `${width < 1560 ? "20px" : "30px"}`,
                marginRight: `${width < 1560 ? "20px" : "30px"}`,
                display: `${width < 1250 ? "none" : "block"}`
            }}>
                <Select
                    inputId="checkbox-select-example"
                    className="checkbox-select issuefilter-priority"
                    spacing="compact"
                    options={[
                        { label: "All", value: -1 },
                        { label: "High", value: 2 },
                        { label: "Medium", value: 1 },
                        { label: "Low", value: 0 }]}
                    onChange={(item: any) => {
                        setFormData((prev: any) => ({ ...prev, priority: item?.value }))
                    }}
                    defaultValue={{ label: "All", value: -1 }}
                    placeholder="Priority"
                    styles={{
                        control: (base) => {

                            return {
                                ...base,
                                backgroundColor: "white",
                                height: 35,
                                fontSize: 14,
                                border: "none",
                                minHeight: 35,
                                width: `${width < 1560 ? "120px" : "200px"}`
                            }

                        },
                        singleValue: (base) => ({
                            ...base,
                            top: "46%"
                        }),
                        indicatorsContainer: (base: any) => ({
                            ...base,
                            height: "35px"
                        }),
                        input: (base: any) => ({
                            ...base,
                            margin: 0,
                            paddingTop: 0
                        })
                    }}
                />
            </div>

            <div style={{ width: "100px", margin: 30 }}>
                <Button type="submit" appearance="primary"
                    onClick={() => ReporterCall(true)}
                    style={{ backgroundColor: "#0E51D3" }}
                >Search</Button>
            </div>
        </div>
    )
}