import { ReactNode } from "react"

type NoDataProps = {
    message: string | ReactNode,
    instruction?: string | ReactNode,
    customImage?: string,
    shouldFitContainer?: boolean
}

const NoData = ({ message, instruction, customImage = "", shouldFitContainer = false }: NoDataProps) => {

    return (
        <div style={shouldFitContainer ? { width: "100%" } : {}}>
                <div style={{
                    display: "flex",
                    justifyContent: "center"
            }}>
                <img src={customImage === "" ? process.env.PUBLIC_URL + "/noTask.svg" : customImage} />
                </div>
            <div style={{ textAlign: "center" }}>
                <h3 style={{ margin: 0, fontFamily: "lato", fontSize: 20, color: "rgb(72 81 110)" }}>{message}</h3>
                <p style={{ marginTop: 4, fontFamily: "lato", fontSize: 14, color: "rgb(72 81 110)" }}>{instruction}</p>
                </div>
            </div>
    )
}

export default NoData