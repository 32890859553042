

function formatDate(datedata: any) {

    let now = new Date(datedata);

    let monthsOfYear = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
    ];

    let daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
    ];



    let date = now.getDate();
    let month = monthsOfYear[now.getMonth()];
    let mseconds = now.getMilliseconds();
    let day = daysOfWeek[now.getDay()];
    let year = now.getFullYear();
    let hours = now.getHours();
    let minutes = now.getMinutes();


    return `${month} ${date}`
}



const nooncalculate = (hour: number) => {
    if (hour === 0) {
        return 12
    }
    else {
        return hour
    }
}



const formatDatedatetimeyear = (datedata: string) => {
    let now = new Date(datedata);

    let monthsOfYear = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
    ];

    let daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
    ];



    let date = String(now.getDate()).padStart(2, '0');;
    let month = monthsOfYear[now.getMonth()];
    let mseconds = now.getMilliseconds();
    let day = daysOfWeek[now.getDay()];
    let year = now.getFullYear();
    let hours = now.getHours() % 12 || 12;
    let minutes = now.getMinutes();

    var amOrPm = (now.getHours() < 12) ? "AM" : "PM";
    var hour = (now.getHours() < 12) ? now.getHours() : now.getHours() - 12;

    hour = nooncalculate(hour)

    return `${month} ${date}, ${year} at ${hour.toString().length === 1 ? "0" + hour.toString() : hour}:${minutes.toString().length === 1 ? "0" + minutes.toString() : minutes} ${amOrPm}`
}


export function convertTo12HourFormat(time24: string) {
    // Parse the time string and extract hours and minutes
    const [hours, minutes] = time24.split(":").map(Number);

    // Determine if it's AM or PM
    const period = hours >= 12 ? "pm" : "am";

    // Convert to 12-hour format
    const hours12 = (hours % 12) || 12; // 12 should be displayed as 12, not 0

    // Format the time as "hh:mm am/pm"
    const time12 = `${hours12}:${minutes.toString().padStart(2, "0")} ${period}`;

    return time12;
}




const formatDatedatetimeyeargforcreatetask = (datedata: string, show: string) => {
    let now = new Date(datedata);

    let monthsOfYear = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
    ];

    let daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
    ];



    let date = now.getDate();
    let month = monthsOfYear[now.getMonth()];
    let monthindex = now.getMonth()
    let mseconds = now.getMilliseconds();
    let day = daysOfWeek[now.getDay()];
    let year = now.getFullYear();
    let hours = now.getHours() % 12 || 12;
    let minutes = now.getMinutes();

    var amOrPm = (now.getHours() < 12) ? "AM" : "PM";
    var hour = (now.getHours() < 12) ? now.getHours() : now.getHours() - 12;



    if (show === "DD/MM/YY") {
        /*return `${monthindex + 1}-${date}-${year}`*/
        /*hour.toString().length === 1 ? "0" + hour.toString() : hour*/
        return `${(monthindex + 1).toString().length === 1 ? "0" + (monthindex + 1).toString() : (monthindex + 1)}-${date.toString().length === 1 ? "0" + date.toString() : date}-${year.toString().length === 1 ? "0" + year.toString() : year}`
    }


    else if (show === "invert") {
        return `${date.toString().length === 1 ? "0" + date.toString() : date} ${month.toString().length === 1 ? "0" + month.toString() : month} ${year.toString().length === 1 ? "0" + year.toString() : year}`
    }

    else if (show === "createtask") {
        return `${year.toString().length === 1 ? "0" + year.toString() : year}-${(monthindex + 1).toString().length === 1 ? "0" + (monthindex + 1).toString() : (monthindex + 1)}-${date.toString().length === 1 ? "0" + date.toString() : date}`
    }

    else {
        return `${date.toString().length === 1 ? "0" + date.toString() : date}/${(monthindex + 1).toString().length === 1 ? "0" + (monthindex + 1).toString() : (monthindex + 1)}/${year.toString().length === 1 ? "0" + year.toString() : year}`
    }


}



const formatDatedaytomonth = (datedata: any) => {
    let now = new Date(datedata);

    let monthsOfYear = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
    ];

    let daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
    ];



    let date = now.getDate();
    let month = monthsOfYear[now.getMonth()];
    let mseconds = now.getMilliseconds();
    let day = daysOfWeek[now.getDay()];
    let year = now.getFullYear();
    let hours = now.getHours();
    let minutes = now.getMinutes();


    return `${date.toString().length === 1 ? "0" + date.toString() : date} ${month.toString().length === 1 ? "0" + month.toString() : month}`
}



const formatDateexpiredon = (datedata: any) => {
    let now = new Date(datedata);

    let monthsOfYear = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
    ];

    let daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
    ];



    let date = now.getDate();
    let month = monthsOfYear[now.getMonth()];
    let mseconds = now.getMilliseconds();
    let day = daysOfWeek[now.getDay()];
    let year = now.getFullYear();
    let hours = now.getHours();
    let minutes = now.getMinutes();


    return `${date.toString().length === 1 ? "0" + date.toString() : date} ${month.toString().length === 1 ? "0" + month.toString() : month} ${year.toString().length === 1 ? "0" + year.toString() : year}`
}



const currentyear = () => {

    let currentdate = new Date()

    let year = currentdate.getFullYear();


    return year
}

export default formatDate

export { formatDatedaytomonth, formatDatedatetimeyear, formatDatedatetimeyeargforcreatetask, formatDateexpiredon, currentyear }

