import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalTitle,
    ModalTransition,
} from '@atlaskit/modal-dialog';
import Button from '@atlaskit/button/standard-button';
import ConfirmModal from "../../../../Interfaces/ModalInterface";

export const ConfirmAlert = ({ Reject, heading = "Are you sure?", message, callBack = () => {} }: ConfirmModal) => {

    function HandleConfirm() {
        callBack()
        Reject()
    }

    return (
        <ModalTransition>
            <Modal width="small" onClose={Reject}>
                    <ModalHeader>
                    <ModalTitle>{heading}</ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                            <p style={{ paddingLeft: 25, paddingRight: 25, margin: 0, fontSize: 14 }}>{message}</p>
                    </ModalBody>
                    <ModalFooter>
                    <Button appearance="subtle" onClick={Reject}>
                            Cancel
                        </Button>
                    <Button appearance="primary" style={{ height: "35px", display: "flex", alignItems: "center", backgroundColor: "#3177ff", fontSize: "14px!important" }}
                        onClick={HandleConfirm}>
                            Confirm
                        </Button>
                    </ModalFooter>
                </Modal>
        </ModalTransition>
        )
}