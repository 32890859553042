import { ApiUtility } from "../../Services/ApiUtility";

const DISPLAY_MEDIA_OPTIONS = {
    video: {
        cursor: "always"
    },
    audio: false
};

export class ScreenRecorderInit {
    private recordingStream: MediaStream | null = null;
    private recorder: MediaRecorder | null = null;
    private chunks: Blob[] = [];
    private onStopCallback: () => void = () => null;
    private ResetTimer: () => void = () => null;
    private setVedioBlobURL: any;
    private recordedFiles: any;
    private URL: string = "";
    private base64Url: string = ""

    private handleRecordingData = (event: BlobEvent) => {
        this.chunks.push(event.data);
    };

    public convertToBase64(blob: any) {

        return new Promise((resolve, reject) => {

            const reader = new FileReader();
            reader.onloadend = function () {
                const base64String = reader.result;
                resolve(reader.result)
            };
            reader.readAsDataURL(blob);

        })
    
}

    private handleRecordingEnd = async () => {
        const blobType = this.chunks[0].type;

        const completeBlob = new Blob(this.chunks, { type: blobType });

        let result = await completeBlob.text()
        const url = URL.createObjectURL(completeBlob);
        const base64url = await this.convertToBase64(completeBlob);
        this.URL = url;
        this.setVedioBlobURL(url)
        this.ResetTimer()
        this.recordedFiles((prev: any) => [...prev, { url, completeBlob, base64url, name: `recording_${prev.length}` }]);
        this.onStopCallback();
    };

    downloadFile = () => {
        const link = document.createElement("a");
        link.href = this.URL;
        link.download = "RecordedVideo.webm";
    }

    async start(onStopCallback: () => void, setVedioBlobURL: any, startTimer: () => void,
        resetTimer: () => void, setRecordedFiles: any) {
        try {
            this.onStopCallback = onStopCallback;
            this.setVedioBlobURL = setVedioBlobURL;
            this.ResetTimer = resetTimer;
            this.recordedFiles = setRecordedFiles
            // TypeScript doesn't yet have typings for getDisplayMedia :(
            // We have to disable type checking here by casting navigator.mediaDevices to any
            this.recordingStream = (await (navigator.mediaDevices as any).getDisplayMedia(
                DISPLAY_MEDIA_OPTIONS
            )) as MediaStream;

            this.recorder = new MediaRecorder(this.recordingStream, {
                mimeType: "video/webm;codecs=vp8,opus"
            });

            this.recorder.start(100);
            this.recorder.onstart = startTimer;
            this.recorder.ondataavailable = this.handleRecordingData;
            this.recorder.onstop = this.handleRecordingEnd;
            
        } catch (err) {
            console.error("Error: " + err);
        }
    }

    stop() {
        if (!this.recorder) {
            return;
        }

        this.recorder.stop();
    }
}
