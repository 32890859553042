import React, { useContext, useEffect, useRef } from "react";
import { useCallback, useState } from 'react';
import { ErrorMessage } from '@atlaskit/form';
import * as XLSX from 'xlsx';
import TrashIcon from '@atlaskit/icon/glyph/trash'
import AttachmentIcon from '@atlaskit/icon/glyph/attachment'
import TextArea from '@atlaskit/textarea';
import MediaServicesSpreadsheetIcon from '@atlaskit/icon/glyph/media-services/spreadsheet'
import Tooltip from '@atlaskit/tooltip';
import AddIcon from '@atlaskit/icon/glyph/add'
import Form from "@atlaskit/form";
import Select, { CheckboxSelect, components } from '@atlaskit/select';
import SunEditor from "suneditor-react";
import Badge from '@atlaskit/badge';
import AddCircleIcon from '@atlaskit/icon/glyph/add-circle'
import Spinner from '@atlaskit/spinner';
import DownloadIcon from '@atlaskit/icon/glyph/download'
import Button from "@atlaskit/button/standard-button";
import DynamicTable from '@atlaskit/dynamic-table';
import Tabs, { Tab, TabList, TabPanel } from '@atlaskit/tabs';
import WatchIcon from '@atlaskit/icon/glyph/watch'
import ImageUploading, { ImageListType, ImageType } from "react-images-uploading";
import Textfield from '@atlaskit/textfield';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import { Checkbox } from '@atlaskit/checkbox';
import './Taskmodel.css'
import BooksService from '../../Services/Books'
import Modal, {
    ModalBody,
    ModalTransition,
    ModalFooter
} from '@atlaskit/modal-dialog';
import { DatePicker } from '@atlaskit/datetime-picker';
import { randomColorArray } from "../../utils/Randomcolor";
import { formatDatedatetimeyeargforcreatetask } from "../../utils/DateConvertor";
import AlertModal from "../AlertModal/AlertModal";
import { formatBytes, imagePath, priorityconvertor } from "../../utils/Util";
import LocalStorage from "../../Services/Localstorage";
import HTTPSCalls from "../../Services/HTTPCalls";
import { ImagePopup } from "../UI/ImagePopup";
import { ModalTemplate } from "../UI/Modal/Modal";
import NoData from "../UI/NoData";
import { AlertConfig } from "../UI/Alert/AlertConfig";
import { Alert } from "../UI/Alert/Alert";
import { exportToExcel, getAsByteArray } from "../../utils/fileUtils";
import { IApiResponse } from "../../Services/ApiUtility";
import { AlertType, useAlert } from '../../Contexts/AlertContext';
import { useApp } from "../../Contexts/AppContext";
import { Loader } from "../UI/Loader/Loader";
interface Props {
    closeModel: () => void,
    createType: CreateType,
    callback?: () => void,
    configData?: Config,
    datas?: any
}

interface Config {
    parantTaskId: string
}

export interface MultiTaskFormChildTask {
    index: number,
    title: string,
    attachments: ImageListType
}[]
export enum TaskSaveType {
    Save,
    SaveAndNew
}
export enum CreateType {
    Task,
    Child,
    Copy
}

const TaskModel = ({ datas, closeModel, createType, configData = { parantTaskId: "" }, callback = () => { } }: Props) => {


    const [images, setImages] = React.useState([]);
    var today = new Date();

    const { flags,
        isSuccess,
        message,
        addFlag,
        IsSuccess,
        SetAlertMessage,
        handleDismiss } = AlertConfig();

    const { showAlert }: any = useAlert();

    const maxNumber = 69;
    const functionRef = useRef<any>(null)
    const [mytitle, setmytitle] = useState(createType === CreateType.Copy ? datas.title : "")
    const [mydescription, setmydescription] = useState<any>(createType === CreateType.Copy ? datas.description : "")
    const [issueTypeValue, setIssueTypeValue] = useState<any>([])
    const [branchValue, setBranchValue] = useState<any>([])
    const [cardWidth, setcardWidth] = useState(window.innerWidth)
    const [milestoneOptions, setMilestoneOptions] = useState<any>([])
    const [ChildTaskSheetData, setChildTaskSheetData] = useState<ImageListType>([])
    const [childTasks, setChildTasks] = useState<MultiTaskFormChildTask[]>([])
    const [childAttachmentModel, setChildAttachmentModel] = useState<boolean>(false);
    const [resizeChildTaskContent, setResizeChildTaskContent] = useState<Record<string, number>>({})
    const [currentIndex, setCurrentIndex] = useState<number | null>(null)
    const [isExcelModel, setIsExcelModel] = useState<boolean>(false)
    const [stageOptions, setStageOptions] = useState<any>([])
    const [sprintdata, setsprintdata] = useState([])
    const [labeldata, setlabeldata] = useState([])
    const [priority, setpriority] = useState(createType === CreateType.Copy ? datas.priority : 0)
    const [Assignee2, setAssignee2] = useState<any>([])
    const [Assignee, setAssignee] = useState<any>([])
    const [AssigneeId, setAssigneedaID] = useState<any>([])
    const [initialimage, setInitialImage] = useState(datas?.file ? datas.file : [])
    const [savingload, setsavingload] = useState(false)
    const [projectIDs, setprojectIDs] = useState<any>(localStorage.getItem("projectid") ? localStorage.getItem("projectid") : "")
    const [milestoneObject, setMilestoneObject] = useState({})
    const [stageObject, setStageObject] = useState({})
    const [companydata, setcompanydata] = useState<any>([])
    const [branchData, setBranchData] = useState<any>([])
    const [checkablelabelId, setcheckablelabelID] = useState<any>(createType === CreateType.Copy ? [...datas.issueTypeIds()] : [])
    const [checkablebranchId, setcheckablebranchId] = useState<any>(createType === CreateType.Copy ? [...datas.branchIds()] : [])
    const [isOpenconfirm, setIsOpenconfirm] = useState(false);
    const openModalconfirm = useCallback(() => setIsOpenconfirm(true), []);
    const closeModelconfirm = useCallback(() => setIsOpenconfirm(false), []);
    const [statenot, setstatenot] = useState("")
    const [randomcolor, setrandomcolor] = useState<any>([])
    const [assigneevalue, setassigneevalue] = useState<any>([])
    const [reporterValue, setReporterValue] = useState<any>([])
    const [reporterOpt, setReporterOpt] = useState<any>([])
    const [finalimagearray, setfinalimagearray] = useState<any>([])
    const [imagessave, setImagessave] = React.useState<any>([]);
    const [isPopup, setIsPopup] = useState(false)
    const [isCreateBrach, setIsCreateBranch] = useState(false)
    const [branch, setBranch] = useState("");
    const [isBranchSave, setIsBranchSave] = useState(false)
    const [currentUkey, setCurrentUkey] = useState<string>("")
    const { projects, user } = useApp();

    const AddBranch = async () => {
        setIsBranchSave(true)
        try {
            let res = await BooksService.addBranch({
                name: branch,
            })
            if (res) {
                setIsCreateBranch(false)
                showAlert("Branch added successfully", AlertType.SUCCESS)
                functiongetuser2()
            }
            else if (res?.errors) {
                showAlert(res?.message, AlertType.ERROR)
            }
        }
        catch (ex) {

        }
        finally {
            setIsBranchSave(false)
        }
    }

    const [projectDefaultOption, setProjectDefaultOption] = useState<any>(
        (LocalStorage.getItem("projectid", "string") && LocalStorage.getItem("name", "string"))
            ?
            { value: LocalStorage.getItem("projectid", "string"), label: LocalStorage.getItem("name", "string") } : {})

    const [isChecked, setIsChecked] = useState(false);

    const assigntome = useCallback((event: any) => {
        setIsChecked((current) => !current);
        if (event.target.checked) {
            setassigneevalue([{ label: JSON.parse(localStorage.getItem("logindetail") || "{}").name, value: JSON.parse(localStorage.getItem("logindetail") || "{}").id }])
            setAssigneedaID([JSON.parse(localStorage.getItem("logindetail") || "{}").id])
        }
        else {
            setassigneevalue([])
        }
    }, []);
    const substringmethod = (data: string) => {

        if (data?.length > 10) {
            return data.substring(0, 10) + "..."
        }
        else {
            return data
        }
    }

    const handleInputAction = (event: any) => {
        if (event && event.key === 'Tab')
            handleTabPress()
    }

    const handleimagechange = (imageList: any) => {

        let imagarray: any = []
        setImages(imageList)

        for (let img in imageList) {
            imagarray.push({
                name: imageList[img].file.name,
                contentType: imageList[img].file.contenttype,
                path: imageList[img].data_url,
                ExistingType: 1
            })
        }

        setImagessave(imagarray)
        setfinalimagearray([...imagarray])
    }

    const [projectdropdowndata, setprojectdropdowndata] = useState<any>([])
    const [date, setDate] = useState("")

    const Control = ({ children, ...props }: any) => {
        // @ts-ignore
        const { emoji, onEmojiClick } = props.selectProps;
        const style = { cursor: 'pointer' };

        return (
            <components.Control {...props}>
                <span onMouseDown={onEmojiClick} style={style}>
                    {emoji}
                </span>
                {children}
            </components.Control>
        );
    };

    const [errtitle, seterrtitle] = useState(false)
    const [errdesc, seterrdesc] = useState(false)
    const [errassignee, seterrassignee] = useState(false)
    const [errlabel, seterrlabel] = useState(false)

    const ExcelImport = async () => {

        return new Promise((resolve, reject) => {
            let file = ChildTaskSheetData[0]
            if (file) {
                const reader = new FileReader();
                reader.onload = (e: any) => {
                    try {
                        const binaryString = e.target.result;
                        var workbook = XLSX.read(binaryString, {
                            type: 'binary'
                        });
                        var first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
                        var jsonArr = XLSX.utils.sheet_to_json(first_worksheet, { header: 1 });
                        resolve(jsonArr);
                    } catch (error) {
                        reject(error)
                    }
                };
                reader.readAsBinaryString(file.file as unknown as Blob)
            }
            else {
                reject("File not available")
            }
        })
    }

    const HandleExcelImport = async () => {
        let response = await ExcelImport();
        if (response && Array.isArray(response)) {
            let childTaskClone = [...childTasks]
            console.log(childTaskClone)
            response.forEach((ele: any, index: number) => {
                if (index != 0) childTaskClone.push({
                    index: childTaskClone.length,
                    title: ele[ele.length - 1],
                    attachments: []
                })
            });
            setChildTasks(childTaskClone)
            setChildTaskSheetData([])
            setIsExcelModel(false)
        }
    }

    const HandleExcelFormatExport = () => {
        let data = [
            ["S.No", "Title"]
        ]
        exportToExcel(data, "format.xlsx", "defaultSheet")
    }

    const isdiscardalert = () => {
        let data = {
            mytitle,
            mydescription,
            AssigneeId,
            checkablelabelId
        }
        if (validateOnCancel(data)) {
            return true
        }
        else {
            return false

        }
    }

    const editorRef: any = useRef();

    const getSunEditorInstance = (sunEditor: any) => {
        if (!!editorRef) editorRef.current = sunEditor;
    };


    const handleTabPress = () => setChildTasks([...childTasks, { index: childTasks.length, title: "", attachments: [] }])

    const ChildTaskGenerator = () => {

        return (
            <>
                {
                    childTasks.map((item: MultiTaskFormChildTask, index: number) => (

                        <div style={{ marginBottom: 20, display: "flex", alignItems: "center" }}>
                            <p style={{ marginLeft: 0, marginTop: 0, marginBottom: 0, marginRight: 6 }}>{index + 1}</p>

                            {
                                resizeChildTaskContent.hasOwnProperty(String(index)) ?
                                    <TextArea
                                        onBlur={() => {
                                            const resizeObjectClone = Object.assign({}, resizeChildTaskContent);
                                            delete resizeObjectClone[String(index)]
                                            setResizeChildTaskContent(resizeObjectClone)
                                        }
                                        }
                                        appearance="standard"
                                        style={{
                                            backgroundColor: "#f6f8fc",
                                            border: "none"
                                        }}
                                        value={childTasks[index].title}
                                        isRequired
                                        onChange={(e: any) => {
                                            let task = [];
                                            for (let i = 0; i < childTasks.length; i++) {
                                                task[i] = childTasks[i];
                                            }
                                            task[index].title = e.target.value;
                                            setChildTasks(task)
                                        }
                                        }
                                        onKeyDown={handleInputAction}
                                    />
                                    :
                                    <Tooltip position="top" content="double click to expand content">
                                        {(tooltipProps: any) => (
                                            <Textfield {...tooltipProps} shouldFitContainer
                                                onDoubleClick={() => setResizeChildTaskContent((prevState: Record<string, number>) => ({
                                                    ...prevState,
                                                    [String(index)]: index
                                                }))}
                                                appearance="standard"
                                                style={{
                                                    backgroundColor: "#f6f8fc",
                                                    border: "none"
                                                }}
                                                value={childTasks[index].title}
                                                isRequired
                                                onChange={(e: any) => {
                                                    let task = [];
                                                    for (let i = 0; i < childTasks.length; i++) {
                                                        task[i] = childTasks[i];
                                                    }
                                                    task[index].title = e.target.value;
                                                    setChildTasks(task)
                                                }
                                                }
                                                onKeyDown={handleInputAction}
                                            />
                                        )}
                                    </Tooltip>
                            }

                            <div style={{ marginLeft: 5, cursor: "pointer" }}>

                                <ImageUploading
                                    multiple
                                    value={childTasks[index].attachments}
                                    onChange={(imagelist: ImageListType) => {
                                        let task = [];
                                        for (let i = 0; i < childTasks.length; i++) {
                                            task[i] = childTasks[i];
                                        }
                                        task[index].attachments = imagelist
                                        setChildTasks(task)
                                    }}
                                    maxNumber={maxNumber}
                                    allowNonImageType={true}
                                    dataURLKey="data_url"
                                >
                                    {({
                                        imageList,
                                        onImageUpload
                                    }) => (

                                        <div style={{ display: "flex" }}>
                                            <div style={{ marginLeft: 5, cursor: "pointer" }} onClick={() => {
                                                let task = [];
                                                for (let i = 0; i < childTasks.length; i++) {
                                                    task[i] = childTasks[i];
                                                }
                                                task.splice(index, 1);
                                                setChildTasks(task)
                                            }}>
                                                <TrashIcon label="deleterow" size="medium" primaryColor="#95A1AE" />
                                            </div>
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: 5, cursor: "pointer" }} onClick={onImageUpload}>
                                                <AttachmentIcon label="attachment" primaryColor="#95A1AE" />
                                                {
                                                    (item.attachments && item.attachments.length > 0) &&
                                                    <Badge>{item.attachments.length}</Badge>
                                                }
                                            </div>
                                            <div style={{ marginLeft: 10, cursor: "pointer" }} onClick={() => { setCurrentIndex(index); setChildAttachmentModel(true); }}>
                                                <WatchIcon label="display attachment" primaryColor="#95A1AE" />
                                            </div>

                                        </div>
                                    )}
                                </ImageUploading>
                            </div>
                        </div>
                    ))
                }

            </>
        )
    }

    const ChildTaskAttachmentRendrer = () => {

        return (
            <>
                {(currentIndex != null && childTasks[currentIndex]?.attachments?.length > 0) ?
                    <DynamicTable
                        head={{
                            cells: [
                                {
                                    key: 'Name',
                                    content: 'Name',
                                    width: 10
                                },

                                {
                                    key: 'Size',
                                    content: 'Size',
                                    width: 5
                                },

                                {
                                    key: 'Type',
                                    content: 'Type',
                                    width: 5
                                },
                                {
                                    key: '',
                                    content: '',
                                    width: 2
                                }
                            ],
                        }}
                        rows={childTasks[currentIndex].attachments.map((data: ImageType, rowindex: number) => ({
                            key: `row-${rowindex}-${data.id}`,
                            isHighlighted: false,
                            cells: [
                                {
                                    content: (
                                        data?.file?.name
                                    )
                                },
                                {
                                    content: (
                                        formatBytes(data?.file?.size ? data?.file?.size : 0)
                                    )
                                },
                                {
                                    content: (
                                        data?.file?.type
                                    )
                                },
                                {
                                    content: (
                                        <div style={{ cursor: "pointer" }} onClick={() => {
                                            setChildTasks(
                                                childTasks.map((item: MultiTaskFormChildTask) => {
                                                    if (item.index === currentIndex) {
                                                        return {
                                                            index: item.index,
                                                            title: item.title,
                                                            attachments: item.attachments.filter((filterItem: ImageType) => filterItem.data_url != data.data_url)
                                                        }
                                                    }
                                                    return item;
                                                })
                                            )
                                        }}>
                                            <TrashIcon label="delete attachment" />
                                        </div>
                                    )
                                }
                            ],
                        }))}
                        rowsPerPage={10}
                        defaultPage={1}
                        loadingSpinnerSize="large"
                    />
                    : <NoData message="No Attachments" />
                }
            </>
        )
    }

    const validateOnSubmit = (data: any) => {
        let errors;
        seterrtitle(requiredValidator(data, 'mytitle', errors))
        seterrassignee(requiredValidator(data, 'AssigneeId', errors))
        seterrlabel(requiredValidator(data, 'checkablelabelId', errors))

        if (requiredValidator(data, 'mytitle', errors) === false &&
            requiredValidator(data, 'AssigneeId', errors) === false &&
            requiredValidator(data, 'checkablelabelId', errors) === false) {
            return true;
        }
        else {
            return false
        }

    };

    const validateOnCancel = (data: any) => {
        let errors;
        if (requiredValidator(data, 'mytitle', errors) === true &&
            requiredValidator(data, 'mydescription', errors) === true &&
            requiredValidator(data, 'AssigneeId', errors) === true &&
            requiredValidator(data, 'checkablelabelId', errors) === true) {
            return true;
        }
        else {
            return false
        }

    };

    const requiredValidator = (
        data: any,
        key: string,
        errors?: any,
    ) => {
        if (!data[key] || data[key].length < 1) {
            return true
        }
        else {
            return false;
        }
    };


    const addTask = async (data: any) => {
        if (projectIDs === "") {
            setprojectIDs(projectdropdowndata[0].value)
        }

        if (childTasks.length < 1) {

            return await BooksService.addTask(
                configData.parantTaskId ?
                    {
                        taskId: configData.parantTaskId,
                        title: mytitle,
                        description: mydescription,
                        dueDate: date != "" ? date : formatDatedatetimeyeargforcreatetask(today.toString(), "DD/MM/YY"),
                        priority: priority,
                        tStatus: 1,
                        milestone: milestoneObject,
                        stage: stageObject,
                        watchingIds: [],
                        categoryIds: [],
                        imagePath: imagessave[0],
                        file: finalimagearray,
                        projectIds: [projectIDs === "" ? projectdropdowndata[0].value : projectIDs],
                        assignedByIds: [JSON.parse(localStorage.getItem('logindetail') || '{}').id],
                        assigneeIds: AssigneeId,
                        labelIds: checkablelabelId,
                        branch: checkablebranchId,
                        reportersIds: reporterValue?.map((item: any) => item?.value)
                    }
                    :
                    {
                        title: mytitle,
                        description: mydescription,
                        dueDate: date != "" ? date : formatDatedatetimeyeargforcreatetask(today.toString(), "DD/MM/YY"),
                        priority: priority,
                        tStatus: 0,
                        watchingIds: [],
                        categoryIds: [],
                        imagePath: imagessave[0],
                        file: finalimagearray,
                        projectIds: [projectIDs === "" ? projectdropdowndata[0].value : projectIDs],
                        assignedByIds: [JSON.parse(localStorage.getItem('logindetail') || '{}').id],
                        assigneeIds: AssigneeId,
                        labelIds: checkablelabelId,
                        branch: checkablebranchId,
                        reportersIds: reporterValue?.map((item: any) => item?.value)
                    }
            )
        }
        else {
            return await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.task.multipleTasks, {
                parant: {
                    title: mytitle,
                    description: mydescription,
                    dueDate: date != "" ? date : formatDatedatetimeyeargforcreatetask(today.toString(), "DD/MM/YY"),
                    priority: priority,
                    tStatus: 0,
                    watchingIds: [],
                    categoryIds: [],
                    imagePath: imagessave[0],
                    file: finalimagearray,
                    projectIds: [projectIDs === "" ? projectdropdowndata[0].value : projectIDs],
                    assignedByIds: [JSON.parse(localStorage.getItem('logindetail') || '{}').id],
                    assigneeIds: AssigneeId,
                    labelIds: checkablelabelId,
                    branch: checkablebranchId,
                    reportersIds: reporterValue?.map((item: any) => item?.value)
                },
                childs: childTasks.map((item: MultiTaskFormChildTask) => ({
                    title: item.title, attachments: item.attachments.map((item: ImageType) => ({
                        name: item.file?.name,
                        contentType: item.file?.type,
                        path: item.data_url,
                        ExistingType: 1
                    }))
                }))
            })
        }
            
    }
    const getCurrentUKey = async () => {
        
        let res = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.task.getCurrentUKey + "/" + projectIDs);
        if (res?.result) {
            setCurrentUkey(res.result)

        }
    }

    const SaveTask = async (saveType: TaskSaveType = TaskSaveType.Save) => {
        let data = {
            mytitle,
            mydescription,
            AssigneeId,
            checkablelabelId
        }

        if (validateOnSubmit(data)) {
            setsavingload(true)
            let response: any = await addTask(data);
            if (response?.result) {
                IsSuccess(true)
                handleclose()
                let res = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.task.getCurrentUKey + "/" + projectIDs);
               
                if (res?.result) {
                    setCurrentUkey(res.result)
                }
                if (saveType != TaskSaveType.SaveAndNew) {
                    setChildTasks([])
                    showAlert("Task created successfully", AlertType.SUCCESS)
                    closeModel()
                } else {
                    setImages([])
                    setmydescription("")
                    showAlert("Task created successfully", AlertType.SUCCESS)
                }
            }
            else if (response?.errors) {
                IsSuccess(false)
                SetAlertMessage(response.message)
                if (childTasks.length > 0) {
                    handleclose()
                    closeModel()
                }
            }
            setsavingload(false)
            callback()
        }
    }


    const functiongetuser = () => {
        let userdata: any = []
        BooksService.getUserByProjectId(projectIDs).then((res: any) => {
            if (res.result.length > 0) {
                res.result[0].assignee.map((item: any) => {
                    userdata.push({ label: item.name, value: item.id })
                })
                setAssignee2(userdata)
            }
        })
    }

    // get all users list 
    const getAllUsers = async () => {
        await BooksService.getUser(2000).then((res: any) => {
            let options: any = [];
            let defaultOption: any = {};
            res?.result?.forEach(element => {
                if (element?.id === LocalStorage.getItem('logindetail', "JSON")?.id)
                    defaultOption = { label: element.name, value: element.id }
                options.push({ label: element.name, value: element.id })
            });
            setReporterValue([defaultOption])
            setReporterOpt(options)
        })
    }


    const functiongetuser1 = () => {
        let userdata: any = []
        BooksService.getLabel(60).then((res) => {
            res.result.map((item: any) => {
                userdata.push({ label: item.name, value: item.id })
            })
            setcompanydata(userdata)
        })
    }

    const functiongetuser2 = () => {
        let userdata: any = []
        BooksService.getBranch(60).then((res) => {
            res.result.map((item: any) => {
                userdata.push({ label: item.name, value: item.id })
            })
            setBranchData(userdata)
        })
    }

    const functiongetproject = () => {
        let userdata: any = []
        BooksService.getProjectByUser(JSON.parse(localStorage.getItem("logindetail") || "{}").id).then((res) => {
            res.result.map((item: any) => {
                if (createType === CreateType.Copy) {
                    userdata.push(localStorage.getItem("projectid") != item.id && { label: item.name, value: item.id })
                }
                else {
                    userdata.push({ label: item.name, value: item.id })
                }
            })
            if (localStorage.getItem("projectid")) {
                setprojectIDs(localStorage.getItem("projectid"))
            } else {
                setprojectIDs(userdata[0].value)
            }
            setprojectdropdowndata(userdata)
            projectdropdownvalue({ label: projectdropdowndata[0]?.label, value: projectdropdowndata[0]?.value })
        })
    }

    const GetMilestoneData = async (projectid: string = LocalStorage.getItem("projectid", "string") ? LocalStorage.getItem("projectid", "string") : "") => {
        let response = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.milestone.getAll + "?", { isAll: true, withExtend: true, projectid: projectid })
        if (response.result.length > 0) {
            setMilestoneOptions(
                [
                    { label: "No Milestone", value: "" },
                    ...response.result.map((item: any) => ({ label: item.name, value: item.id }))
                ]

            )
        }
        else {
            setMilestoneOptions([])
        }
    }

    const GetStageData = async (milestoneid: string) => {
        let response = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.stage.getAll + "?", { milestoneid: milestoneid })
        if (response.result.length > 0) {
            setStageOptions((
                [
                    { label: "No Stage", value: "" },
                    ...response.result.map((item: any) => ({ label: item.name, value: item.id }))
                ]

            ))
        }
    }

    useEffect(() => {
        BooksService.getSprint("").then((res) => {
            setsprintdata(res.result)
        })
        BooksService.getLabel(15).then((res) => {
            setlabeldata(res.result)
        })
        BooksService.getUser(60).then((res) => {
            setAssignee(res.result)
        })
        GetMilestoneData()
        functiongetuser1()
        functiongetuser2()
        functiongetproject()

    }, [localStorage.getItem("projectid")])

    useEffect(() => {
        functiongetuser()
        getCurrentUKey()
    }, [projectIDs])

    useEffect(() => {
    }, [currentUkey])

    useEffect(() => {
        getAllUsers()
    }, [])
    const projectdropdownvalue = ({ label, value }: { label: string, value: string }) => {
        setProjectDefaultOption(localStorage.getItem("projectid") ? { label: localStorage.getItem("name"), value: localStorage.getItem("projectid") } : { label: label, value: value })
    }

    const handleclose = () => {
        seterrtitle(false)
        seterrdesc(false)
        seterrassignee(false)
        seterrlabel(false)
        setmytitle("")
        setmydescription("")
        editorRef?.current?.setContents('');
        setAssigneedaID(null)
        setcheckablelabelID(null)
        setIssueTypeValue([])
        setBranchValue([])
        seterrtitle(false)
        setcheckablelabelID(null)
        seterrdesc(false)
        seterrassignee(false)
        seterrlabel(false)
        setassigneevalue([])
        setIsChecked(false)
    }

    useEffect(() => {
        setrandomcolor(randomColorArray())
    }, [statenot])

    return (
        <>
            <ModalTransition>
                <Modal width={1300}>
                    <ModalBody>
                        <div style={{
                            display: "flex",
                            alignItems: "baseline",
                            justifyContent: "space-between",
                            marginTop: 14
                        }}>
                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}>
                                <div style={{
                                    fontSize: 14,
                                    fontWeight: 600,
                                    paddingLeft: 20
                                }}>
                                    Add an Issue
                                </div>
                                <div style={{ fontWeight: 600, marginLeft: "10px" }}>
                                    {currentUkey !== "" ? `(${currentUkey})` : ""}
                                </div>
                            </div>
                            <div>
                                <div style={{ cursor: "pointer", marginRight: 18 }} onClick={
                                    () => {
                                        if (isdiscardalert()) {
                                            /*if (createType === CreateType.Copy) {*/
                                            if (initialimage.length > 0) {
                                                functionRef?.current()
                                            }
                                            /*}*/
                                            handleclose()
                                            closeModel()
                                        }
                                        else {
                                            openModalconfirm()
                                        }
                                    }}>

                                    <CrossIcon
                                        label="Close Modal"
                                        size="medium"
                                        primaryColor="#bac2d0"
                                    />

                                </div>
                            </div>
                        </div>

                        <hr style={{ color: "#F6F6F6", opacity: "0.2", marginTop: "10px", marginBottom: "10px" }} />

                        <div style={{ display: "flex" }} >

                            <div style={{ padding: "15px", paddingLeft: "20px", paddingRight: "20px", height: "62vh", overflowX: "scroll" }}>

                                {
                                    createType != CreateType.Child &&
                                    <>
                                        <div style={{ padding: `${cardWidth < 650 ? "10px" : "0px"}` }}>
                                            <label className="labelcolor" style={{ fontSize: 14 }}>Project</label>
                                            <div style={{
                                                width: 400,
                                                marginLeft: 25,
                                                marginTop: 5,
                                                fontSize: "10px!important"
                                            }}>

                                                <Select
                                                    inputId="checkbox-select-example"
                                                    className="checkbox-select"
                                                    options={[...projects]}
                                                    defaultValue={
                                                        projectDefaultOption
                                                    }
                                                    onChange={(value: any) => {
                                                        setprojectIDs(value.value)
                                                        GetMilestoneData(value.value)
                                                    }}
                                                    components={{ Control }}
                                                    styles={{
                                                        control: (base) => {

                                                            return {
                                                                ...base,
                                                                backgroundColor: "#f6f8fc",
                                                                border: "none",

                                                            }

                                                        }
                                                    }}
                                                    placeholder="Choose Project"
                                                />

                                            </div>
                                        </div>
                                        {
                                            milestoneOptions.length > 0 &&
                                            <>

                                                <div style={{ padding: `${cardWidth < 650 ? "10px" : "0px"}`, marginTop: 15 }}>
                                                    <label className="labelcolor" style={{ fontSize: 14 }}>Attach Milestone</label>
                                                    <div style={{
                                                        width: 400,
                                                        marginLeft: 25,
                                                        marginTop: 5,
                                                        fontSize: "10px!important"
                                                    }}>

                                                        <Select
                                                            inputId="checkbox-select-example"
                                                            className="checkbox-select"
                                                            options={[...milestoneOptions]}
                                                            defaultValue={
                                                                []
                                                            }
                                                            onChange={(value: any) => {
                                                                if (value.value === "")
                                                                    setMilestoneObject({})
                                                                else
                                                                    setMilestoneObject({ id: value.value, name: value.label })

                                                                GetStageData(value.value)
                                                            }}
                                                            components={{ Control }}
                                                            styles={{
                                                                control: (base) => {

                                                                    return {
                                                                        ...base,
                                                                        backgroundColor: "#f6f8fc",
                                                                        border: "none",

                                                                    }

                                                                }
                                                            }}
                                                        />

                                                    </div>
                                                </div>

                                                <div style={{ padding: `${cardWidth < 650 ? "10px" : "0px"}`, marginTop: 15 }}>
                                                    <label className="labelcolor" style={{ fontSize: 14 }}>Attach Milestone's Stage</label>
                                                    <div style={{
                                                        width: 400,
                                                        marginLeft: 25,
                                                        marginTop: 5,
                                                        fontSize: "10px!important"
                                                    }}>

                                                        <Select
                                                            inputId="checkbox-select-example"
                                                            className="checkbox-select"
                                                            options={[...stageOptions]}
                                                            defaultValue={
                                                                []
                                                            }
                                                            onChange={(value: any) => {
                                                                if (value.value === "")
                                                                    setStageObject({})
                                                                else
                                                                    setStageObject({ id: value.value, name: value.label })
                                                            }}
                                                            components={{ Control }}
                                                            styles={{
                                                                control: (base) => {

                                                                    return {
                                                                        ...base,
                                                                        backgroundColor: "#f6f8fc",
                                                                        border: "none",

                                                                    }

                                                                }
                                                            }}
                                                        />

                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </>
                                }

                                <div style={{
                                    marginTop: `${cardWidth < 450 ? "0px" : "14px"}`,
                                    padding: `${cardWidth < 650 ? "10px" : "0px"}`,
                                }}>
                                    <label className="labelcolor" style={{
                                        fontSize: 14
                                    }}>Title</label>
                                    <div style={{
                                        marginTop: 5
                                    }}>

                                        <Textfield
                                            appearance="standard"
                                            required
                                            style={{
                                                backgroundColor: "#f6f8fc",
                                            }}
                                            value={mytitle}
                                            autoFocus
                                            isRequired
                                            onChange={(e: any) => {
                                                seterrtitle(false)
                                                setmytitle(e.target.value)
                                            }
                                            }
                                        />
                                        {
                                            errtitle && (
                                                <ErrorMessage>
                                                    Please enter title
                                                </ErrorMessage>
                                            )
                                        }
                                    </div>
                                </div>

                                <div style={{
                                    display: `${cardWidth < 450 ? "block" : "flex"}`,
                                    justifyContent: "space-between",
                                    marginTop: `${cardWidth < 450 ? "0px" : "10px"}`,
                                    padding: `${cardWidth < 650 ? "10px" : "0px"}`,
                                    paddingTop: "10px"
                                }}>

                                    <div>
                                        <label className="labelcolor" style={{ fontSize: 14 }}>Priority</label>
                                        <div style={{
                                            width: 173,
                                            marginLeft: 25,
                                            marginRight: 50,
                                            marginTop: 5,
                                            fontSize: "10px!important"
                                        }}>
                                            <Select
                                                inputId="checkbox-select-example"
                                                className="checkbox-select"
                                                options={[{ label: "High", value: 2 },
                                                { label: "Medium", value: 1 },
                                                { label: "Low", value: 0 }
                                                ]}
                                                defaultValue={createType === CreateType.Copy ? { label: priorityconvertor(priority), value: priority } : { label: "Low", value: 0 }}
                                                onChange={(value: any) => {
                                                    setpriority(value.value)
                                                }}
                                                styles={{
                                                    control: (base) => {

                                                        return {
                                                            ...base,
                                                            backgroundColor: "#f6f8fc",
                                                            border: "none",
                                                        }

                                                    }
                                                }}
                                                placeholder="Choose Priority"
                                            />

                                        </div>
                                    </div>

                                    <div>
                                        <label className="labelcolor" style={{
                                            fontSize: 14
                                        }}>Due Date</label>
                                        <div style={{
                                            width: 191,
                                            fontSize: 14,
                                            marginTop: 5
                                        }}>
                                            <DatePicker
                                                onChange={setDate}
                                                name="datetime-picker"
                                                spacing="compact"
                                                dateFormat="DD/MM/YYYY"
                                                placeholder={formatDatedatetimeyeargforcreatetask(today.toString(), "not")}
                                                appearance="default"
                                            />

                                        </div>

                                    </div>

                                </div>
                                <div style={{
                                    display: `${cardWidth < 450 ? "block" : "flex"}`,
                                    justifyContent: "space-between",
                                    marginTop: 20,
                                    padding: `${cardWidth < 650 ? "10px" : "0px"}`
                                }}>

                                    <div style={{ paddingLeft: `${cardWidth < 450 ? "0px" : "0px"}`, paddingTop: `${cardWidth < 450 ? "10px" : "0px"}`, paddingBottom: `${cardWidth < 450 ? "10px" : "0px"}` }}>
                                        <label className="labelcolor" style={{ fontSize: 14 }}>Issue Type</label>
                                        <div style={{
                                            width: 173,

                                            marginTop: 5,
                                            paddingLeft: 24
                                        }}>

                                            <CheckboxSelect
                                                inputId="checkbox-select-example"
                                                className="checkbox-select"
                                                maxMenuHeight={120}
                                                defaultValue={createType === CreateType.Copy ? [...datas.issueType()] : []}
                                                isRequired
                                                value={createType != CreateType.Copy && issueTypeValue}
                                                options={[...companydata]}
                                                placeholder="Choose Issue Type"
                                                menu
                                                onChange={(value) => {
                                                    seterrlabel(false)
                                                    let arr: any = value.map((item: any) => {
                                                        return item.value
                                                    })
                                                    setIssueTypeValue(value)
                                                    setcheckablelabelID(arr)
                                                }}
                                                styles={{
                                                    control: (base) => {

                                                        return {
                                                            ...base,
                                                            backgroundColor: "#f6f8fc",
                                                            border: "none",
                                                        }
                                                    }
                                                }}
                                            />

                                        </div>
                                        {
                                            errlabel && (
                                                <ErrorMessage>
                                                    Please select label
                                                </ErrorMessage>
                                            )
                                        }
                                    </div>

                                    <div style={{ paddingLeft: `${cardWidth < 450 ? "24px" : "0px"}`, paddingTop: `${cardWidth < 450 ? "10px" : "0px"}`, paddingBottom: `${cardWidth < 450 ? "10px" : "0px"}` }}>
                                        <label className="labelcolor" style={{ marginLeft: -21, marginTop: 10, fontSize: 14 }}>Assignee</label>
                                        <div style={{
                                            width: 173,
                                            marginTop: 5,
                                            marginRight: "-5px"
                                        }}>

                                            <CheckboxSelect
                                                inputId="checkbox-select-example"
                                                className="checkbox-select"
                                                maxMenuHeight={120}
                                                placeholder="Choose assignee"
                                                value={[...assigneevalue]}
                                                options={[...Assignee2]}
                                                onChange={(value) => {
                                                    if (value.length == 0) {
                                                        setIsChecked(false)
                                                    }
                                                    seterrassignee(false)
                                                    setassigneevalue([...value])
                                                    let arr = value.map((item: any) => {
                                                        if (item.value === LocalStorage.getItem("logindetail", "JSON").id) {
                                                            setIsChecked(true)
                                                        }
                                                        return item.value
                                                    })
                                                    setAssigneedaID(arr)
                                                }}
                                                styles={{
                                                    control: (base) => {

                                                        return {
                                                            ...base,
                                                            backgroundColor: "#f6f8fc",
                                                            border: "none",

                                                        }

                                                    }
                                                }}

                                            />

                                        </div>
                                        <div style={{ fontSize: 14, marginLeft: -29 }}>
                                            <Checkbox
                                                isChecked={isChecked}
                                                onChange={(value: any) => {
                                                    assigntome(value)
                                                }}
                                                value="Controlled Checkbox"
                                                label={"Assign to me"}
                                                name="controlled-checkbox"
                                            />
                                        </div>
                                        <div style={{ marginLeft: "-25px" }}>
                                            {
                                                errassignee && (
                                                    <ErrorMessage>
                                                        Please select assignee
                                                    </ErrorMessage>
                                                )
                                            }
                                        </div>
                                    </div>

                                </div>
                                {/* Row 5 */}
                                <div style={{
                                    display: `${cardWidth < 450 ? "block" : "flex"}`,
                                    justifyContent: "space-between",
                                    marginTop: 20,
                                    padding: `${cardWidth < 650 ? "10px" : "0px"}`
                                }}>
                                    {/* Branch */}
                                    <div>
                                        <div style={{ paddingLeft: `${cardWidth < 450 ? "0px" : "0px"}`, paddingTop: `${cardWidth < 450 ? "10px" : "0px"}`, paddingBottom: `${cardWidth < 450 ? "10px" : "0px"}` }}>
                                            <label className="labelcolor" style={{ fontSize: 14 }}>Branch</label>
                                            <div style={{
                                                width: 175,
                                                display: "flex",
                                                alignItems: "center",
                                                marginTop: 5,
                                                paddingLeft: 24
                                            }}>

                                                <CheckboxSelect
                                                    inputId="checkbox-select-example"
                                                    className="checkbox-select"
                                                    maxMenuHeight={120}
                                                    defaultValue={createType === CreateType.Copy ? [...datas.branchType()] : []}
                                                    isRequired
                                                    value={createType != CreateType.Copy && branchValue}
                                                    options={[...branchData]}
                                                    placeholder="Choose Branch"
                                                    menu
                                                    onChange={(value) => {
                                                        seterrlabel(false)
                                                        let arr: any = value.map((item: any) => ({
                                                            id: item?.value, name: item?.label
                                                        }))
                                                        setBranchValue(value)
                                                        setcheckablebranchId(arr)
                                                    }}
                                                    styles={{
                                                        control: (base) => {

                                                            return {
                                                                ...base,
                                                                backgroundColor: "#f6f8fc",
                                                                border: "none",
                                                            }
                                                        }
                                                    }}
                                                />
                                                <div style={{ marginLeft: 5, cursor: "pointer" }} onClick={() => setIsCreateBranch(true)}>
                                                    <AddIcon label="add" primaryColor="#b9bfc9" />
                                                </div>
                                            </div>
                                            {
                                                errlabel && (
                                                    <ErrorMessage>
                                                        Please select label
                                                    </ErrorMessage>
                                                )
                                            }
                                        </div>
                                    </div>

                                    {/* Reporter */}
                                    <div style={{ paddingLeft: `${cardWidth < 450 ? "24px" : "0px"}`, paddingTop: `${cardWidth < 450 ? "10px" : "0px"}`, paddingBottom: `${cardWidth < 450 ? "10px" : "0px"}` }}>
                                        <label className="labelcolor" style={{ marginLeft: -21, marginTop: 10, fontSize: 14 }}>Reporter</label>
                                        <div style={{
                                            width: 173,
                                            marginTop: 5,
                                            marginRight: "-5px"
                                        }}>

                                            <Select
                                                inputId="checkbox-select-example"
                                                className="checkbox-select"
                                                placeholder="Choose reporter"
                                                value={reporterValue}
                                                isClearable
                                                maxMenuHeight={120}
                                                options={[...reporterOpt]}
                                                onChange={(value) => {
                                                    setReporterValue([value])
                                                }}
                                                styles={{
                                                    control: (base) => {

                                                        return {
                                                            ...base,
                                                            backgroundColor: "#f6f8fc",
                                                            border: "none",

                                                        }

                                                    }
                                                }}

                                            />

                                        </div>

                                    </div>

                                </div>


                                <ImageUploading
                                    multiple
                                    value={images}
                                    onChange={handleimagechange}
                                    maxNumber={maxNumber}
                                    allowNonImageType={true}
                                    dataURLKey="data_url"
                                >
                                    {({
                                        imageList,
                                        onImageUpload,
                                        onImageRemoveAll,
                                        onImageUpdate,
                                        onImageRemove,
                                        isDragging,
                                        dragProps
                                    }) => (
                                        // write your building UI
                                        <div className="upload__image-wrapper">
                                            <div style={{
                                                display: "flex",
                                                alignItems: "baseline",
                                                paddingTop: "10px"
                                            }}>
                                                <div style={{ paddingRight: "20px" }}>
                                                    <p style={{ color: "#9a99af", fontSize: "13px" }}>Attachment</p>
                                                </div>
                                                <div onClick={() => {
                                                    functionRef.current = onImageRemoveAll;
                                                    onImageUpload()
                                                }} style={{
                                                    display: "flex",
                                                    alignItems: "baseline",
                                                    cursor: "pointer"
                                                }}>
                                                    <p><AttachmentIcon size="medium" primaryColor="#758296" label="persn" /></p>
                                                    <p style={{
                                                        color: "#758296",
                                                        fontSize: "13px"
                                                    }}>Attach</p>
                                                </div>
                                            </div>
                                            &nbsp;
                                            <div style={{
                                                display: "grid",
                                                gridTemplateColumns: "33.33% 33.33% 33.33%"
                                            }}>
                                                {
                                                    imageList.map((image, index) => {

                                                        return (

                                                            <div key={index} style={{ marginRight: "20px", marginTop: "10px" }} className="image-item">
                                                                {
                                                                    image.file != undefined &&

                                                                    <div style={{ textAlign: "center", border: "1px solid #F2F6FE", padding: "10px", borderRadius: "10px" }}>
                                                                        <img onClick={() => {
                                                                            setImagessave(image.data_url)
                                                                            setIsPopup(true)
                                                                        }
                                                                        } style={{ height: "60px" }} src={image.data_url} />
                                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                                            <p style={{ margin: 0, fontSize: 14, fontFamily: "lato" }}>{substringmethod(image.file.name)}</p>
                                                                            <span style={{ paddingLeft: "20px", cursor: "pointer", flexShrink: 0 }} onClick={() => {
                                                                                onImageRemove(index)
                                                                            }}><TrashIcon primaryColor='#b9bfc9' label='person' /></span>
                                                                        </div>
                                                                    </div>

                                                                }
                                                            </div>
                                                        )
                                                    }
                                                    )}
                                            </div>
                                        </div>
                                    )}
                                </ImageUploading>

                                {
                                    createType === CreateType.Copy &&
                                    datas.file?.map((image: any, index: any) => {

                                        return (
                                            <div key={index} style={{ display: "flex" }} className="image-item">
                                                {
                                                    <div>
                                                        <a href={image.data_url}>
                                                            {
                                                                imagePath(image.path)
                                                            }
                                                        </a>
                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                            <p style={{ margin: 0, fontSize: 14, fontFamily: "lato" }}>{image.name}</p>
                                                            <span style={{ paddingLeft: "20px", cursor: "pointer" }}><TrashIcon primaryColor='#b9bfc9' label='person' /></span>
                                                        </div>
                                                    </div>

                                                }
                                            </div>
                                        )
                                    }
                                    )}

                            </div>

                            <div style={{
                                marginTop: 10,
                                marginLeft: 20,
                                marginRight: 20,
                                padding: `${cardWidth < 650 ? "10px" : "0px"}`
                            }}>
                                <div style={{ display: "flex" }}>

                                    <Tabs>
                                        <TabList>
                                            <Tab>Description</Tab>
                                            {
                                                configData.parantTaskId === "" &&
                                                <>
                                                    <Tab>Add child Task</Tab>
                                                   
                                                </>
                                            }
                                        </TabList>
                                        <TabPanel>
                                            <SunEditor
                                                getSunEditorInstance={getSunEditorInstance}
                                                setContents={mydescription ?? ""}
                                                height="400"
                                                width="750"
                                                setOptions={{
                                                    buttonList: [
                                                        ["undo", "redo"],
                                                        ["font", "fontSize"],
                                                        // ['paragraphStyle', 'blockquote'],
                                                        [
                                                            "bold",
                                                            "underline",
                                                            "italic"
                                                        ],
                                                        ["fontColor", "hiliteColor"],
                                                        ["align", "list", "lineHeight"],
                                                        ["outdent", "indent"],

                                                        ["link", "image"],
                                                        // ['math'] //You must add the 'katex' library at options to use the 'math' plugin.
                                                        // ['imageGallery'], // You must add the "imageGalleryUrl".
                                                        // ["fullScreen", "showBlocks", "codeView"],
                                                        ["removeFormat"]

                                                        // ['save', 'template'],
                                                        // '/', Line break
                                                    ], // Or Array of button list, eg. [['font', 'align'], ['image']]
                                                    defaultTag: "div",
                                                    showPathLabel: false
                                                }}
                                                onChange={(value: any) => {
                                                    seterrdesc(false)
                                                    setmydescription(value)
                                                }}
                                            />
                                        </TabPanel>
                                        <TabPanel>
                                        <div>
                                            <div style={{ cursor: "pointer", display: "flex", alignItems: "center" }}>
                                                <Tooltip position="top" content="add child task">
                                                    {(tooltipProps: any) => (
                                                        <div {...tooltipProps} shouldFitContainer style={{ display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }} onClick={handleTabPress}>
                                                            <AddCircleIcon label="addrow" primaryColor="#95A1AE" />
                                                        </div>
                                                    )}
                                                </Tooltip>

                                                <Tooltip position="top" content="import spreadsheet">
                                                    {(tooltipProps: any) => (
                                                        <div {...tooltipProps} shouldFitContainer style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: 5, cursor: "pointer" }} onClick={() => setIsExcelModel(true)}>
                                                            <MediaServicesSpreadsheetIcon label="spreadsheet" primaryColor="#95A1AE" />
                                                        </div>
                                                    )}
                                                </Tooltip>
                                                <Tooltip position="top" content="download template">
                                                    {(tooltipProps: any) => (
                                                        <div {...tooltipProps} shouldFitContainer style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: 5, cursor: "pointer" }} onClick={HandleExcelFormatExport}>
                                                            <DownloadIcon label="download template" primaryColor="#95A1AE" />
                                                        </div>
                                                    )}
                                                </Tooltip>
                                            </div>
                                            <div style={{ width: 700, maxHeight: 450, padding: 10, overflow: "scroll" }}>
                                                {
                                                    ChildTaskGenerator()
                                                }
                                                </div>
                                            </div>
                                        </TabPanel>
                                    </Tabs>
                                </div>
                            </div>

                        </div>

                    </ModalBody>

                    <div style={{
                        display: "flex",
                        justifyContent: "end",
                        marginTop: 10,
                        marginBottom: 10,
                        marginRight: 10

                    }}>
                        <Button appearance="primary" style={{ height: "35px", display: "flex", alignItems: "center", marginRight: "10px", backgroundColor: "#0E51D3", fontSize: "14px!important" }} onClick={() => SaveTask(TaskSaveType.Save)} type="submit">
                            Save
                        </Button>
                        <Button appearance="primary" style={{ height: "35px", display: "flex", alignItems: "center", backgroundColor: "#0E51D3", fontSize: "14px!important" }} onClick={() => SaveTask(TaskSaveType.SaveAndNew)} type="submit">
                            Save and New
                        </Button>
                    </div>

                </Modal>
            </ModalTransition>

            <AlertModal isOpenconfirm={isOpenconfirm} openModalconfirm={openModalconfirm} closeModelconfirm={closeModelconfirm} handleclose={() => {
                handleclose()
                closeModel()
            }} type={"createtask"} />

            <Alert isSuccess={isSuccess} message={message} flags={flags} handleDismiss={handleDismiss} />

            {

                isPopup &&

                <ImagePopup onClose={() => setIsPopup(false)}>

                    <img onClick={() => setIsPopup(true)} style={{ height: "87%", width: "100%" }} src={imagessave} />

                </ImagePopup>

            }

            {
                childAttachmentModel &&
                <ModalTemplate closeModel={() => setChildAttachmentModel(false)} title="Attachment" shouldShowFooter={false} >
                    {
                        ChildTaskAttachmentRendrer()
                    }
                </ModalTemplate>
            }

            {
                isExcelModel &&
                <ModalTemplate closeModel={() => setIsExcelModel(false)} title="Add Excel" shouldShowFooter={true} buttonText="Upload"
                    onClose={() => setChildTaskSheetData([])} onSubmit={HandleExcelImport}
                >
                    <ImageUploading
                        multiple
                        value={ChildTaskSheetData}
                        onChange={(file: ImageListType) => setChildTaskSheetData(file)}
                        maxNumber={1}
                        allowNonImageType={true}
                        acceptType={["xlsx"]}
                        dataURLKey="data_url"
                    >
                        {({
                            imageList,
                            onImageUpload,
                            onImageRemoveAll,
                            onImageUpdate,
                            onImageRemove,
                            isDragging,
                            dragProps
                        }) => (
                            <div className="upload__image-wrapper">

                                {
                                    imageList.length > 0 ?
                                        <div
                                            style={{ alignItems: "center", display: "flex", justifyContent: "center" }}
                                        >
                                            {
                                                imageList.map((item: any) => (
                                                    <div style={{ textAlign: "center" }}>
                                                        <img style={{ width: 400, height: 200 }} src={process.env.PUBLIC_URL + "/xlxs.svg"} alt="" />
                                                        <p>{item.file.name}</p>
                                                    </div>
                                                ))
                                            }


                                        </div>
                                        :
                                        <div
                                            onClick={onImageUpload}
                                            style={{ width: "100%", height: "100%" }}
                                            {...dragProps}
                                        >
                                            <NoData message={`${isDragging ? "Dragging" : "Drag or click to upload spreadsheet"}`} />
                                        </div>
                                }
                            </div>

                        )}
                    </ImageUploading>
                </ModalTemplate>
            }

            {
                savingload &&
                <div className="loading">
                    <Spinner size="medium" />
                </div>
            }
            {
                isCreateBrach &&
                <ModalTemplate closeModel={() => setIsCreateBranch(false)} title="Create branch" shouldShowFooter={false} >
                    <Form onSubmit={(data: any) => {
                        console.log(data)
                    }}>
                        {({ formProps }) => (
                            <form {...formProps}>
                                <Textfield
                                    appearance="standard"
                                    name="branch"
                                    onChange={(e: any) => setBranch(e.target.value)}
                                    style={{
                                        backgroundColor: "#f6f8fc",
                                    }}
                                />
                                <div style={{
                                    display: "flex",
                                    justifyContent: "end",
                                    margin: 20
                                }}>

                                    <Button style={{ marginRight: "10px" }} appearance="subtle" onClick={() => setIsCreateBranch(false)}>
                                        Cancel
                                    </Button>
                                    <Button appearance="primary"
                                        style={{ height: "35px", display: "flex", alignItems: "center", backgroundColor: "#3177ff", fontSize: "14px!important" }}
                                        type="submit"
                                        onClick={AddBranch}
                                    >
                                        Save
                                    </Button>
                                </div>
                            </form>
                        )}
                    </Form>

                </ModalTemplate>
            }
            <Loader isloading={isBranchSave} />
        </>
    )
}
export default TaskModel