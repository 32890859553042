import { createContext, useContext, useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode'
import HTTPSCalls from '../Services/HTTPCalls';
import LocalStorage from '../Services/Localstorage';

interface IUser {
  email: string,
  id: string,
  name: string,
  role: string,
  imagePath: string,
  imageThumb: string
}

interface AppContextProps {
    projects: any;
    user: IUser;
    setUser: any;
    getProjectByUser: (token?: string) => Promise<void>
  }

// Create the context
const AppContext = createContext<AppContextProps | undefined>(undefined);

// Create the ContextProvider component
export const AppProvider = ({ children }: any) => {

  const [projects, setProjects] = useState<any>([])
  const [user, setUser] = useState<IUser | undefined>(undefined)
  
    const getCurrrentUser = (token ?:string) => {
        let tokenVal = token ?? LocalStorage.getItem("Token", "string");
        if (tokenVal) {      
          const currentuser: any = jwtDecode(tokenVal);
          if(currentuser)
            setUser({name: currentuser.name, id: currentuser.id,
          email: currentuser.email, role: currentuser.role,
          imagePath: currentuser.imagePath, imageThumb: currentuser.imageThumb});
          return currentuser;
        }
  }

  const getProjectByUser = async (token?: string) => {
    let currentUser = getCurrrentUser(token);
    if (currentUser) {
      let res = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.project.getAll + "?", { uid: currentUser?.id, isAllRecord: true })
      if (res) setProjects(res.result?.map((item: any) => ({ ...item, label: item?.name, value: item?.id })))
    }
  }

  useEffect(() => {
    getProjectByUser();
  }, [])

  return (
    <>
          <AppContext.Provider value={{ projects, user, setUser, getProjectByUser }}>
                {children}
        </AppContext.Provider>
    </>
  );
};

// Create a custom hook to use the app context
export const useApp = () => {
  return useContext(AppContext);
};