import Button from '@atlaskit/button/standard-button';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle, ModalTransition } from "@atlaskit/modal-dialog"
import { WidthNames } from '@atlaskit/modal-dialog/dist/types/internal/constants';

interface ConfirmationProps {
    isOpen: boolean,
    message: string,
    closeModal: () => void,
    handleConfirm: () => void,
    size?: WidthNames
}

const ConfirmationModal = ({ isOpen, closeModal, message, handleConfirm, size = "small" }: ConfirmationProps) => {
    return (
        <ModalTransition>
            {isOpen && (
                <Modal width={size}>
                    <ModalHeader>
                        <ModalTitle>Are you sure?</ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        <div style={{ marginLeft: 25 }}>
                            {message}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button appearance="subtle" onClick={closeModal}>
                            No
                        </Button>
                        <Button type="submit" appearance="primary"
                            style={{ backgroundColor: "#3177ff" }}
                            onClick={handleConfirm}
                        >Yes</Button>
                    </ModalFooter>
                </Modal>
            )}
        </ModalTransition>
        )
}

export {
    ConfirmationModal as default
}