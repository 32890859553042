import { createSlice } from '@reduxjs/toolkit'
import { useState } from 'react'
import { GetApi } from '../Hooks/useGetApi'
import { PostApi } from '../Hooks/usePostApi'
import BooksService from '../Services/Apicall'



    export const projectSlice = createSlice({
        name: 'Projects',
        initialState: <any>{
            projectData: [],
            projectDataByAssignee: [],
        },
        reducers: {

            allProjects: (state, action) => {
                state.projectData = action.payload
            },
            projectsByAssignee: (state, action) => {
                state.projectDataByAssignee = action.payload
            }
        },
    })



    export const { allProjects, projectsByAssignee } = projectSlice.actions

    // The function below is called a selector and allows us to select a value from
    // the state. Selectors can also be defined inline where they're used instead of
    // in the slice file. For example: `useSelector((state) => state.counter.value)`
    export const selectCount = (state: any) => state.counter.value

    export default projectSlice.reducer





 


