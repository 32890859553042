import { useEffect } from 'react';
import { useNavigate, useRoutes, Navigate, redirect } from 'react-router-dom';
import { useAuth } from '../Hooks/useAuth';
import { PostApi } from '../Hooks/usePostApi';
import Routes from '../Services/Endpoints';
import { protectedRoutes } from './PrivateRoutes';


export const AppRoutes = () => {

    const { isAuth } = useAuth()

    const { data,
        isLoading: isPostLoading,
        error,
        reload: postTask,
        isSuccess } = PostApi(Routes.userRoute + "/addlog");

    useEffect(() => {
        let user: any = JSON.parse(localStorage.getItem("logindetail") || "{}")
        if (user?.id) {
            postTask({ UserId: user.id }, () => { });
        }
    }, [])
    

    !isAuth && redirect("");

    const Element = () => <div></div>;

    return (<> 
            <Element />
        </>);
};
