import { Fragment, useEffect, useState } from "react";
import { IdName } from "../../Constents/Status";
import { MilestoneModel } from "../../Pages/Milestone"
import HTTPSCalls from "../../Services/HTTPCalls";
import { ModalTemplate } from "../UI/Modal/Modal";
import Select, { OptionType } from '@atlaskit/select';
import { MilestoneDetail } from "./MileStoneNewCard";
import Textfield from '@atlaskit/textfield';
import DynamicTable from "@atlaskit/dynamic-table";
import Spinner from '@atlaskit/spinner';
import { priorityconvertor, statusconvertor } from "../../utils/Util";
import Checkbox from "@atlaskit/checkbox";
import { Loader } from "../UI/Loader/Loader";
import { ErrorMessage, Label } from "@atlaskit/form";
import { DatePicker } from "@atlaskit/datetime-picker";
import Button from "@atlaskit/button";
import { error } from "console";

export interface MilestoneDetails {
    milestone: IdName,
    project: IdName,
    stage: IdName
}

export interface TaskAttachModel {
    model: MilestoneModel,
    setModel: any,
    milestoneDetail: MilestoneDetail,
    milestoneData: any
} 

const createHead = () => {
    return {
        cells: [
            {
                key: '',
                content: '',
                isSortable: false,
                width: 1
            },
            {
                key: 'Ukey',
                content: 'Ukey',
                isSortable: false,
                width: 1
            },
            {
                key: 'Title',
                content: 'Title',
                isSortable: false,
                width: 1
            },
            {
                key: 'Status',
                content: 'Status',
                isSortable: false,
                width: 1
            },
            {
                key: 'Priority',
                content: 'Priority',
                isSortable: false,
                width: 1
            },
        ],
    };
};

export const TaskAttachModel = ({ model, setModel, milestoneDetail, milestoneData }: TaskAttachModel) => {

    const [tasks, setTasks] = useState<any>([])
    const [taskidList, setTaskidList] = useState<string[]>([]);
    const [stage, setStage] = useState<IdName>({ id: "", name: "" })
    const [filterTask, setFilterTask] = useState<any>({ start: 0, length: 5000, isDone: 1, projectId: milestoneDetail?.project?.id })

    const [isLoading, setIsLoading] = useState(false)

    const [errorObject, setErrorObject] = useState<{ stage: boolean }>();

    const AttachTask = async () => {
        if (Object.values(stage).every(x => x === null || x === ''))
            setErrorObject({ ...errorObject, stage: true })
        else {
            setErrorObject({ ...errorObject, stage: false })
        setIsLoading(true)
        const response = await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.milestone.attachTask, {
            milestone: milestoneDetail?.milestone,
            stage: stage,
            taskIds: taskidList
        })
        if (response.result) {
            setTasks(response.result)
            setModel({...model, attach: false})
        }
        }
        setIsLoading(false)
    }

    const GetTask = async () => {
        setIsLoading(true)
        const response = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.task.getAll + "?", filterTask)
        if (response.result) {
            setTasks(response.result)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        GetTask()
    }, [])

    return (
        <ModalTemplate width="large" closeModel={() => {
            setModel({ ...model, attach: false })
        }} title="Attach Tasks" onSubmit={AttachTask}>


            <div>
            <div>
                <label className="labelcolor" style={{
                  fontSize: 14
                    }}>Select stage to attach task</label>
                    <div style={{ marginLeft: 25, marginTop: 2, marginBottom: 10, width: "30%" }}>
                <Select
                    inputId="checkbox-select-example"
                    className="checkbox-select"
                    options={[...milestoneData?.progress?.map((arrayItem: any) => ({ label: arrayItem?.stage?.name, value: arrayItem?.stage?.id }))]}
                    spacing="compact"
                    placeholder="Select Stage"
                    onChange={(item: any) => {
                        setStage({id: item.value, name: item.label})
                    }}
                    styles={
                        {
                            control: (base: any) => ({ ...base, fontSize: 14 }),
                        }

                    }
                        />
                    </div>
                    {
                        errorObject?.stage &&
                        <ErrorMessage>
                            Stage is required to attach task
                        </ErrorMessage>
                    }
            </div>
                <div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{margin: 4}}>
                            <label className="labelcolor" style={{
                                fontSize: 14
                            }}>Keyword</label>
                            <Textfield style={{ backgroundColor: "#f4f5f7" }} onChange={(e: any) => setFilterTask({ ...filterTask, searchText: e.target.value })} />
                        </div>
                        <div style={{ margin: 10 }}>
                            <label className="labelcolor" style={{
                                fontSize: 14
                            }}>Status</label>
                            <div style={{
                                width: 150,
                                fontSize: 14,
                                marginLeft: 25,
                                marginRight: 5
                            }}>

                                <Select
                                    inputId="checkbox-select-example"
                                    className="checkbox-select"
                                    options={[
                                    { label: "none", value: null },    
                                    { label: "To Do", value: 1 },
                                    { label: "In Progress", value: 2 },
                                    { label: "Need to Discuss", value: 3 },
                                    { label: "UAT", value: 4 },
                                    { label: "Developed", value: 7 },
                                    { label: "Testing", value: 5 },
                                    { label: "Done", value: 6 }]}
                                    spacing="compact"
                                    placeholder="Select"
                                    onChange={(item: any) => {
                                        setFilterTask({ ...filterTask, status: item.value })
                                    }}
                                    styles={
                                        {
                                            control: (base: any) => ({ ...base, fontSize: 14 }),
                                        }

                                    }
                                />

                            </div>

                        </div>
                        <div style={{ margin: 10 }}>
                            <label className="labelcolor" style={{
                                fontSize: 14
                            }}>Priority</label>
                            <div style={{
                                width: 150,
                                fontSize: 14,
                                marginLeft: 5,
                                marginRight: 5
                            }}>

                                <Select
                                    inputId="checkbox-select-example"
                                    className="checkbox-select"
                                    options={[
                                        { label: "none", value: null },
                                        { label: "High", value: 2 },
                                        { label: "Medium", value: 1 },
                                        { label: "Low", value: 0 },
                                        ]}
                                    spacing="compact"
                                    placeholder="Select"
                                    onChange={(item: any) => {
                                        setFilterTask({ ...filterTask, priority: item.value })
                                    }}
                                    styles={
                                        {
                                            control: (base: any) => ({ ...base, fontSize: 14 }),
                                        }

                                    }
                                />
                            </div>

                        </div>
                        <div style={{ margin: 10, marginTop: 24, textAlign: "center" }}>
                            <Button appearance="primary"
                                style={{
                                    backgroundColor: "#3177ff",
                                    fontSize: "14px!important"
                                }}
                                onClick={GetTask}
                        >
                            Search
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

                <div style={{
                fontSize: 14,
                height: "500px",
                overflow: "scroll"
                }}>
                {
                    isLoading ?
                         <div style={{ display: "flex", justifyContent: "center", marginTop: "20%" }}>
                    <Spinner size="medium" />
                </div>
                        :
                <DynamicTable
                        head={createHead()}
                        rows={tasks.map((data: any, index: number) =>
                        ({
                            key: `row-${index}-${data.id}`,
                            isHighlighted: false,
                            cells: [
                                {
                                    content: <Checkbox
                                        onChange={(value: any) => {
                                            let resultarray: string[] = []
                                            const index = taskidList.indexOf(data?.id);
                                            if (index != -1)
                                                resultarray = taskidList.filter((arrayItems: any) => {
                                                    if (arrayItems != data?.id)
                                                        return arrayItems
                                                })
                                            else
                                                resultarray = [...taskidList, data?.id]
                                            setTaskidList(resultarray)
                                        }}
                                    />
                                },
                                {
                                    content: <p style={{ fontWeight: 400, margin: 0, fontSize: "14px" }}>{data?.uKey}</p>
                                },
                                {
                                    content: <p style={{ fontWeight: 400, margin: 0, fontSize: "14px" }}>{data?.title}</p>
                                },
                                {
                                    content: <p style={{ margin: 0, marginTop: "5px", marginBottom: "5px" }}>{statusconvertor(data?.tStatus)}</p>
                                },
                                {
                                    content: <p style={{ margin: 0, marginTop: "5px", marginBottom: "5px" }}>{priorityconvertor(data?.priority)}</p>
                                }
                            ],
                        })

                        )}
                        defaultPage={1}
                        loadingSpinnerSize="large"
                    />      
                }
                </div>
        </ModalTemplate>
        )
}