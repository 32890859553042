import Button from "@atlaskit/button";
import { DatePicker } from "@atlaskit/datetime-picker";
import Modal, { ModalBody, ModalTransition } from "@atlaskit/modal-dialog";
import Select, { CheckboxSelect } from '@atlaskit/select';
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import CrossIcon from '@atlaskit/icon/glyph/cross';
import Textfield from '@atlaskit/textfield';
import { ErrorMessage } from '@atlaskit/form';
import { useEffect, useState } from "react";
import HTTPSCalls from "../../Services/HTTPCalls";
import LocalStorage from "../../Services/Localstorage";
import { IdName } from "../../Constents/Status";
import { DurationToText, LogType } from "../../Pages/Milestone";
import { Loader } from "../UI/Loader/Loader";
import { DurationType } from "./StageDuration";
const cardWidth = window.innerWidth;

export type Progress = Array<{ stage: IdName, duration: { type: DurationType, value: number }, startDate?: string, endDate?: string }>;

export interface MilestonePost {
    id?: string,
    name?: string,
    points?: string,
    due?: string,
    deadline?: string,
    parantMilestone?: { id: string, name: string },
    project?: { id: string, name: string },
    progress?: Progress
}

const CreateMilestone = ({ model, setModel, CreateCallback, isExtend = false, isEdit = false, setMessage, addFlag, setShouldExtend = () => { }, milestoneid = "", milestoneName = "", milestoneParantid = "" }: any) => {

    //form validation error object 
    const [errorObject, setErrorObject] = useState<any>({
        project: false,
        name: false,
        points: false
    })

    const [formValues, setFormValues] = useState<MilestonePost>({
        id: "",
        name: "",
        progress: []
    })

    const [milestoneOptions, setMilestoneOptions] = useState<any>([])
    const [projectOptions, setProjectOptions] = useState<any>([])
    const [stagesOptions, setStagesOptions] = useState<any>([])
    const [isValidStages, setIsValidStages] = useState(true);
    const [isValidProject, setIsValidProject] = useState(true);
    const [isValidTitle, setIsValidTitle] = useState(true);
    const [isValidDueDate, setIsValidDueDate] = useState(true);
    const [isValidDeadlineDate, setIsValidDeadlineDate] = useState(true);

    const [isLoading, setIsLoading] = useState<boolean>(false)

    const GetMilestoneOptions = async () => {
        const response = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.milestone.getAll + "?")
        if (response.result?.length > 0) {
            setMilestoneOptions([...response.result.map((arrayItems: any) => ({ value: arrayItems.id, label: arrayItems.name }))])
        }
    }
    const GetProjectOptions = async () => {
        const response = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.project.get + "?", { start: 0, length: 50, uid: LocalStorage.getItem("logindetail", "JSON").id })
        if (response.result?.length > 0) {
            setProjectOptions(response.result.map((arrayItems: any) => ({ value: arrayItems.id, label: arrayItems.name })))
        }
    }

    const GetMilestoneStages = async () => {
        const response = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.milestoneStages.getAll)
        if (response.result?.length > 0) {
            setStagesOptions(response.result.map((arrayItems: any) => ({ value: arrayItems.id, label: arrayItems.name })))
        }
    }

    const GetMilestoneById = async () => {
        setIsLoading(true)
        const response = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.milestone.get + `/${milestoneid}`)
        if (response.result) {
            setFormValues({
                id: response.result.id, name: response.result.name, points: response.result.points,
                progress: response.result?.progress, due: response.result?.due, deadline: response.result?.deadline
            })
        }
        setIsLoading(false)
    }

    const AddMilestone = async () => {
        setIsLoading(true)
        if (formValues?.progress?.length > 0) {
            const response = await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.milestone.add, isExtend ? { parantMilestone: { id: milestoneParantid, name: milestoneName }, ...formValues } : formValues)

            if (response.result) {
                let logresponse = await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.milestone.addLog, {
                    project: response.result.project,
                    milestone: {
                        id: response.result.id,
                        name: response.result.name
                    },
                    user: {
                        id: LocalStorage.getItem("logindetail", "JSON").id,
                        name: LocalStorage.getItem("logindetail", "JSON").name,
                        thumb: LocalStorage.getItem("logindetail", "JSON").imageThumb
                    },
                    remarks: isExtend ? `milestone extended` : `milestone created`,
                    from: "",
                    to: "",
                    type: LogType.Extend
                })
                if (logresponse.result) {
                    CreateCallback(milestoneParantid)
                    setShouldExtend(false)
                    setModel({ ...model, create: false })
                }

            }
            else {
                setMessage(response.message)
                addFlag()
            }
        }
        else {
            setIsValidStages(false)
            setIsValidProject(false)
            setIsValidDeadlineDate(false)
            setIsValidDueDate(false)
            setIsValidTitle(false)
        }

        setIsLoading(false)
    }

    const EditMilestone = async () => {
        let requestBody = {
            id: milestoneid,
            name: formValues.name,
            points: formValues.points,
            progress: formValues.progress,
            due: formValues.due,
            deadline: formValues.deadline
        }
        setIsLoading(true)
        const response = await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.milestone.edit, requestBody)
        if (response.result) {

            let logresponse = await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.milestone.addLog, {
                project: response.result.project,
                milestone: {
                    id: response.result.id,
                    name: response.result.name
                },
                user: {
                    id: LocalStorage.getItem("logindetail", "JSON").id,
                    name: LocalStorage.getItem("logindetail", "JSON").name,
                    thumb: LocalStorage.getItem("logindetail", "JSON").imageThumb
                },
                remarks: `milestone edited`,
                from: "",
                to: "",
                type: LogType.Extend
            })
            if (logresponse.result) {
                CreateCallback(milestoneParantid)
                setShouldExtend(false)
                setModel({ ...model, create: false })
            }
        }
        else {
            setMessage(response.message)
            addFlag()
        }
        setIsLoading(false)
    }

    function RenderSelect() {
        if (isEdit === false && isExtend === false) {
            return <div>
                <label className="labelcolor" style={{ fontSize: 14 }}>Project</label>
                <div style={{
                    width: "91%",
                    marginLeft: 25,
                    marginTop: 5,
                    fontSize: "10px!important"
                }}>

                    <Select
                        inputId="checkbox-select-example"
                        className="checkbox-select"
                        options={[...projectOptions]}
                        onChange={(value: any) => {
                            setFormValues((prev: MilestonePost) => ({ ...prev, project: { id: value.value, name: value.label } }))
                            setIsValidProject(true)
                        }}
                        styles={{
                            control: (base) => {

                                return {
                                    ...base,
                                    backgroundColor: "#f6f8fc",
                                    border: "none",

                                }

                            }
                        }}
                    />
                    {!isValidProject &&
                        <div style={{ marginLeft: "-25px" }}>
                            <ErrorMessage>
                                Please select Project
                            </ErrorMessage>
                        </div>
                    }
                </div>
            </div>
        }
    }

    useEffect(() => {
        Promise.all([
            GetMilestoneOptions(),
            GetProjectOptions(),
            GetMilestoneStages()
        ])
        isEdit && GetMilestoneById()
    }, [])

    console.log(formValues?.progress?.length, "formValuesformValues")
    return (
        <>
            <ModalTransition>
                <Modal width="">
                    <ModalBody>
                        <div style={{
                            display: "flex",
                            alignItems: "baseline",
                            justifyContent: "space-between",
                            marginTop: 14
                        }}>
                            {
                                isExtend ?
                                    <div style={{
                                        fontSize: 14,
                                        fontWeight: 600,
                                        paddingLeft: 20
                                    }}>Extend Milestone
                                    </div> :
                                    <div style={{
                                        fontSize: 14,
                                        fontWeight: 600,
                                        paddingLeft: 20
                                    }}>{`${isEdit ? "Edit" : "Add"} Milestone`}
                                    </div>
                            }
                            <div>
                                <div style={{ cursor: "pointer", marginRight: 18 }} onClick={
                                    () => {
                                        setModel({ ...model, create: false })
                                    }}>
                                    <CrossIcon
                                        label="Close Modal"
                                        size="medium"
                                        primaryColor="#bac2d0"

                                    />
                                </div>
                            </div>
                        </div>

                        <hr style={{ color: "#F6F6F6", opacity: "0.2", marginTop: "10px", marginBottom: "10px" }} />

                        <div >

                            <div style={{ padding: "15px", paddingLeft: "20px", paddingRight: "20px" }}>
                                {
                                    RenderSelect()
                                }

                                <div style={{
                                    marginTop: 5,
                                    fontSize: "10px!important"
                                }}>
                                    <label className="labelcolor" style={{
                                        fontSize: 14
                                    }}>Title</label>
                                    <div style={{
                                        marginTop: 5
                                    }}>
                                        <Textfield
                                            appearance="standard"
                                            required
                                            value={formValues.name}
                                            style={{
                                                backgroundColor: "#f6f8fc",
                                            }}
                                            onChange={(event: any) => {
                                                setIsValidTitle(true)
                                                setFormValues((prev: MilestonePost) => ({ ...prev, name: event.target.value }))
                                            }}
                                            autoFocus
                                            isRequired
                                        />
                                        {!isValidTitle &&
                                            <ErrorMessage>
                                                Please select Title
                                            </ErrorMessage>}
                                    </div>
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between", paddingTop: "10px" }}>
                                    <div>
                                        <label className="labelcolor" style={{
                                            fontSize: 14
                                        }}>Due Date</label>
                                        <div style={{
                                            width: 191,
                                            fontSize: 14,
                                            marginTop: 5
                                        }}>
                                            <DatePicker
                                                name="datetime-picker"
                                                spacing="compact"
                                                dateFormat="DD/MM/YYYY"
                                                placeholder="DD/MM/YYYY"
                                                appearance="default"
                                                value={formValues.due}
                                                onChange={(selectedDate: string) => {
                                                    setFormValues({
                                                        ...formValues,
                                                        due: selectedDate ? selectedDate : "1001-01-01T00:00:00.000Z"

                                                    })
                                                    setIsValidDueDate(true)
                                                }
                                                }
                                            />
                                            {!isValidDueDate &&
                                                <ErrorMessage>
                                                    Please select DueDate
                                                </ErrorMessage>}
                                        </div>
                                    </div>
                                    <div>
                                        <label className="labelcolor" style={{
                                            fontSize: 14
                                        }}>Deadline Date</label>
                                        <div style={{
                                            width: 191,
                                            fontSize: 14,
                                            marginTop: 5
                                        }}>
                                            <DatePicker
                                                name="datetime-picker"
                                                spacing="compact"
                                                dateFormat="DD/MM/YYYY"
                                                placeholder="DD/MM/YYYY"
                                                appearance="default"
                                                value={formValues.deadline}
                                                onChange={
                                                    (selectedDate: string) => {
                                                        setFormValues({
                                                            ...formValues,
                                                            deadline: selectedDate ? selectedDate : "1001-01-01T00:00:00.000Z"

                                                        })
                                                        setIsValidDeadlineDate(true)
                                                    }
                                                }
                                            />
                                            {!isValidDeadlineDate &&
                                                <ErrorMessage>
                                                    Please select DeadlineDate
                                                </ErrorMessage>}
                                        </div>

                                    </div>
                                </div>

                                <div style={{
                                    marginTop: 5,
                                    fontSize: "10px!important",

                                }}>
                                    <label className="labelcolor" style={{
                                        fontSize: 14
                                    }}>Points</label>
                                    <div style={{
                                        marginTop: 5,
                                        zIndex: 0
                                    }}>

                                        <SunEditor
                                            setOptions={{
                                                buttonList: [
                                                    ["undo", "redo"],
                                                    ["font", "fontSize"],
                                                    // ['paragraphStyle', 'blockquote'],
                                                    [
                                                        "bold",
                                                        "underline",
                                                        "italic",
                                                        "strike"
                                                    ],
                                                    ["fontColor", "hiliteColor"],
                                                    ["align", "list", "lineHeight"],
                                                    ["outdent", "indent"],

                                                    ["table", "horizontalRule", "link", "image", "video"],
                                                    // ['math'] //You must add the 'katex' library at options to use the 'math' plugin.
                                                    // ['imageGallery'], // You must add the "imageGalleryUrl".
                                                    // ["fullScreen", "showBlocks", "codeView"],
                                                    ["print"],
                                                    ["removeFormat"]

                                                    // ['save', 'template'],
                                                    // '/', Line break
                                                ], // Or Array of button list, eg. [['font', 'align'], ['image']]
                                                defaultTag: "div",
                                                showPathLabel: false
                                            }}
                                            height="250"
                                            setContents={formValues.points}
                                            onChange={(value: any) => { setFormValues((prev: MilestonePost) => ({ ...prev, points: value })) }}
                                        />
                                    </div>
                                </div>

                                {
                                    !isEdit &&
                                    <div style={{
                                        marginTop: 5
                                    }}>
                                        <label className="labelcolor" style={{ fontSize: 14 }}>Attach Required Stages</label>
                                        <div style={{
                                            width: "91%",
                                            marginLeft: 25,
                                            marginTop: 5,
                                            fontSize: "10px!important"
                                        }}>

                                            <CheckboxSelect
                                                inputId="checkbox-select-example"
                                                className="checkbox-select"
                                                classNamePrefix="select"
                                                options={[...stagesOptions]}
                                                styles={{
                                                    control: (base) => {
                                                        return {
                                                            ...base,
                                                            backgroundColor: "#f6f8fc",
                                                            border: "none",
                                                        }
                                                    }
                                                }}
                                                menuPlacement={"top"}
                                                onChange={(selectedItems: any) => {
                                                    setFormValues(
                                                        {
                                                            ...formValues,
                                                            progress: selectedItems.map((arrayItems: { label: string, value: string }) =>
                                                                ({ stage: { id: arrayItems.value, name: arrayItems.label } }))
                                                        })
                                                    setIsValidStages(true)
                                                }}

                                                placeholder=""
                                            />

                                            {!isValidStages &&
                                                <div style={{ marginLeft: "-25px" }}>
                                                    <ErrorMessage>
                                                        Please select Stages
                                                    </ErrorMessage>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }

                                {
                                    isEdit &&
                                    formValues?.progress?.map((arrayItem: any, index: number) => {
                                        return <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                            <div style={{
                                                fontSize: 14,
                                                marginTop: 5,
                                                marginLeft: 20
                                            }}>
                                                <div style={{ display: "flex", justifyContent: "space-between", marginTop: 10 }}>
                                                    <div style={{ width: 100, marginTop: 10 }}>
                                                        <label className="labelcolor" style={{
                                                            fontSize: 14,
                                                            marginTop: 8,
                                                            marginLeft: -20,
                                                        }}>{arrayItem.stage.name}</label>
                                                    </div>
                                                    <div style={{ width: 100, marginLeft: 40 }}>
                                                        <Select
                                                            inputId="checkbox-select-example"
                                                            className="checkbox-select"
                                                            menuPlacement="top"
                                                            options={[
                                                                { label: "Hours", value: DurationType.Hours },
                                                                { label: "Days", value: DurationType.Days },
                                                                { label: "Months", value: DurationType.Months },
                                                                { label: "Years", value: DurationType.Years },
                                                            ]}
                                                            defaultValue={{ label: DurationToText(arrayItem?.duration?.type), value: arrayItem?.duration?.type }}
                                                            onChange={(selectedOption) => {
                                                                let referenceobject = formValues?.progress;
                                                                if (referenceobject)
                                                                    referenceobject[index].duration = {
                                                                        type: selectedOption?.value,
                                                                        value: referenceobject[index].duration ? referenceobject[index].duration.value : 0
                                                                    }
                                                                setFormValues({ progress: referenceobject, ...formValues })
                                                            }}
                                                            styles={{
                                                                control: (base: any) => {

                                                                    return {
                                                                        ...base,
                                                                        backgroundColor: "#f6f8fc",
                                                                        border: "none",

                                                                    }

                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    <>{
                                                        arrayItem?.duration?.type === DurationType.Date ?
                                                            <div style={{ display: "flex" }}>

                                                                <div style={{ marginRight: 5 }}>
                                                                    <DatePicker
                                                                        name="datetime-picker"
                                                                        autoFocus={false}
                                                                        dateFormat="DD/MM/YYYY"
                                                                        onChange={(selectedDate) => {
                                                                            let referenceobject = formValues?.progress;
                                                                            if (referenceobject)
                                                                                referenceobject.length > 0 ? referenceobject[index].startDate = selectedDate : null
                                                                            setFormValues({ progress: referenceobject, ...formValues })
                                                                        }}
                                                                        appearance="default"
                                                                    />
                                                                </div>

                                                                <div>
                                                                    <DatePicker
                                                                        name="datetime-picker"
                                                                        autoFocus={false}
                                                                        dateFormat="DD/MM/YYYY"
                                                                        appearance="default"
                                                                        onChange={(selectedDate) => {
                                                                            let referenceobject = formValues?.progress;
                                                                            if (referenceobject)
                                                                                referenceobject.length > 0 ? referenceobject[index].endDate = selectedDate : null
                                                                            setFormValues({ progress: referenceobject, ...formValues })
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div> :
                                                            <div style={{ width: 240 }}>
                                                                <Textfield
                                                                    appearance="standard"
                                                                    type="number"
                                                                    required
                                                                    defaultValue={arrayItem?.duration?.value}
                                                                    style={{
                                                                        backgroundColor: "#f6f8fc",
                                                                        height: 40
                                                                    }}
                                                                    onChange={(event: any) => {
                                                                        let referenceobject = formValues?.progress;
                                                                        if (referenceobject)
                                                                            referenceobject[index].duration.value = Number(event.target.value)
                                                                        setFormValues({ progress: referenceobject, ...formValues })
                                                                    }}
                                                                    autoFocus
                                                                    isRequired
                                                                />
                                                            </div>

                                                    }

                                                    </>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }

                                <div style={{
                                    display: "flex",
                                    justifyContent: "end",
                                    marginTop: 20

                                }}>
                                    <Button style={{ marginRight: "10px" }} appearance="subtle" onClick={
                                        () => {
                                            setModel({ ...model, create: false })
                                        }}>
                                        Cancel
                                    </Button>
                                    <Button appearance="primary"
                                        style={{
                                            height: "35px",
                                            display: "flex",
                                            alignItems: "center",
                                            backgroundColor: "#3177ff",
                                            fontSize: "14px!important"
                                        }}
                                        onClick={isEdit ? EditMilestone : AddMilestone}
                                    >
                                        Save
                                    </Button>
                                </div>
                            </div>

                        </div>

                    </ModalBody>
                    <Loader isLoading={isLoading} />
                </Modal>
            </ModalTransition>
        </>
    )
}

export default CreateMilestone