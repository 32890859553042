import Avatar, { AvatarItem } from '@atlaskit/avatar';
import DynamicTable from '@atlaskit/dynamic-table';
import Spinner from '@atlaskit/spinner';
import { ActivityType, LogType } from '../../Pages/Milestone';
import { formatDatedatetimeyear } from '../../utils/DateConvertor';
import { ActivityTypeToText, initialsgenerator, priorityconvertor, statusconvertor, textnumbergenerator } from '../../utils/Util';

export interface Activity {
    activity: any,
    activityType: any,
    activityLoading: boolean
}


function RenderActivity(log: any) {

        return (
            <>
                <div style={{ display: "flex" }}>
                    <span style={{ fontSize: "14px" }}>{log.remarks}</span>
                </div>
            </>
            )
}


const getYrlUkey1 = (clickedValue) => {
    window.open(process.env.REACT_APP_API_URL + "/task/" + `${clickedValue}`)
}


const Activity = ({ activity, activityType, activityLoading }: Activity) => {

    const createHead = (withWidth: boolean) => {

        return {
            cells: [
                /* {
                    key: 'ProjectName',
                    content: projectid1 && 'ProjectName',
                    isSortable: true,
                    width: 0.1
                },*/
                {
                    key: 'Ukey',
                    content: 'Ukey',
                    isSortable: true,
                    width: 1
                },
                {
                    key: 'Stage',
                    content: 'Stage',
                    isSortable: true,
                    width: 1
                },
                {
                    key: 'Priority',
                    content: 'Priority',
                    isSortable: true,
                    width: 1
                },
                {
                    key: 'Status',
                    content: 'Status',
                    isSortable: true,
                    width: 1
                },
            ],
        };
    };

    const head = createHead(true);
    return (
        <>
            {
                activityType === ActivityType.Log &&
                    <div style={{ marginLeft: "20px", backgroundColor: "white" }}>
                <div style={{ borderBottom: "1px solid #f6f8fc" }}>
                    <p style={{ color: "#3177FF", fontSize: 14 }}>{ActivityTypeToText(activityType)}</p>
                </div>
                <div style={{ height: "86vh", overflowX: "scroll" }}>
                    {
                        activity?.map((arrayItem: any) => (
                            <div style={{ borderBottom: "1px solid #F0F2F4", width: "93%" }}>
                                <div style={{ display: "flex", marginTop: "10px" }}>
                                    <div style={{ marginRight: "10px" }}>
                                        
                                        <Avatar size="medium" src={arrayItem?.user?.thumb === null || arrayItem?.user?.thumb === "" || arrayItem?.user?.thumb === undefined ?
                                            `https://i1.wp.com/avatar-management--avatars.us-west-2.prod.public.atl-paas.net/initials/${initialsgenerator(arrayItem?.user?.assigneeName)}-${textnumbergenerator(arrayItem?.user?.assigneeName)}.png?ssl=1`
                                            : arrayItem?.user?.thumb} />
                                        
                                    </div>
                                    <div>
                                        <h5 style={{ marginTop: "0px", marginBottom: "0px", color: "#364663" }}>{arrayItem?.user?.name}</h5>
                                        <p style={{ margin: 0, fontSize: 12, color: "rgb(146, 159, 172)" }}>{formatDatedatetimeyear(arrayItem?.createdBy.date)}</p>
                                    </div>
                                </div>
                                {
                                    RenderActivity(arrayItem)
                                }
                            </div>
                        ))
                    }
                    
                </div>
                </div>
            }
            {
                activityType === ActivityType.Task &&
                <div style={{ marginLeft: "20px", backgroundColor: "white" }}>
                        <div style={{ borderBottom: "1px solid #f6f8fc" }}>
                            <h5 style={{ color: "#3177FF" }}>{ActivityTypeToText(activityType)}</h5>
                        </div>
                        {
                            activityLoading ?
                                <div style={{ display: "flex", justifyContent: "center", marginTop: "20%" }}>
                                    <Spinner size="medium" />
                                </div>
                                :
                        <div style={{ height: "86vh", overflowX: "scroll" }}>
                    <DynamicTable
                            head={head}
                            rows={activity?.map((data: any, index: number) => {


                                return ({
                                    key: `row-${index}-${data.id}`,
                                    isHighlighted: false,
                                    cells: [

                                        {
                                            content:
                                                <div style={{ cursor: "pointer" }}>
                                                    <p onClick={() => {
                                                        getYrlUkey1(data?.uKey)
                                                    }}>{data.uKey}</p>
                                                </div>
                                        },
                                        {
                                            content:
                                                <div>
                                                    <p>{data?.stage?.name}</p>
                                                </div>
                                        },
                                        /*{
                                            content:
                                                <div style={{ display: "inline-block", width: "100%" }}>
                                                    {
                                                        <Avatar size="medium" src={data?.thumb === null || data?.thumb === "" || data?.thumb === undefined ?
                                                            `https://i1.wp.com/avatar-management--avatars.us-west-2.prod.public.atl-paas.net/initials/${initialsgenerator(data?.thumb)}-${textnumbergenerator(data?.thumb)}.png?ssl=1`
                                                            : data?.thumb} />
                                                    }
                                                </div>
                                        },*/
                                        {
                                            content:
                                                <div style={{ display: "inline-block", width: "100%" }}>
                                                    {
                                                        <p>{priorityconvertor(data.priority)}</p>
                                                    }
                                                </div>
                                        },
                                        {
                                            content:
                                                <div style={{ display: "inline-block", width: "100%" }}>
                                                    {
                                                        <p>{statusconvertor(data.tStatus)}</p>
                                                    }
                                                </div>
                                        }
                                    ],
                                })

                            }

                            )
                            }
                            loadingSpinnerSize="large"
                        />
                        </div>
                        }
                </div>
            }
        </>
    )
}

export default Activity;