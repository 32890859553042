import { ActivityType } from "../Pages/Milestone"
import { jwtDecode } from "jwt-decode";

export let globalobject = {
    companyid: ""
}

export const substringmethod = (data: string) => {

    if (data === null || data === undefined) return ""

    if (data.length > 25) {
        return data.substring(0, 25) + "..."
    }
    else {
        return data
    }

}

export const substringListViewModal = (data: string) => {
    if (data === null || data === undefined) return ""

    if (data.length > 60) {
        return data.substring(0, 60) + "..."
    }
    else {
        return data
    }
}

export const textnumbergenerator = (name: string) => {
    if (name)
        return name.charCodeAt(0) % 7
    else 
        return 0
}

export function formatBytes(bytes: number, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export function ActivityTypeToText(activity: ActivityType) {
    switch (activity) {
        case ActivityType.Log: return "Logs";
        case ActivityType.Task: return "Tasks"
    }
}

export const FindDayDifferenceInDates = (from: string, to: string) => {
    const fromdateobject: any = new Date(from);
    const todateobject: any = new Date(to);
    const diffTime = Math.abs(todateobject - fromdateobject);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}

export const initialsgenerator = (name: string) => {
    if (name) {
        let arr = name?.split(" ")
        if (arr.length > 1) {
            return ((arr[0].charAt(0) + arr[1].charAt(0)).toUpperCase())
        }
        else {
            return ((arr[0].charAt(0) + arr[0].charAt(1)).toUpperCase())
        }
    }
    else {
        return "NA"
    }
}

export const prioritycolorchange = (priority: number) => {
    if (priority === 2) {
        return "#ff9292";
    }
    else if (priority === 1) {
        return "#ffc69a";
    }
    else {
        return "#d1d1d1";
    }
}


export const Priorityflag = (priority: number) => {
    switch (priority) {
        case 2: return "red"
        case 1: return "orange"
        case 0: return "yellow"
    }
}

export function compareObjects(obj1: any, obj2: any) {
    // Get the keys of both objects
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // Check if the number of keys in both objects is the same
    if (keys1.length !== keys2.length) {
        return false;
    }

    // Iterate through the keys and compare values
    for (const key of keys1) {
        // Check if the key exists in both objects
        if (!keys2.includes(key)) {
            return false;
        }

        // Compare the values of the corresponding keys
        if (obj1[key] !== obj2[key]) {
            return false;
        }
    }

    // If all keys and values match, return true
    return true;
}

const imagePath2 = (imageName: string) => {
    let imageNames = imageName.split(".")

    if (imageNames[imageNames.length - 1] === "pdf") {
        return <img style={{ paddingTop: "2px", height: "40px" }} src={process.env.PUBLIC_URL + "/pdf.svg"} alt="" />
    }
    else if (imageNames[imageNames.length - 1] === "png") {
        return <img style={{ paddingTop: "2px", height: "40px" }} src={process.env.PUBLIC_URL + "/png.svg"} alt="" />
    }
    else if (imageNames[imageNames.length - 1] === "doc") {
        return <img style={{ paddingTop: "2px", height: "40px" }} src={process.env.PUBLIC_URL + "/doc.svg"} alt="" />
    }
    else if (imageNames[imageNames.length - 1] === "xlxs") {
        return <img style={{ paddingTop: "2px", height: "40px" }} src={process.env.PUBLIC_URL + "/xlxs.svg"} alt="" />
    }
    else if (imageNames[imageNames.length - 1] === "xls") {
        return <img style={{ paddingTop: "2px", height: "40px" }} src={process.env.PUBLIC_URL + "/xls.svg"} alt="" />
    }
    else if (imageNames[imageNames.length - 1] === "jpg") {
        return <img style={{ paddingTop: "2px", height: "40px" }} src={process.env.PUBLIC_URL + "/jpg.svg"} alt="" />
    }
    else {
        return <img style={{ paddingTop: "2px", height: "40px" }} src={process.env.PUBLIC_URL + "/other.svg"} alt="" />
    }
}

export const imagePath = (imageName: string) => {
    let imageNames = imageName.split(".")

    if (imageNames[imageNames.length - 1] === "pdf") {
        return <img style={{ paddingTop: "2px", height: "40px" }} src={process.env.PUBLIC_URL + "/pdf.svg"} alt="" />
    }
    else if (imageNames[imageNames.length - 1] === "png") {
        return <img style={{ paddingTop: "2px", height: "40px" }} src={process.env.PUBLIC_URL + "/png.svg"} alt="" />
    }
    else if (imageNames[imageNames.length - 1] === "doc") {
        return <img style={{ paddingTop: "2px", height: "40px" }} src={process.env.PUBLIC_URL + "/doc.svg"} alt="" />
    }
    else if (imageNames[imageNames.length - 1] === "xlxs") {
        return <img style={{ paddingTop: "2px", height: "40px" }} src={process.env.PUBLIC_URL + "/xlxs.svg"} alt="" />
    }
    else if (imageNames[imageNames.length - 1] === "xls") {
        return <img style={{ paddingTop: "2px", height: "40px" }} src={process.env.PUBLIC_URL + "/xls.svg"} alt="" />
    }
    else if (imageNames[imageNames.length - 1] === "jpg") {
        return <img style={{ paddingTop: "2px", height: "40px" }} src={process.env.PUBLIC_URL + "/jpg.svg"} alt="" />
    }
    else if (imageNames[imageNames.length - 1] === "other") {
        return <img style={{ paddingTop: "2px", height: "40px" }} src={process.env.PUBLIC_URL + "/other.svg"} alt="" />
    }
}   


export const priorityconvertor = (priority: number) => {

    let extenshionObject: any = {
        0: "Low",
        1: "Medium",
        2: "High"
    }
    return extenshionObject[priority] || "none"
}


export const priorityColor = (priority: number) => {
    if (priority === 2) {
        return "#FF9F9F";
    }
    else if (priority === 1) {
        return "rgb(37, 49, 72)";
    }
    else {
        return "rgb(37, 49, 72)";
    }
}

export const selectvalue = [
    { label: "Todo", value: 1 },
    { label: "Need to Discuss", value: 2 },
    { label: "In Progress", value: 3 },
    { label: "Developed", value: 7 },
    { label: "UAT", value: 4 },
    { label: "Testing", value: 5 },
    { label: "Done", value: 6 },
]


export const statusconvertor = (status: number) => {
    let extenshionObject: any = {
         0: "Backlog",
         1: "Todo",
         2: "Need to Discuss",
         3: "In Progress",
         4: "UAT",
         5: "Testing",
         6: "Done",
         7: "Developed",
    }
    return extenshionObject[status] || "None"
}

export const statusvalueconvertor = (status: string) => {
    let extenshionObject: any = {
         "Backlog":  0,
         "Todo":  1,
         "Need to Discuss":  2,
         "In Progress":  3,
         "UAT":  4,
         "Testing":  5,
         "Done":  6,
         "Developed":  7,
    }
    return extenshionObject[status] || "None"
}

export const droppableconvertor = (status: string) => {
    let extenshionObject: any = {
         "Todo": 0,
         "NeedToDiscuss": 1,
         "InProgress": 2,
         "UAT": 3,
         "Testing": 4,
         "Done": 5,
         "Developed": 6,
    }
    return extenshionObject[status] || "None"
}

export const statusbackgroundgenerator = (status: any) => {

    if (status?.toLowerCase()?.trim() === "todo") {
        return "#eaeeff"
    }
    else if (status?.toLowerCase()?.trim() === "need to discuss") {
        return "#d6fef4"
    }
    else if (status?.toLowerCase()?.trim() === "in progress") {
        return "#fcefe6"
    }
    else if (status?.toLowerCase()?.trim() === "developed") {
        return "#ffeafb"
    }
    else if (status?.toLowerCase()?.trim() === "uat") {
        return "#ebe7ff"
    }
    else if (status?.toLowerCase()?.trim() === "testing") {
        return "#ffeff3"
    }
    else if (status?.toLowerCase()?.trim() === "done") {
        return "#e8faed"
    }
    else if (status?.toLowerCase()?.trim() === "backlog") {
        return "#fff9e8"
    }
    else if (status?.toLowerCase()?.trim() === "Developed") {
        return ""
    }
    else {
        return ""
    }
}

export const statusbackgroundfont = (status: any) => {

    if (status === "NeedToDiscuss" || status === "NeedtoDiscuss" || status === "NeedToDiscuss") {
        status = "need to discuss"
    }
    else if (status === "InProgress") {
        status = "in progress"
    }

    if (status?.toLowerCase().trim() === "todo" || status === 1) {
        return "#eaeeff"
    }
    else if (status?.toLowerCase().trim() === "need to discuss" || status === 2) {
        return "#d6fef4"
    }
    else if (status?.toLowerCase().trim() === "in progress" || status === 3) {
        return "#fcefe6"
    }
    else if (status?.toLowerCase().trim() === "developed" || status === 7) {
        return "#ffeafb"
    }
    else if (status?.toLowerCase().trim() === "uat" || status === 4) {
        return "#ebe7ff"
    }
    else if (status?.toLowerCase().trim() === "testing" || status === 5) {
        return "#ffeff3"
    }
    else if (status?.toLowerCase().trim() === "done" || status === 6) {
        return "#e8faed"
    }
    else if (status?.toLowerCase().trim() === "backlog" || status === 0) {
        return "#fff9e8"
    }
    else if (status?.toLowerCase().trim() === "Developed") {
        return ""
    }
    else {
        return ""
    }
}

export const statuscolorfont = (status: any) => {
    if (status === "NeedToDiscuss" || status === "NeedtoDiscuss" || status === "NeedToDiscuss") {
        status = "need to discuss"
    }
    else if (status === "InProgress") {
        status = "in progress"
    }



    if (status?.toLowerCase().trim() === "todo" || status === 1) {
        return "#0066C6"
    }
    else if (status?.toLowerCase().trim() === "need to discuss" || status === 2) {
        return "#50a18f"
    }
    else if (status?.toLowerCase().trim() === "in progress" || status === 3) {
        return "#e16a14"
    }
    else if (status?.toLowerCase().trim() === "developed" || status === 7) {
        return "#ff76af"
    }
    else if (status?.toLowerCase().trim() === "uat" || status === 4) {
        return "#4b5264"
    }
    else if (status?.toLowerCase().trim() === "testing" || status === 5) {
        return "#f28593"
    }
    else if (status?.toLowerCase().trim() === "done" || status === 6) {
        return "#3ab15b"
    }
    else if (status?.toLowerCase().trim() === "backlog" || status === 0) {
        return "#d78723"
    }
    else if (status?.toLowerCase().trim() === "Developed") {
        return ""
    }
    else {
        return ""
    }
}

export const removefromarray = (arr: any) => {
    for (var i = 0; i < arr.length; i++) {

        if (arr[i] === 5) {

            arr.splice(i, 1);
        }

    }
}

export const randomhax = (index: any) => {
    let colorList = [
        '#247dff', '#43b4fa', '#d645d1', '#12c912', '#256be8',
        '#ff3399', '#af19d4', '#d12a2a', '#ffa500', '#0ddbc7'
    ];

    return colorList[index]
}

export const isTokenNotExpired = (token: string) => {
    interface DecodedToken {
        id: string;
        name: string;
        email: string;
        role: string;
        exp: number;
        iss: string;
    }
    try {
        const decoded: DecodedToken = jwtDecode (token);
        const expirationDate = new Date(decoded.exp * 1000);
        const currentDate = new Date();
        if (expirationDate > currentDate) {
            console.log("Token is valid");
            return true;
        } else {
            console.log("Token is expired");
            return false;
        }
    } catch (error) {
        console.error("Invalid token", error);
        return false;
    }
};