import { Suspense, lazy, useEffect } from 'react';
import { Outlet, createBrowserRouter, Navigate, useNavigate } from "react-router-dom";
import Spinner from '@atlaskit/spinner';
import Headerroutes from '../Routes/Headerroutes';
import Navbar from '../Components/Navbar/Navbar'
import LocalStorage from '../Services/Localstorage';
import { RouterProvider, redirect, useLocation } from "react-router-dom"
import { useApp } from '../Contexts/AppContext';
import { isTokenNotExpired } from '../utils/Util';

const DashboardPage = lazy(
    () =>
        import("../Pages/Dashboard2")
);

const UserReport = lazy(
    () =>
        import("../Reports/Userreport")

)
const BoardTasksPage = lazy(
    () =>
        import("../Pages/Board")
);

const BacklogPage = lazy(
    () =>
        import("../Pages/Backlog")
);

const InHandTask = lazy(
    () =>
        import("../Pages/InHandTask")
)

const IssuePage = lazy(
    () =>
        import("../Pages/IssuesPage")
)

const Statustimelinereport = lazy(
    () =>
        import("../Reports/Statustimelinerepot")
)

const Report = lazy(
    () =>
        import("../Reports/Report")
)

const ViewAll = lazy(
    () =>
        import("../Pages/ViewAll")
)

const PeopleReport = lazy(
    () =>
        import("../Pages/UserReport")
)

const LoginPage = lazy(
    () =>
        import("../Pages/Login")
);

const ForgotpasswordPage = lazy(
    () =>
        import("../Pages/Forgotpassword")
);

const SentMail = lazy(
    () =>
        import("../Pages/SentMail")
);

const RecoveryPage = lazy(
    () =>
        import("../Pages/Accountrecovery")
);

const Notfoundpage = lazy(
    () =>
        import("../Routes/Notfoundpage")
);

const UKey = lazy(
    () =>
        import("../Components/BrowseRoutes/UKey")
)

const TodayTimerComp = lazy(
    () =>
        import("../Components/TodatTimerComp/TodayTimerComp")
)


const Meeting = lazy(
    () =>
        import("../Pages/Meeting")
)

const LandingUkey = lazy(
    () =>
        import("../Pages/LandingUkey")
)
const RateingUser = lazy(
    () =>
        import("../Pages/RateingUser")
)

const AdminDashboard = lazy(
    () =>
        import("../Admin/AdminPages/AdminDashboard/AdminDashboard")
)

const RouteNotFound = lazy(
    () =>
        import("../Routes/Notfoundpage")
)


const UserReportReport = lazy(
    () =>
        import("../Components/UserTasks/UserTasks")
)


const AuthorizationRole = lazy(
    () =>
        import("../Components/AuthorizationRole/AuthorizationRole")
)

const TaskReport = lazy(
    () =>
        import("../Reports/TaskReport")
)

const SprintReport = lazy(
    () =>
        import("../Reports/SprintReport")
)

const WeeklyDoneTasks = lazy(
    () =>
        import("../Reports/WeeklyDoneTask")
)

const AllReport = lazy(
    () =>
        import("../Reports/AllReports")
)

const LogDetail = lazy(
    () =>

        import("../Components/LogDetail/LogDetail")
)

const OrgnisationDetail = lazy(
    () =>

        import("../Components/OrganizationDetail/OrganizationDetail")
)

const UserDetail = lazy(
    () =>

        import("../Components/UserDetail/UserDetail")
)

const SidebarProjects = lazy(
    () =>

        import("../Components/SidebarProjects/SidebarProjects")
)

const SidebarBranch = lazy(
    () =>
        import("../Components/SidebarBranch/SidebarBranch")
)

const SidebarDesignation = lazy(
    () =>
        import("../Components/SidebarDesignation/SidebarDesignation")
)

const SidebarSprints = lazy(
    () =>

        import("../Components/SidebarSprints/SidebarSprint")
)

const SidebarIssues = lazy(
    () =>

        import("../Components/SidebarIssues/SidebarIssues")
)


const Teamleader = lazy(
    () =>

        import("../Teamleader/TeamleaderPages/TeamleaderDashboard/TeamleaderDashboard")
)

const Milestone = lazy(
    () =>
        import("../Pages/Milestone")
)
const DashboardMain = lazy(
    () =>
        import("../Components/Dashboardtablecomponent/DashboardMain")
)

const MilestoneList = lazy(
    () =>
        import("../Pages/MilestoneList")
)

const App = () => {
    const { projects, user, setUser, getProjectByUser } = useApp();
    const navigate = useNavigate()
    var data = localStorage.getItem("logindetail");

    

    var token = localStorage.getItem("Token");
    useEffect(() => {
        const normalizedPath = location.pathname.replace(/\/$/, '');
        if (token) {
            if (isTokenNotExpired(token)) {
                if (location.pathname === '/' || normalizedPath === '/login') {
                    navigate('/dashboard');
                }
            } else {
                localStorage.removeItem("logindetail");
                localStorage.removeItem("Token");

                navigate('/login');
            }
        } else if (location.pathname === '/') {
            navigate('/login');
        }
    }, [location.pathname]);

     
    useEffect(() => {
        
        if (!user && !data) {
            navigate("/login");
        }
    }, [user])

    console.log("user", user);

    return (
        <Suspense fallback={<div className="loadinglazy">
            <Spinner size="medium" />
        </div>}>
            <Navbar />
            <Outlet />
        </Suspense>  
    );
};

const Login = () => {
    return (
        <Suspense fallback={<div className="loadinglazy">
            <Spinner size="medium" />
        </div>}>
            <LoginPage />
            <Outlet />
        </Suspense>
    );
};

const Forgotpassword = () => {
    return (
        <Suspense fallback={<div className="loadinglazy">
            <Spinner size="medium" />
        </div>}>
            <ForgotpasswordPage />
            <Outlet />
        </Suspense>
    );
};

const Accountrecovery = () => {
    return (
        <Suspense fallback={<div className="loadinglazy">
            <Spinner size="medium" />
        </div>}>
            <SentMail />
            <Outlet />
        </Suspense>
    ); 
};

const AdminDashboardRoute = () => {
    
    const { user } = useApp(); // Destructure user from useApp hook
    console.log(user,"dddfddfdfdfad")
    // Check if user exists and is an admin
    const isAdmin = user && user.role === "Admin";

    // Render AdminDashboard if user is admin, otherwise render RouteNotFound
    return isAdmin ? <AdminDashboard /> : <RouteNotFound />;
};

export const protectedRoutes = createBrowserRouter([
        
    {
        path: '',
        element: < App />,
        children: [
            { path: '/dashboard', element: <DashboardPage page="mywork" /> },
            { path: '/Board', element: <BoardTasksPage /> },
            { path: '/Board/:project/:ukey', element: <BoardTasksPage /> },
            { path: '/backlog', element: <BacklogPage /> },
            { path: '/backlog/:project/:ukey', element: <BacklogPage /> },
            { path: '/viewall', element: <ViewAll /> },
            { path: '/report/reporting', element: <Report /> },
            { path: '/userreport', element: <UserReport page="" /> },
            { path: '/report/taskstatustimeline', element: <Statustimelinereport /> },
            { path: '/peoplereport', element: <PeopleReport /> },
            { path: '/browse/:uKey', element: <UKey /> },
            { path: '/recovery', element: <RecoveryPage /> },
            { path: '/TodayTimer', element: <TodayTimerComp /> },
            { path: '/meeting', element: <Meeting /> },
            { path: '/authrole', element: <AuthorizationRole /> },
            { path: '/rating', element: <RateingUser /> },
            { path: '/LogDetail', element: <LogDetail /> },
            { path: '/organisationDetail', element: <OrgnisationDetail /> },
            { path: '/branch', element: <SidebarBranch /> },
            { path: '/designation', element: <SidebarDesignation /> },
            { path: '/userDetail', element: <UserDetail /> },
            { path: '/sidebarProjects', element: <SidebarProjects /> },
            { path: '/sidebarSprints', element: <SidebarSprints /> },
            { path: '/sidebarIssues', element: <SidebarIssues /> },
            { path: '/teamleader/dashboard', element: <Teamleader /> },
            {
                path: '/admin/dashboard',
                element: <AdminDashboardRoute />
            },
            { path: '/usertask',element:<UserReportReport/>},
            { path: '/milestone/', element: <MilestoneList /> },
            { path: '/milestone/:id', element: <Milestone /> },
            { path: '/task/:uKey', element: <DashboardMain /> },
            {
                path: "/reports/taskreport",
                element: <TaskReport />,
            },
            {
                path: "/reports/sprintreport",
                element: <SprintReport />,
            },
            {
                path: "/reports/weeklydonetasks",
                element: <WeeklyDoneTasks />, 
            },
            {
                path: "/reports/issues",
                element: <IssuePage />,
            },
            {
                path: "/reports/inhand",
                element: <InHandTask />,
            },
            { path: "", element: <Navigate to="/login" /> },



        ],
    },

    {
        path: '/login',
        element: <Login />,
    },
    {
        path: '/forgotpassword',
        element: <Forgotpassword />
    },
    {
        path: '/accountrecovery/*',
        element: <Accountrecovery />
    },
    { path: '*', element: <Notfoundpage /> }
]);
