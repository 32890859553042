import Spinner, { Size } from '@atlaskit/spinner';

export const Loader = ({ isLoading, withoutOverlay = false }: any) => {
    return (
        <>
            {
                isLoading &&
                <>
                    {
                        withoutOverlay
                            ?
                            <div style={{
                                position: 'absolute',
                                top: "50%",
                                left: "50%",
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: '24px'
                            }}>
                                <Spinner size="medium" />
                            </div>
                            :
                            <div className="loading">
                                <Spinner size="medium" />
                            </div>
                    }
                </>
            }
        </>
    )
}
